<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on" small block>
          {{ $t("events.saveLog") }}

          <v-progress-circular
            v-if="isLoading"
            indeterminate
            size="12"
            width="2"
            class="ml-2"
          ></v-progress-circular>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(eventDay, index) in eventDays"
          :key="index"
          class="downloadItem"
          @click="eventDayClickHandler(eventDay.value)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ eventDay.text }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <v-icon :small="$vuetify.breakpoint.smAndUp">mdi-download</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Moment from "moment";
import { extendMoment } from "moment-range";
import { mapGetters } from "vuex";
import Repository from "../store/api/repository";
import getDeviceBySectionIndex from "@/mixins/getDeviceBySectionIndex";

const moment = extendMoment(Moment);

export default {
  name: "LogDownloader",
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      getDeviceById: "devices/getItemById"
    }),
    eventDays() {
      const start = moment.unix(this.event.start);
      const end = moment.unix(this.event.end);
      const range = moment.range(start, end);
      const days = Array.from(range.by("days"));

      return days.map(el => ({
        value: el.format("YYYY-MM-DD"),
        text: this.$i18n.d(el, "shortDate")
      }));
    },
    startDevice() {
      return this.getDeviceBySectionIndex(0);
    },
    timeOffset() {
      if (this.startDevice) {
        return this.startDevice.deviceComponents.TimingInfo.timeOffsetUTC;
      }
      return null;
    }
  },
  mixins: [getDeviceBySectionIndex],
  methods: {
    eventDayClickHandler(eventDay) {
      this.isLoading = true;

      const dateTime = `${eventDay}T00:00`;

      this.fetchEventLogsByDay(dateTime)
        .then(response => {
          const fileName = `event-log_${eventDay}`;
          const log = response.data.data;
          const a = this.buildCSVFile(fileName, log);
          const e = document.createEvent("MouseEvents");
          e.initEvent(
            "click",
            true,
            false,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          a.dispatchEvent(e);

          this.$emit("logDownload", true);
          this.isLoading = false;
        })
        .catch(response => {
          this.$emit("logDownload", false);
          this.isLoading = false;
        });
    },
    fetchEventLogsByDay(dateTime) {
      return new Promise((resolve, reject) => {
        Repository.get(`events/${this.event.id}/daylog?dateTime=${dateTime}`)
          .then(response => {
            if (response.data.status !== 0 || response.data.data === null) {
              return Promise.reject(response);
            }

            resolve(response);
          })
          .catch(response => {
            reject(response);
          });
      });
    },
    buildCSVFile(fileName, log) {
      const delimiter = ";";

      const headers = [
        '"ID"',
        '"Timestamp"',
        '"Timezone"',
        '"Type"',
        '"Severity"',
        '"Text"',
        '"Data Type"',
        '"Data"'
      ];
      let content = headers.join(delimiter) + "\n";

      log.map(el => {
        content += `\"${el.id}\"${delimiter}`;
        content += `\"${this.formatLogTimestamp(el.timestamp)}\"${delimiter}`;
        content += `\"${this.formatLogTimeZone(this.timeOffset)}\"${delimiter}`;
        content += `\"${el.type}\"${delimiter}`;
        content += `\"${el.severity}\"${delimiter}`;
        content += `\"${
          el.text !== null ? el.text.replace(delimiter, " ") : ""
        }\"${delimiter}`;
        content += `\"${el.dataType}\"${delimiter}`;
        content += `\"${el.data}\"${delimiter}`;
        content += "\n";
      });

      const blob = new Blob([content], { type: "text/csv;charset=UTF-8" });
      const a = document.createElement("a");
      a.download = `${fileName}.csv`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");

      return a;
    },
    formatLogTimestamp(timestamp) {
      if (this.timeOffset !== null) {
        timestamp = moment(timestamp).add(this.timeOffset / 60, "hours");
      }

      return moment(timestamp).format("HH:mm:ss.SSS YYYY-MM-DD");
    },
    formatLogTimeZone(timeOffset) {
      if (timeOffset !== null) {
        timeOffset /= 60;

        if (timeOffset > 0) {
          return `+${timeOffset}`;
        }

        return timeOffset;
      }

      return "-";
    }
  }
};
</script>
<style>
.downloadItem:hover .v-list-item__title,
.downloadItem:hover .v-list-item__icon .v-icon {
  color: #1976d2;
}
</style>
