<template>
  <v-dialog v-model="showDialog" width="600" scrollable>
    <v-form @submit.prevent="submitForm">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $t("forms.competitorNote.title") }}
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon @click="showDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="py-0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  :label="$t('forms.competitorNote.note')"
                  v-model.trim="$v.form.note.$model"
                  :error-messages="noteErrors"
                  :counter="250"
                  clearable
                  auto-grow
                  autofocus
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex justify-space-between">
          <v-btn depressed :disabled="loading" @click="showDialog = false">
            {{ $t("forms.competitorNote.cancel") }}
          </v-btn>

          <v-btn type="submit" color="primary" :loading="loading">
            {{ $t("forms.competitorNote.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import { required, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "CompetitorNoteEdit",
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showDialog: false,
      competitorId: null,
      form: {
        note: null
      },
      loading: false,
      error: null
    };
  },
  validations() {
    return {
      form: {
        note: {
          maxLength: maxLength(250)
        }
      }
    };
  },
  computed: {
    noteErrors() {
      const errors = [];
      if (!this.$v.form.note.$dirty) return errors;
      !this.$v.form.note.maxLength &&
        errors.push(
          this.$i18n.t("forms.competitorNote.errors.note.maxLength", {
            length: 250
          })
        );
      return errors;
    }
  },
  methods: {
    ...mapActions({
      updateCompetitorNote: "events/updateCompetitorNote"
    }),
    openDialog(competitor) {
      this.resetDialog();
      this.initData(competitor);
      this.showDialog = true;
    },
    resetDialog() {
      this.$v.$reset();

      this.error = null;
    },
    initData(competitorId) {
      if (competitorId && this.heat) {
        this.competitorId = competitorId;
        const competitorInformation = this.heat.competitorInformation.find(
          el => el.competitorId === competitorId
        );
        if (competitorInformation) {
          this.form.note = competitorInformation.note;
        } else {
          this.form.note = null;
        }
      }
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.form.$invalid && this.heat && this.competitorId) {
        this.loading = true;
        this.error = null;
        const payload = {
          eventId: this.event.id,
          heatId: this.heat.id,
          competitorId: this.competitorId,
          data: {
            value: this.form.note
          }
        };
        this.updateCompetitorNote(payload)
          .then(() => {
            this.loading = false;
            this.showDialog = false;
          })
          .catch(response => {
            switch (response.data.status) {
              case -3070:
                this.error = "form.competitorNote.errors.updateFailed";
                break;
              default:
                this.error = "errors.unexpectedError";
            }
            this.loading = false;
          });
      }
    }
  }
};
</script>
