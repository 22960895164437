<template>
  <v-dialog
    v-if="heat"
    v-model="show"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("startTimeAssignment.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-tabs id="start-time-assignment-tabs" v-model="selectedTab">
          <v-tab v-for="tab in tabs" :key="tab.id">
            {{ tab.label }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="tab in tabs" :key="tab.id">
            <component
              :is="tab.component"
              :event="event"
              :heat="heat"
              :show="show"
              @close="closeDialog"
            ></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import StartTimeAssignmentByClass from "@/components/StartTimeAssignmentByClass";
import StartTimeAssignmentByBib from "@/components/StartTimeAssignmentByBib";

export default {
  name: "StartTimeAssignmentDialog",
  components: {
    StartTimeAssignmentByClass,
    StartTimeAssignmentByBib
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      show: false,
      selectedTab: "bib",
      tabs: [
        {
          id: "class",
          label: this.$i18n.t("startTimeAssignment.tabs.assignmentByClass"),
          component: StartTimeAssignmentByClass
        },
        {
          id: "bib",
          label: this.$i18n.t("startTimeAssignment.tabs.assignmentByBib"),
          component: StartTimeAssignmentByBib
        }
      ]
    };
  },
  methods: {
    openDialog() {
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    }
  }
};
</script>
<style>
#start-time-assignment-tabs > div.v-item-group > div.v-slide-group__prev,
#start-time-assignment-tabs > div.v-item-group > div.v-slide-group__next {
  display: none;
}
</style>
