var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.heat)?_c('v-dialog',{attrs:{"scrollable":"","max-width":"500","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.isOwner ? _vm.$t("startNumberInput.ownerTitle") : _vm.$t("startNumberInput.guestTitle"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-text-field',{staticClass:"px-4 py-6",attrs:{"label":_vm.$t('startNumberInput.filterLabel'),"append-icon":"mdi-magnify","hide-details":"","autofocus":"","tabindex":"0"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.filteredStartList),function(entry,index){return _c('v-list-item',{key:entry.id,style:([
          {
            height: '64px'
          },
          _vm.nextCompetitor.id === entry.id ? _vm.nextCompetitorStyleObject : '',
          _vm.selectedEntryIndex === index
            ? { backgroundColor: '#E3F2FD !important' }
            : { backgroundColor: '#ffffff !important' }
        ])},[_c('v-list-item-icon',[_c('v-avatar',{attrs:{"color":_vm.nextCompetitor.id === entry.id ? 'success' : 'primary',"rounded":""}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(" "+_vm._s(entry.startNumber)+" ")])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6",staticStyle:{"line-height":"1.2"}},[_vm._v(" "+_vm._s(entry.userData.lastName)+" "+_vm._s(entry.userData.firstName)+" ")])],1),_c('v-list-item-action',[(_vm.nextCompetitor.id === entry.id)?_c('v-btn',{attrs:{"icon":"","fab":"","small":"","outlined":"","color":"success","disabled":""}},[_c('v-icon',[_vm._v(" mdi-home ")])],1):(_vm.isOwner)?_c('v-btn',{attrs:{"icon":"","fab":"","small":"","outlined":"","color":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.clickHandler(entry.id)}}},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()],1)],1)}),1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }