<template>
  <div>
    <v-card-title>
      {{ $t("log.title") }}
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="logItems"
      :loading="isLoading"
      mobile-breakpoint="0"
      show-expand
    >
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-text-field v-model="search.type" type="text"></v-text-field>
          </td>
          <td>
            <v-select
              v-model="search.severity"
              :items="severities"
              :item-text="item => $t(`log.severity.${item.text}`)"
              clearable
            ></v-select>
          </td>
          <td>
            <v-text-field v-model="search.comment" type="text"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="search.timestamp" type="text"></v-text-field>
          </td>
        </tr>
      </template>

      <template v-slot:item.type="{ item }">
        {{ getType(item.type, item.data) }}
      </template>

      <template v-slot:item.severity="{ item }">
        <v-chip
          v-if="item.severity"
          :color="getSeverityColor(item.severity)"
          dark
        >
          {{ $t(`log.severity.${String(item.severity).toLowerCase()}`) }}
        </v-chip>
      </template>

      <template v-slot:item.timestamp="{ item }">
        <span style="white-space: nowrap">
          {{ formatLogTimestamp(item.timestamp) }}
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-start">
          <v-btn icon @click="editLog(item)">
            <v-icon>mdi-comment-edit-outline</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn
          v-if="
            getLogDataColumns(item.dataType, JSON.parse(item.data)).length >
              0 && !isExpanded
          "
          icon
          @click="expand(true)"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          v-if="
            getLogDataColumns(item.dataType, JSON.parse(item.data)).length >
              0 && isExpanded
          "
          icon
          @click="expand(false)"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-row>
              <v-col
                v-for="column of getLogDataColumns(
                  item.dataType,
                  JSON.parse(item.data)
                )"
                :key="column.id"
                cols="12"
                sm="6"
                md="4"
              >
                <span class="font-weight-bold">
                  {{ `${column.text}:` }}
                </span>
                <router-link
                  v-if="column.id.includes('deviceId')"
                  :to="{ name: 'DevicesEdit', params: { id: column.value } }"
                  exact
                >
                  {{ getDeviceNameString(column.value) }}
                </router-link>
                <span v-else-if="column.id.includes('active')">
                  {{ column.value ? $t("log.yes") : $t("log.no") }}
                </span>
                <span
                  v-else-if="
                    column.id.includes('lowerBound') ||
                      column.id.includes('upperBound')
                  "
                >
                  {{ msToTime(column.value) }}
                </span>
                <span v-else>
                  {{ column.value }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import formatTimestamp from "@/mixins/formatTimestamp";
import formatRunTime from "@/mixins/formatRunTime";
import cropTime from "@/mixins/cropTime";
import getDeviceBySectionIndex from "@/mixins/getDeviceBySectionIndex";

export default {
  name: "LogHistory",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      isLoading: true,
      isInitialized: false,
      search: {
        type: "",
        severity: "",
        comment: "",
        timestamp: ""
      },
      severities: [
        {
          value: "NOTE",
          text: "note"
        },
        {
          value: "LOW",
          text: "low"
        },
        {
          value: "MEDIUM",
          text: "medium"
        },
        {
          value: "HIGH",
          text: "high"
        },
        {
          value: "CRITICAL",
          text: "critical"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      logItems: state => state.events.selectedItemLogItems
    }),
    ...mapGetters({
      getDeviceById: "devices/getItemById"
    }),
    headers() {
      return [
        {
          text: this.$i18n.t("log.table.type"),
          value: "type",
          filter: (value, search, item) =>
            this.getType(value, item.data)
              .toLowerCase()
              .includes(String(this.search.type).toLowerCase())
        },
        {
          text: this.$i18n.t("log.table.severity"),
          value: "severity",
          filter: value => {
            if (
              this.search.severity !== null &&
              this.search.severity !== undefined &&
              this.search.severity !== ""
            ) {
              return String(value)
                .toLowerCase()
                .includes(String(this.search.severity).toLowerCase());
            }

            return true;
          }
        },
        {
          text: this.$i18n.t("log.table.timestamp"),
          value: "timestamp",
          filter: value =>
            this.formatLogTimestamp(value)
              .toLowerCase()
              .includes(String(this.search.timestamp).toLowerCase())
        },
        {
          text: this.$i18n.t("log.table.comment"),
          value: "text",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.search.comment).toLowerCase())
        },
        {
          value: "actions",
          sortable: false,
          width: "1%"
        },
        {
          value: "data-table-expand"
        }
      ];
    },
    startDevice() {
      return this.getDeviceBySectionIndex(0);
    },
    timeOffset() {
      if (this.startDevice) {
        return this.startDevice.deviceComponents.TimingInfo.timeOffsetUTC;
      } else {
        return new Date().getTimezoneOffset() * -1;
      }
    }
  },
  watch: {
    isVisible(value) {
      if (value && !this.isInitialized) {
        this.initialize();
      }
    }
  },
  mixins: [formatTimestamp, formatRunTime, cropTime, getDeviceBySectionIndex],
  methods: {
    ...mapActions({
      fetchLogItems: "events/fetchLogItems",
      updateLogEntry: "events/updateLogEntry"
    }),
    initialize() {
      const payload = {
        eventId: this.event.id
      };

      this.fetchLogItems(payload)
        .then(() => {
          this.isLoading = false;
          this.isInitialized = true;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getDeviceNameString(deviceId) {
      if (deviceId) {
        const device = this.getDeviceById(deviceId);

        if (device) {
          const deviceName = device.deviceComponents.AppSettings.name;

          return deviceName ? `${deviceId} (${deviceName})` : `${deviceId}`;
        }
      }

      return "";
    },
    msToTime(ms) {
      ms *= 10000;

      return this.cropTime(this.formatRunTime(ms, 2));
    },
    editLog(logEntry) {
      this.$emit("editLogEntry", logEntry);
    },
    getSeverityColor(severity) {
      switch (severity) {
        case "NOTE":
          return "blue";
        case "LOW":
          return "green";
        case "MEDIUM":
          return "amber";
        case "HIGH":
          return "orange";
        case "CRITICAL":
          return "red";
        default:
          return "";
      }
    },
    getLogDataColumns(logDataType, logData) {
      const fields = new Array();

      switch (logDataType) {
        case "TriggerDto":
          return [
            {
              id: "triggerDto-type",
              text: this.$i18n.t("log.type"),
              value: logData.type
            },
            {
              id: "triggerDto-deviceId",
              text: this.$i18n.t("log.device"),
              value: logData.deviceId
            },
            {
              id: "triggerDto-timingChannel",
              text: this.$i18n.t("log.channel"),
              value: logData.timingChannel
            },
            {
              id: "triggerDto-startNumber-startNumber",
              text: this.$i18n.t("log.startNumber"),
              value: logData.startNumber.startNumber
            },
            {
              id: "triggerDto-startNumber-type",
              text: this.$i18n.t("log.startNumberType"),
              value: this.$i18n.t(
                `trigger.table.${String(
                  logData.startNumber.type
                ).toLowerCase()}`
              )
            },
            {
              id: "triggerDto-triggerSource",
              text: this.$i18n.t("log.source"),
              value: logData.triggerSource
            },
            {
              id: "triggerDto-timestamp",
              text: this.$i18n.t("log.timestamp"),
              value: this.formatTimestamp_100ns(
                logData.timestamp,
                logData.timeOffset
              )
            }
          ];
        case "HeatDto":
          return [
            {
              id: "heatDto-name",
              text: this.$i18n.t("log.name"),
              value: logData.name
            },
            {
              id: "heatDto-state",
              text: this.$i18n.t("log.state"),
              value: this.$i18n.t(
                `events.state.${String(logData.state).toLowerCase()}`
              )
            }
          ];
        case "CompetitorDto":
          fields.push({
            id: "competitorDto-startNumber",
            text: this.$i18n.t("log.startNumber"),
            value: logData.startNumber
          });

          if (logData.userData) {
            fields.push(
              {
                id: "competitorDto-userData-firstName",
                text: this.$i18n.t("log.firstName"),
                value: logData.userData.firstName
              },
              {
                id: "competitorDto-userData-lastName",
                text: this.$i18n.t("log.lastName"),
                value: logData.userData.lastName
              }
            );
          }

          return fields;
        case "PulseHoldActiveDto":
          return [
            {
              id: "pulseHoldActiveDto-deviceId",
              text: this.$i18n.t("log.device"),
              value: logData.deviceId
            },
            {
              id: "pulseHoldActiveDto-channel",
              text: this.$i18n.t("log.channel"),
              value: logData.channel
            },
            {
              id: "pulseHoldActiveDto-active",
              text: this.$i18n.t("log.active"),
              value: logData.active
            }
          ];
        case "BibDto":
          return [
            {
              id: "bibDto-trigger-type",
              text: this.$i18n.t("log.type"),
              value: logData.trigger.type
            },
            {
              id: "bibDto-trigger-deviceId",
              text: this.$i18n.t("log.device"),
              value: logData.trigger.deviceId
            },
            {
              id: "bibDto-trigger-timingChannel",
              text: this.$i18n.t("log.channel"),
              value: logData.trigger.timingChannel
            },
            {
              id: "bibDto-trigger-startNumber-startNumber",
              text: this.$i18n.t("log.startNumber"),
              value: logData.trigger.startNumber.startNumber
            },
            {
              id: "bibDto-trigger-startNumber-type",
              text: this.$i18n.t("log.startNumberType"),
              value: this.$i18n.t(
                `trigger.table.${String(
                  logData.trigger.startNumber.type
                ).toLowerCase()}`
              )
            },
            {
              id: "bibDto-trigger-triggerSource",
              text: this.$i18n.t("log.source"),
              value: logData.trigger.triggerSource
            },
            {
              id: "bibDto-trigger-timestamp",
              text: this.$i18n.t("log.timestamp"),
              value: this.formatTimestamp_100ns(
                logData.trigger.timestamp,
                logData.trigger.timeOffset
              )
            }
          ];
        case "ReferenceRunLogDto":
          const sections = logData.sectionTolerances;

          for (const [key, value] of Object.entries(sections)) {
            fields.push(
              {
                id: `referenceRunLogDto-sectionTolernaces-${key}-section`,
                text: this.$i18n.t("log.section"),
                value: key
              },
              {
                id: `referenceRunLogDto-sectionTolernaces-${key}-lowerBound`,
                text: this.$i18n.t("log.lowerBound"),
                value: value.lowerBound
              },
              {
                id: `referenceRunLogDto-sectionTolernaces-${key}-upperBound`,
                text: this.$i18n.t("log.upperBound"),
                value: value.upperBound
              }
            );
          }

          return fields;
        default:
          return fields;
      }
    },
    formatLogTimestamp(timestamp) {
      timestamp = moment(timestamp);
      if (this.timeOffset !== null) {
        timestamp = timestamp.add(this.timeOffset, "minutes");
      }
      return this.$i18n.d(timestamp, "longDateTime");
    },
    getType(type, logData = null) {
      switch (type) {
        case "REFERENCE_RUN_SET":
          return this.$i18n.t("log.types.referenceRunSet");
        case "REFERENCE_RUN_UNSET":
          return this.$i18n.t("log.types.referenceRunUnset");
        case "TRIGGER_PREMATURE":
          return this.$i18n.t("log.types.triggerPremature");
        case "TRIGGER_DELAYED":
          return this.$i18n.t("log.types.triggerDelayed");
        case "SPEED_UNDERCUT":
          return this.$i18n.t("log.types.speedUndercut");
        case "SPEED_EXCEED":
          return this.$i18n.t("log.types.speedExceed");
        case "PULSE_HOLD":
          logData = JSON.parse(logData);

          if (logData) {
            return logData.active
              ? this.$i18n.t("log.types.pulseHoldActive")
              : this.$i18n.t("log.types.pulseHoldInactive");
          }

          return this.$i18n.t("log.types.pulseHold");
        case "AUTO_COMPETITOR_ADD":
          return this.$i18n.t("log.types.autoCompetitorAdd");
        case "HEAT_ARMED":
          return this.$i18n.t("log.types.heatArmed");
        case "HEAT_DISARMED":
          return this.$i18n.t("log.types.heatDisarmed");
        case "HEAT_ARMING_FAILED":
          return this.$i18n.t("log.types.heatArmingFailed");
        case "SPORT_EVENT_RESTART":
          return this.$i18n.t("log.types.sportEventRestart");
        case "HEAT_CANCEL":
          return this.$i18n.t("log.types.heatCancel");
        case "RESET_RUN":
          return this.$i18n.t("log.types.resetRun");
        case "UNHANDLED_TRIGGER":
          return this.$i18n.t("log.types.unhandledTrigger");
        case "RESEND_TRIGGER":
          return this.$i18n.t("log.types.resendTrigger");
        case "INFORMATION":
          return this.$i18n.t("log.types.information");
        case "WARNING":
          return this.$i18n.t("log.types.warning");
        case "ERROR":
          return this.$i18n.t("log.types.error");
        default:
          return type;
      }
    }
  }
};
</script>
<style scoped>
.v-data-table /deep/ th[role="columnheader"] {
  white-space: nowrap;
}
</style>
