var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.print)?_c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"label":_vm.$t('events.classFilter.class'),"items":_vm.classFilters,"return-object":""},model:{value:(_vm.classFilter),callback:function ($$v) {_vm.classFilter=$$v},expression:"classFilter"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('TableFilter',{attrs:{"event":_vm.event,"reduced":""}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"label":_vm.$t('events.search'),"append-icon":"mdi-magnify","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1):_vm._e(),_c('v-data-table',{ref:"eventRankingTable",class:_vm.printClass,attrs:{"headers":_vm.rankingHeaders,"items":_vm.indexedEventRanking,"search":_vm.search,"value":_vm.highlightedCompetitor,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"fixed-header":"","mobile-breakpoint":"0","dense":"","disable-sort":_vm.print,"disable-filtering":_vm.print,"disable-pagination":_vm.print,"hide-default-footer":_vm.print},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.rank",fn:function({ item }){return [(
          !_vm.print &&
            item.rank > 0 &&
            item.rank < 4 &&
            item.state === 'FINISHED'
        )?_c('Trophy',{attrs:{"rank":item.rank}}):(item.state === 'FINISHED')?_c('span',[_vm._v(" "+_vm._s(item.rank)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.startNumber",fn:function({ item }){return [_c('a',{on:{"click":function($event){return _vm.$emit('open-edit-action', item)}}},[_vm._v(" "+_vm._s(item.startNumber)+" ")])]}},{key:"item.userData.name",fn:function({ item }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.getCompetitorName(item))+" ")])]}},{key:"item.userData.nation",fn:function({ item }){return [(item.userData.nation && _vm.print)?_c('span',[_vm._v(_vm._s(item.userData.nation))]):(item.userData.nation)?_c('CountryFlag',{attrs:{"country":_vm.getNationById(item.userData.nation),"size":20}}):_vm._e()]}},{key:"item.userData.gender",fn:function({ item }){return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.genderIcon(item.userData.gender))+" ")])]}},{key:"item.userData.dateOfBirth",fn:function({ item }){return [_vm._v(" "+_vm._s(item.userData.dateOfBirth ? isNaN(new Date(item.userData.dateOfBirth)) ? item.userData.dateOfBirth : _vm.$d(new Date(item.userData.dateOfBirth).getTime(), "shortDate") : "")+" ")]}},_vm._l((_vm.event.heats),function(heat,index){return {key:`item.heats.${index}.runTime`,fn:function({ item }){return [(item.heats[index].state === 'FINISHED')?_c('span',{key:heat.id,class:{
          'text-decoration-underline': item.heats[index].highlighted
        }},[_vm._v(" "+_vm._s(_vm.cropTime(_vm.formatRunTime(item.heats[index].runTime, _vm.precision)))+" ")]):(
          item.heats[index].state === 'CANCELLED' &&
            item.heats[index].cancellation
        )?_c('span',{key:heat.id},[_vm._v(" "+_vm._s(item.heats[index].cancellation)+" ")]):_c('span',{key:heat.id},[_vm._v(" - ")])]}}}),_vm._l((_vm.event.heats),function(heat,index){return {key:`item.heats.${index}.speed`,fn:function({ item }){return [(item.heats[index].speed)?_c('span',{key:heat.id},[_vm._v(" "+_vm._s(_vm.convertToSpeedUnit(item.heats[index].speed))+" ")]):_c('span',{key:heat.id},[_vm._v(" - ")])]}}}),{key:"item.runTime",fn:function({ item }){return [(item.state === 'FINISHED')?_c('span',[_vm._v(" "+_vm._s(_vm.cropTime(_vm.formatRunTime(item.runTime, _vm.precision)))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.diff",fn:function({ item }){return [(item.state !== 'FINISHED')?_c('span',[_vm._v(" - ")]):(item.diff === 0)?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(" "+_vm._s(_vm.cropTime(_vm.formatRunTime(item.diff, _vm.precision)))+" ")])]}},{key:"item.speed.average",fn:function({ item }){return [(item.speed.average)?_c('span',[_vm._v(" "+_vm._s(_vm.convertToSpeedUnit(item.speed.average))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.speed.min",fn:function({ item }){return [(item.speed.min)?_c('span',[_vm._v(" "+_vm._s(_vm.convertToSpeedUnit(item.speed.min))+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.speed.max",fn:function({ item }){return [(item.speed.max)?_c('span',[_vm._v(" "+_vm._s(_vm.convertToSpeedUnit(item.speed.max))+" ")]):_c('span',[_vm._v(" - ")])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }