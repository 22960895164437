<template>
  <div>
    <v-container v-if="!print" :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row align="center">
        <v-col cols="auto">
          <v-select
            :label="$t('events.classFilter.class')"
            :items="classFilters"
            v-model="classFilter"
            return-object
          />
        </v-col>

        <v-col cols="auto">
          <TableFilter :event="event" reduced />
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="auto">
          <v-text-field
            :label="$t('events.search')"
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      ref="eventRankingTable"
      :headers="rankingHeaders"
      :items="indexedEventRanking"
      :search="search"
      :value="highlightedCompetitor"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      fixed-header
      mobile-breakpoint="0"
      dense
      :disable-sort="print"
      :disable-filtering="print"
      :disable-pagination="print"
      :hide-default-footer="print"
      :class="printClass"
    >
      <template v-slot:item.rank="{ item }">
        <Trophy
          v-if="
            !print &&
              item.rank > 0 &&
              item.rank < 4 &&
              item.state === 'FINISHED'
          "
          :rank="item.rank"
        />
        <span v-else-if="item.state === 'FINISHED'">
          {{ item.rank }}
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template v-slot:item.startNumber="{ item }">
        <a @click="$emit('open-edit-action', item)">
          {{ item.startNumber }}
        </a>
      </template>

      <template v-slot:item.userData.name="{ item }">
        <span style="white-space: nowrap">
          {{ getCompetitorName(item) }}
        </span>
      </template>

      <template v-slot:item.userData.nation="{ item }">
        <span v-if="item.userData.nation && print">{{
          item.userData.nation
        }}</span>
        <CountryFlag
          v-else-if="item.userData.nation"
          :country="getNationById(item.userData.nation)"
          :size="20"
        />
      </template>

      <template v-slot:item.userData.gender="{ item }">
        <v-icon small>
          {{ genderIcon(item.userData.gender) }}
        </v-icon>
      </template>

      <template v-slot:item.userData.dateOfBirth="{ item }">
        {{
          item.userData.dateOfBirth
            ? isNaN(new Date(item.userData.dateOfBirth))
              ? item.userData.dateOfBirth
              : $d(new Date(item.userData.dateOfBirth).getTime(), "shortDate")
            : ""
        }}
      </template>

      <template
        v-for="(heat, index) in event.heats"
        v-slot:[`item.heats.${index}.runTime`]="{ item }"
      >
        <span
          v-if="item.heats[index].state === 'FINISHED'"
          :key="heat.id"
          :class="{
            'text-decoration-underline': item.heats[index].highlighted
          }"
        >
          {{ cropTime(formatRunTime(item.heats[index].runTime, precision)) }}
        </span>
        <span
          v-else-if="
            item.heats[index].state === 'CANCELLED' &&
              item.heats[index].cancellation
          "
          :key="heat.id"
        >
          {{ item.heats[index].cancellation }}
        </span>
        <span v-else :key="heat.id">
          -
        </span>
      </template>

      <template
        v-for="(heat, index) in event.heats"
        v-slot:[`item.heats.${index}.speed`]="{ item }"
      >
        <span v-if="item.heats[index].speed" :key="heat.id">
          {{ convertToSpeedUnit(item.heats[index].speed) }}
        </span>
        <span v-else :key="heat.id">
          -
        </span>
      </template>

      <template v-slot:item.runTime="{ item }">
        <span v-if="item.state === 'FINISHED'">
          {{ cropTime(formatRunTime(item.runTime, precision)) }}
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template v-slot:item.diff="{ item }">
        <span v-if="item.state !== 'FINISHED'">
          -
        </span>
        <span v-else-if="item.diff === 0">
          -
        </span>
        <span v-else>
          {{ cropTime(formatRunTime(item.diff, precision)) }}
        </span>
      </template>

      <template v-slot:item.speed.average="{ item }">
        <span v-if="item.speed.average">
          {{ convertToSpeedUnit(item.speed.average) }}
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template v-slot:item.speed.min="{ item }">
        <span v-if="item.speed.min">
          {{ convertToSpeedUnit(item.speed.min) }}
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template v-slot:item.speed.max="{ item }">
        <span v-if="item.speed.max">
          {{ convertToSpeedUnit(item.speed.max) }}
        </span>
        <span v-else>
          -
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TableFilter from "@/components/TableFilter";
import Trophy from "@/components/Trophy";
import CountryFlag from "@/components/CountryFlag";
import whitelist from "@/mixins/whitelist";
import formatRunTime from "@/mixins/formatRunTime";
import cropTime from "@/mixins/cropTime";
import genderIcon from "@/mixins/genderIcon";
import getCompetitorName from "@/mixins/getCompetitorName";
import convertToSpeedUnit from "@/mixins/convertToSpeedUnit";
import headers from "@/mixins/headers";

export default {
  name: "EventRanking",
  components: {
    TableFilter,
    Trophy,
    CountryFlag
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      default: null
    },
    print: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      precisions: ["1s", "1/10s", "1/100s", "1/1000s", "1/10000s", "1/100000s"],
      search: "",
      page: 1,
      itemsPerPage: 10
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      getNationById: "nations/getItemById",
      getCompetitorById: "events/getCompetitorById",
      getCompetitorByUserId: "events/getCompetitorByUserId",
      getFieldPreferenceById: "settings/getFieldPreferenceById"
    }),
    highlightedCompetitor() {
      if (this.$route.query.competitor && this.indexedEventRanking) {
        const competitor = this.getCompetitorByUserId(
          this.$route.query.competitor
        );
        if (competitor) {
          const rankingEntry = this.indexedEventRanking.find(
            el => el.competitorId === competitor?.id
          );

          if (rankingEntry) {
            return new Array(rankingEntry);
          }
        }
      }
      return [];
    },
    printClass() {
      if (this.rankingHeaders.length < 15) {
        return "";
      }
      if (this.rankingHeaders.length < 17) {
        return "print-large";
      }
      if (this.rankingHeaders.length < 19) {
        return "print-medium";
      }
      return "print-small";
    },
    classFilter: {
      get() {
        return this.$store.state.events.selectedItemClassFilter;
      },
      set(value) {
        this.$store.commit("events/updateSelectedItemClassFilter", value);
      }
    },
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    },
    isRegularity() {
      return this.event.setup.regularitySettings.enabled;
    },
    isTargetTime() {
      return this.event.setup.regularitySettings.type === "TARGET_TIME";
    },
    isPerfection() {
      return this.event.setup.regularitySettings.type === "PERFECTION";
    },
    isBestOfRanking() {
      return (
        this.event.settings.rankingBestOfCount > 0 &&
        this.event.settings.rankingBestOfCount < this.event.heats.length
      );
    },
    rankingHeaders() {
      const headers = Array.from(this.headers());
      headers.unshift({
        text: this.$i18n.t("competitors.tables.rank"),
        value: "rank",
        align: "right",
        width: "1%",
        sort: (a, b) => {
          if (a === null) {
            return 1;
          }
          if (b === null) {
            return -1;
          }
          return a - b;
        }
      });

      this.event.heats
        .filter(heat => !heat.excludeFromOverallRanking)
        .map((heat, index) => {
          headers.push({
            text:
              index === 0 && this.isRegularity && this.isTargetTime
                ? this.$i18n.t("competitors.tables.targetTime")
                : heat.name,
            value: this.event.setup.speedEvent
              ? `heats.${index}.speed`
              : `heats.${index}.runTime`,
            align: "right",
            sort: (a, b) => {
              if (a === null) {
                return 1;
              }
              if (b === null) {
                return -1;
              }
              return a - b;
            }
          });
        });

      if (this.event.setup.speedEvent) {
        headers.push(
          {
            text: this.$i18n.t("competitors.tables.average"),
            value: "speed.average",
            align: "right",
            sort: (a, b) => {
              if (a === null) {
                return 1;
              }
              if (b === null) {
                return -1;
              }
              return a - b;
            }
          },
          {
            text: this.$i18n.t("competitors.tables.min"),
            value: "speed.min",
            align: "right",
            sort: (a, b) => {
              if (a === null) {
                return 1;
              }
              if (b === null) {
                return -1;
              }
              return a - b;
            }
          },
          {
            text: this.$i18n.t("competitors.tables.max"),
            value: "speed.max",
            align: "right",
            sort: (a, b) => {
              if (a === null) {
                return 1;
              }
              if (b === null) {
                return -1;
              }
              return a - b;
            }
          }
        );
      } else {
        headers.push(
          {
            text:
              (this.isRegularity && this.isPerfection) || this.isBestOfRanking
                ? this.$i18n.t("competitors.tables.result")
                : this.$i18n.t("competitors.tables.totalTime"),
            value: "runTime",
            align: "right",
            sort: (a, b) => {
              if (a === null) {
                return 1;
              }
              if (b === null) {
                return -1;
              }
              return a - b;
            }
          },
          {
            text: this.$i18n.t("competitors.tables.diff"),
            value: "diff",
            align: "right",
            sort: (a, b) => {
              if (a === null) {
                return 1;
              }
              if (b === null) {
                return -1;
              }
              return a - b;
            }
          }
        );
      }

      return headers;
    },
    indexedEventRanking() {
      let entries = new Array();
      if (this.classFilter.value.value === "all") {
        entries = this.event.overallRanking.entries;
      } else {
        const classDivision = this.event.overallRanking.classDivisionRanking[
          this.classFilter.value.field
        ];
        if (classDivision) {
          const classDivisionValue = classDivision.find(
            el => el.key === this.classFilter.value.value
          );
          if (classDivisionValue) {
            entries = classDivisionValue.entries;
          }
        }
      }

      return entries.map(rankingEntry => {
        const heats = this.event.heats
          .filter(heat => !heat.excludeFromOverallRanking)
          .map((heat, heatIndex) => {
            const heatRankingEntryIndex = heat.ranking.entries.findIndex(
              entry => entry.competitorId === rankingEntry.competitorId
            );
            const heatCycleEntryIndex = heat.heatCycle.entries.findIndex(
              entry => entry.competitorId === rankingEntry.competitorId
            );

            const item = {
              heatId: heat.id,
              runTime:
                heatRankingEntryIndex >= 0 &&
                heat.ranking.entries[heatRankingEntryIndex].state === "FINISHED"
                  ? heat.ranking.entries[heatRankingEntryIndex].runTime
                  : null,
              speed:
                heatCycleEntryIndex >= 0 &&
                heat.heatCycle.entries[heatCycleEntryIndex].state === "FINISHED"
                  ? heat.heatCycle.entries[heatCycleEntryIndex].data.sections[
                      "FINISH"
                    ].speed || null
                  : null,
              state:
                heatCycleEntryIndex >= 0
                  ? heat.heatCycle.entries[heatCycleEntryIndex].state
                  : null,
              cancellation:
                heatCycleEntryIndex >= 0
                  ? heat.heatCycle.entries[heatCycleEntryIndex].data
                      .cancellation
                  : null,
              highlighted: false
            };

            if (heatCycleEntryIndex >= 0 && this.isRegularity) {
              if (this.isPerfection || (this.isTargetTime && heatIndex !== 0)) {
                item.highlighted =
                  !rankingEntry.discardedRuns?.includes(
                    heat.heatCycle.entries[heatCycleEntryIndex].runId
                  ) || false;
              }
            }

            return item;
          });

        const entry = {
          ...rankingEntry,
          heats
        };

        if (!entry.state && !entry.runTime) {
          const eventRankingEntryIndex = this.event.overallRanking.entries.findIndex(
            el => el.competitorId === rankingEntry.competitorId
          );

          if (eventRankingEntryIndex !== -1) {
            Object.assign(entry, {
              state: this.event.overallRanking.entries[eventRankingEntryIndex]
                .state,
              runTime: this.event.overallRanking.entries[eventRankingEntryIndex]
                .runTime
            });
          }
        }

        const competitor = this.getCompetitorById(rankingEntry.competitorId);
        if (competitor) {
          Object.assign(entry, competitor);
        }

        if (this.event.setup.speedEvent) {
          const speedData = this.event.heats.reduce((re, heat) => {
            const heatCycleEntryIndex = heat.heatCycle.entries.findIndex(
              entry => entry.competitorId === rankingEntry.competitorId
            );
            if (
              heatCycleEntryIndex !== -1 &&
              heat.heatCycle.entries[heatCycleEntryIndex].state ===
                "FINISHED" &&
              heat.heatCycle.entries[heatCycleEntryIndex].data.sections[
                "FINISH"
              ].speed
            ) {
              re.push(
                heat.heatCycle.entries[heatCycleEntryIndex].data.sections[
                  "FINISH"
                ].speed
              );
            }
            return re;
          }, []);

          entry.speed = {
            average:
              speedData.length > 0
                ? speedData.reduce((re, el) => re + el, 0) / speedData.length
                : null,
            min: speedData.length > 0 ? Math.min(...speedData) : null,
            max: speedData.length > 0 ? Math.max(...speedData) : null
          };
        }

        if (entry.state === "INCOMPLETE") {
          entry.rank = null;
          entry.runTime = null;
        }

        if (entry.runTime && this.leader) {
          entry.diff = entry.runTime - this.leader.runTime;
        } else {
          entry.diff = null;
        }

        return entry;
      });
    },
    leader() {
      if (this.classFilter.value.value === "all") {
        if (
          this.event.overallRanking.entries.length > 0 &&
          this.event.overallRanking.entries[0].runTime > 0 &&
          this.event.overallRanking.entries[0].state !== "CANCELLED"
        ) {
          return this.event.overallRanking.entries[0];
        }
      } else {
        const classDivision = this.event.overallRanking.classDivisionRanking[
          this.classFilter.value.field
        ];
        if (classDivision) {
          const classDivisionValue = classDivision.find(
            el => el.key === this.classFilter.value.value
          );
          if (classDivisionValue) {
            const entries = classDivisionValue.entries;
            if (entries.length > 0) {
              const entry = entries[0];
              const eventRankingEntry = this.event.overallRanking.entries.find(
                el => el.competitorId === entry.competitorId
              );
              if (
                eventRankingEntry &&
                eventRankingEntry.runTime > 0 &&
                eventRankingEntry.state !== "CANCELLED"
              ) {
                return {
                  competitorId: entry.competitorId,
                  rank: entry.rank,
                  runTime: eventRankingEntry.runTime,
                  state: eventRankingEntry.state
                };
              }
            }
          }
        }
      }

      return null;
    },
    precision() {
      const precision = this.precisions.findIndex(
        el => el === this.event.setup.precision
      );

      return precision >= 0 ? precision : 2;
    },
    classFilters() {
      const items = new Array();

      items.push(
        {
          header: this.$i18n.t("events.classFilter.general")
        },
        {
          divider: true
        },
        {
          text: this.$i18n.t("events.classFilter.all"),
          value: {
            field: "general",
            value: "all"
          }
        }
      );

      for (const [fieldId, fieldValues] of Object.entries(
        this.event.overallRanking.classDivisionRanking
      )) {
        const field = this.event.competitorData.data.find(
          el => el.id === fieldId
        );

        items.push(
          {
            header: this.whitelist.includes(fieldId)
              ? this.$i18n.t(`competitors.tables.${fieldId}`)
              : field.view.label
          },
          {
            divider: true
          }
        );
        fieldValues.map(value => {
          items.push({
            text: value.key,
            value: {
              field: fieldId,
              value: value.key
            }
          });
        });
      }

      return items;
    }
  },
  watch: {
    classFilter() {
      this.page = 1;
    }
  },
  mixins: [
    whitelist,
    formatRunTime,
    cropTime,
    genderIcon,
    getCompetitorName,
    convertToSpeedUnit,
    headers
  ],
  created() {
    if (this.$route.query.competitor && this.highlightedCompetitor) {
      const entryIndex = this.indexedEventRanking.findIndex(
        el => el.id === this.highlightedCompetitor[0].id
      );
      if (entryIndex !== -1) {
        this.$nextTick(() => {
          this.page = Math.floor(entryIndex / this.itemsPerPage) + 1;
          if (this.$refs.eventRankingTable) {
            this.$refs.eventRankingTable.$el.scrollIntoView({
              behavior: "smooth"
            });
          }
        });
      }
    }
    this.$watch("heat", (newValue, oldValue) => {
      if (newValue.id !== oldValue.id) {
        this.page = 1;
      }
    });
  }
};
</script>

<style scoped>
.v-data-table /deep/ th[role="columnheader"] {
  white-space: nowrap;
}
.v-data-table--dense /deep/ tbody tr:nth-child(even),
.v-data-table--dense /deep/ tbody tr:nth-child(even) .firstSectionColumn {
  background: #f7f7f7;
}
.firstSectionColumn {
  white-space: nowrap;
  position: sticky;
  left: 0;
  z-index: 10;
  background: #ffffff;
}
.v-data-table /deep/ tbody tr:hover .firstSectionColumn {
  background: #eeeeee;
}
th.clickable {
  cursor: pointer;
}
.v-data-table /deep/ tbody tr.v-data-table__selected {
  background: #e4f2fd;
}
.v-data-table--dense > .v-data-table__wrapper {
  overflow-y: hidden;
}
.v-data-table--dense /deep/ thead > tr > th,
.v-data-table--dense /deep/ tbody > tr > td {
  padding: 0 10px !important;
}
</style>
