<template>
  <div class="trophy_container">
    <v-icon :class="trophyColor">
      mdi-trophy
    </v-icon>

    <span class="trophy_text">
      {{ rank }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Trophy",
  props: {
    rank: {
      type: Number,
      required: true
    }
  },
  computed: {
    trophyColor() {
      switch (this.rank) {
        case 1:
          return "gold--text";
        case 2:
          return "silver--text";
        case 3:
          return "copper--text";
        default:
          return "";
      }
    }
  }
};
</script>
<style scoped>
.trophy_container {
  position: relative;
  display: inline;
}
.trophy_text {
  position: absolute;
  top: -6%;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-weight: bold;
  font-size: smaller;
}
.gold--text {
  color: #ffc107 !important;
}
.silver--text {
  color: #b0c4de !important;
}
.copper--text {
  color: #a52a2a !important;
}
</style>
