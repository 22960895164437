var render = function render(_c,_vm){return _c('div',[(
      _vm.props.serverTimestamp &&
        _vm.props.competitorStartSectionTime !== null &&
        _vm.props.leaderRunTime !== null
    )?_c('span',{class:_vm.props.serverTimestamp -
        _vm.props.competitorStartSectionTime -
        _vm.props.leaderRunTime <=
      0
        ? 'success--text'
        : 'error--text'},[_vm._v(" "+_vm._s(_vm.props.serverTimestamp - _vm.props.competitorStartSectionTime - _vm.props.leaderRunTime <= 0 ? "-" : "+")+_vm._s(_vm.props.cropTime( _vm.props.formatRunTime( _vm.props.serverTimestamp - _vm.props.competitorStartSectionTime - _vm.props.leaderRunTime, 1, false ) ))+" ")]):_c('span',[_vm._v(" - ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }