<template>
  <v-dialog v-model="showDialog" width="600" scrollable>
    <v-form @submit.prevent="submitForm">
      <v-card v-if="logEntry">
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $t("forms.log.title") }}
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon @click="showDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text class="py-0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  :label="$t('forms.log.comment')"
                  v-model.trim="$v.form.comment.$model"
                  :error-messages="commentErrors"
                  :counter="250"
                  clearable
                  auto-grow
                  autofocus
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex justify-space-between">
          <v-btn depressed :disabled="loading" @click="showDialog = false">
            {{ $t("forms.log.cancel") }}
          </v-btn>

          <v-btn type="submit" color="primary" :loading="loading">
            {{ $t("forms.log.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import { maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "LogEntryEdit",
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showDialog: false,
      logEntry: null,
      form: {
        comment: null
      },
      loading: false,
      error: null
    };
  },
  validations() {
    return {
      form: {
        comment: {
          maxLength: maxLength(250)
        }
      }
    };
  },
  computed: {
    commentErrors() {
      const errors = [];
      if (!this.$v.form.comment.$dirty) return errors;
      !this.$v.form.comment.maxLength &&
        errors.push(
          this.$i18n.t("forms.log.errors.comment.maxLength", { length: 250 })
        );
      return errors;
    }
  },
  methods: {
    ...mapActions({
      updateLogEntry: "events/updateLogEntry"
    }),
    openDialog(logEntry) {
      this.resetDialog();
      this.initData(logEntry);
      this.showDialog = true;
    },
    resetDialog() {
      this.$v.$reset();

      this.error = null;
    },
    initData(logEntry) {
      this.logEntry = logEntry;

      this.form.comment = logEntry.text;
    },
    submitForm() {
      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.loading = true;
        this.error = null;

        const payload = {
          eventId: this.event.id,
          logId: this.logEntry.id,
          data: {
            value: this.form.comment
          }
        };

        this.updateLogEntry(payload)
          .then(response => {
            this.loading = false;
            this.showDialog = false;
          })
          .catch(response => {
            switch (response.data.status) {
              default:
                this.error = "errors.unexpectedError";
            }
            this.loading = false;
          });
      }
    }
  }
};
</script>
