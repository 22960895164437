const dateTime = value => {
  if (
    typeof value === "undefined" ||
    value === null ||
    value === "" ||
    value.hasOwnProperty("timestamp")
  ) {
    return true;
  }
  return /^((2[0-3])|([0-1][0-9])|([0-9])):([0-5][0-9]):([0-5][0-9])[.,]([0-9][0-9][0-9][0-9][0-9])?$/.test(
    value
  );
};

export default dateTime;
