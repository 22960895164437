var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"firstSectionColumn",staticStyle:{"width":"1%"},attrs:{"role":"columnheader"}}),_vm._l((_vm.isOwner ? _vm.liveHeadersOwner : _vm.liveHeaders),function(header){return _c('th',{key:header.value,class:[header.clickHandler ? 'clickable' : ''],style:({ textAlign: header.align, width: header.width }),attrs:{"role":"columnheader"},on:{"click":function($event){header.clickHandler ? header.clickHandler() : null}}},[_vm._v(" "+_vm._s(header.text)+" ")])})],2)]),_c('tbody',_vm._l((_vm.liveTableEntries),function(liveTableEntry){return _c('Section',{key:liveTableEntry.competitorData
            ? `${liveTableEntry.sectionIndex}-${
                liveTableEntry.runIndex
              }-${liveTableEntry.competitorData.id ||
                liveTableEntry.competitorData.competitorId}`
            : `${liveTableEntry.sectionIndex}-${liveTableEntry.runIndex}`,attrs:{"entry":liveTableEntry,"event":_vm.event,"heat":_vm.heat,"columns":_vm.isOwner ? _vm.liveHeadersOwner : _vm.liveHeaders,"liveTime":_vm.liveTimes[_vm.curLiveTimeIndex],"leaderData":_vm.sectionLeaderData,"serverTimestamp":_vm.serverTimestamp,"decimals":_vm.precision,"sectionItems":_vm.getSectionItems(liveTableEntry.sectionIndex),"sectionItemsPerPage":_vm.sectionItemsPerPage,"sectionIndexOffset":_vm.sectionIndexOffsets[liveTableEntry.sectionIndex]},on:{"decreaseOffset":_vm.decreaseSectionOffset,"increaseOffset":_vm.increaseSectionOffset,"cancel-action":(heatId, competitorId, reason) =>
            _vm.$emit('cancel-action', heatId, competitorId, reason),"open-edit-action":competitor => _vm.$emit('open-edit-action', competitor),"open-edit-run-action":(heatId, competitorId) =>
            _vm.$emit('open-edit-run-action', heatId, competitorId),"restart-action":(heatId, competitorId, includeStartgroup) =>
            _vm.$emit('restart-action', heatId, competitorId, includeStartgroup),"edit-competitor-comment":competitorId => _vm.$emit('edit-competitor-comment', competitorId),"open-start-number-input":function($event){return _vm.$emit('open-start-number-input')}}})}),1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }