<template>
  <v-dialog
    v-model="dialog"
    width="500"
    @click:outside="closeDialog"
    scrollable
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("export.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text class="py-0">
        <v-list class="sortable_container">
          <v-list-item class="pa-0">
            <v-list-item-content class="mr-1">
              <v-btn @click="clearColumns" color="warning" small>
                {{ $t("export.clearAll") }}
              </v-btn>
            </v-list-item-content>
            <v-list-item-content class="ml-1">
              <v-btn @click="fillColumns" color="primary" small>
                {{ $t("export.showAll") }}
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(column, index) in columns"
            :key="column.id"
            class="sortable_item white elevation-1 my-1"
          >
            <v-list-item-action class="sortable_item_handle">
              <v-icon>mdi-drag-horizontal-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-autocomplete
                v-model="column.props"
                :items="availableFields"
                item-text="selectText"
                auto-select-first
                hide-details
                return-object
              ></v-autocomplete>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                @click="removeColumn(index)"
                icon
                :disabled="columns.length === 1"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn @click="addColumn" color="primary" class="mr-1">
          {{ $t("export.addColumn") }}
        </v-btn>

        <v-btn @click="submit" color="success" class="ml-1">
          {{ $t("export.submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { Sortable } from "@shopify/draggable/lib/es5/draggable.bundle.legacy";
import cuid from "cuid";

export default {
  name: "ExportSelectionDialog",
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      columns: new Array(),
      availableFields: [
        {
          value: "heat-rank",
          selectText: this.$i18n.t("export.columns.heatRank"),
          text: this.$i18n.t("competitors.tables.rank")
        },
        ...this.event.competitorData.data.map(field => ({
          value: `competitor-${field.id}`,
          selectText: [
            "startNumber",
            "firstName",
            "lastName",
            "gender",
            "dateOfBirth",
            "nation",
            "club",
            "team",
            "class",
            "mail"
          ].includes(field.id)
            ? this.$i18n.t(`competitors.tables.${field.id}`)
            : field.view.label,
          text: [
            "startNumber",
            "firstName",
            "lastName",
            "gender",
            "dateOfBirth",
            "nation",
            "club",
            "team",
            "class",
            "mail"
          ].includes(field.id)
            ? this.$i18n.t(`competitors.tables.${field.id}`)
            : field.view.label
        })),
        {
          value: "heat-number",
          selectText: this.$i18n.t("export.columns.heatNumber"),
          text: this.$i18n.t("competitors.tables.heat")
        },
        {
          value: "heat-start-time",
          selectText: this.$i18n.t("export.columns.startTime"),
          text: this.$i18n.t("export.columns.startTime")
        },
        {
          value: "heat-finish-time",
          selectText: this.$i18n.t("export.columns.finishTime"),
          text: this.$i18n.t("export.columns.finishTime")
        },
        {
          value: "heat-run-time",
          selectText: this.$i18n.t("competitors.tables.runTime"),
          text: this.$i18n.t("competitors.tables.runTime")
        },
        {
          value: "heat-run-time-diff",
          selectText: this.$i18n.t("competitors.tables.diff"),
          text: this.$i18n.t("competitors.tables.diff")
        },
        ...this.event.setup.sections
          .slice(1, -1)
          .map((section, index) => {
            const position = index + 1;
            return [
              {
                value: `heat-intermediate-${position}-time`,
                selectText: this.$i18n.t("export.columns.intermediateTime", {
                  position
                }),
                text: `Int${position}`
              },
              {
                value: `heat-intermediate-${index + 1}-time-diff`,
                selectText: this.$i18n.t("export.columns.intermediateDiff", {
                  position: index + 1
                }),
                text: this.$i18n.t("competitors.tables.diff")
              },
              {
                value: `heat-intermediate-${index + 1}-rank`,
                selectText: this.$i18n.t("export.columns.intermediateRank", {
                  position: index + 1
                }),
                text: this.$i18n.t("competitors.tables.rank")
              }
            ];
          })
          .flat(),
        ...this.event.setup.sections
          .slice(1, -1)
          .map((section, index) => {
            const position = index + 1;
            return [
              {
                value: `heat-section-${position}-time`,
                selectText: this.$i18n.t("export.columns.sectionTime", {
                  position
                }),
                text:
                  position < this.event.setup.sections.length - 2
                    ? `I${position}-I${position + 1}`
                    : `I${position}-FIN`
              },
              {
                value: `heat-section-${position}-time-diff`,
                selectText: this.$i18n.t("export.columns.sectionDiff", {
                  position
                }),
                text: this.$i18n.t("competitors.tables.diff")
              },
              {
                value: `heat-section-${position}-rank`,
                selectText: this.$i18n.t("export.columns.sectionRank", {
                  position
                }),
                text: this.$i18n.t("competitors.tables.rank")
              }
            ];
          })
          .flat(),
        ...this.event.setup.sections
          .filter(section => section.hasOwnProperty("distance"))
          .map((section, index) => {
            const position = index + 1;
            return [
              {
                value: `heat-speed-${position}-speed`,
                selectText: this.$i18n.t("export.columns.speedSpeed", {
                  position
                }),
                text: this.$i18n.t("export.columns.speed", { position })
              },
              {
                value: `heat-speed-${position}-rank`,
                selectText: this.$i18n.t("export.columns.speedRank", {
                  position
                }),
                text: this.$i18n.t("competitors.tables.rank")
              }
            ];
          })
          .flat()
      ]
    };
  },
  computed: {
    ...mapGetters({
      getSelectionPreferencesByEventId:
        "settings/getSelectionPreferencesByEventId"
    })
  },
  methods: {
    ...mapMutations({
      setSelectionPreferences: "settings/setSelectionPreferences"
    }),
    openDialog() {
      this.dialog = true;
      this.initData();
      this.$nextTick(() => this.initDraggable());
    },
    closeDialog() {
      this.dialog = false;
    },
    initData() {
      const selectionPreference = this.getSelectionPreferencesByEventId(
        this.event.id
      );

      if (selectionPreference) {
        this.columns = selectionPreference.filter(
          el =>
            this.availableFields.findIndex(
              field => field.value === el.props.value
            ) !== -1
        );
      } else {
        this.columns = new Array();
        const lastFieldIndex = this.availableFields.findIndex(
          el => el.value === "heat-run-time-diff"
        );
        this.availableFields
          .slice(0, lastFieldIndex + 1)
          .map(field => this.columns.push({ id: cuid(), props: field }));
      }
    },
    initDraggable() {
      const containerSelector = ".sortable_container";
      const containers = document.querySelectorAll(containerSelector);

      if (containers.length === 0) {
        return false;
      }

      const sortable = new Sortable(containers, {
        draggable: ".sortable_item",
        handle: ".sortable_item_handle",
        mirror: {
          constrainDimensions: true
        }
      });

      sortable.on("sortable:stop", ({ oldIndex, newIndex }) => {
        this.columns = this.moveItem(this.columns, oldIndex, newIndex);
      });
    },
    moveItem(items, oldIndex, newIndex) {
      const itemRemovedArray = [
        ...items.slice(0, oldIndex),
        ...items.slice(oldIndex + 1, items.length)
      ];
      return [
        ...itemRemovedArray.slice(0, newIndex),
        items[oldIndex],
        ...itemRemovedArray.slice(newIndex, itemRemovedArray.length)
      ];
    },
    addColumn() {
      this.columns.push({
        id: cuid(),
        props: null
      });
    },
    removeColumn(index) {
      Vue.delete(this.columns, index);
    },
    clearColumns() {
      this.columns = [
        {
          id: cuid(),
          props: null
        }
      ];
    },
    fillColumns() {
      this.columns = this.availableFields.map(field => ({
        id: cuid(),
        props: field
      }));
    },
    submit() {
      this.setSelectionPreferences({
        eventId: this.event.id,
        columns: this.columns
      });
      this.$emit(
        "submit",
        this.columns.map(column => column.props)
      );
      this.closeDialog();
    }
  }
};
</script>
<style scoped>
.sortable_item_handle {
  cursor: grab;
}
.draggable-source--is-dragging {
  opacity: 0.5;
}
</style>
