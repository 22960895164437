/* eslint-disable */

const mixin = {
  methods: {
    getDeviceBySectionIndex(sectionIndex) {
      if (this.event && this.event.setup.sections[sectionIndex].devices.length > 0) {
        for (let i = 0; i < this.event.setup.sections[sectionIndex].devices.length; i++) {
          const device = this.getDeviceById(this.event.setup.sections[sectionIndex].devices[i].deviceId);
          if (device) {
            return device;
          }
        }
      }
      return null;
    }
  }
};

export default mixin;
