<template>
  <v-dialog
    v-if="heat"
    v-model="show"
    scrollable
    max-width="500"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card :loading="isLoading">
      <v-toolbar flat>
        <v-toolbar-title>
          {{
            isOwner
              ? $t("startNumberInput.ownerTitle")
              : $t("startNumberInput.guestTitle")
          }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-text-field
        :label="$t('startNumberInput.filterLabel')"
        v-model="query"
        append-icon="mdi-magnify"
        class="px-4 py-6"
        hide-details
        autofocus
        tabindex="0"
      />

      <v-list dense>
        <v-list-item
          v-for="(entry, index) in filteredStartList"
          :key="entry.id"
          :style="[
            {
              height: '64px'
            },
            nextCompetitor.id === entry.id ? nextCompetitorStyleObject : '',
            selectedEntryIndex === index
              ? { backgroundColor: '#E3F2FD !important' }
              : { backgroundColor: '#ffffff !important' }
          ]"
        >
          <v-list-item-icon>
            <v-avatar
              :color="nextCompetitor.id === entry.id ? 'success' : 'primary'"
              rounded
            >
              <span class="white--text text-h6">
                {{ entry.startNumber }}
              </span>
            </v-avatar>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-h6" style="line-height: 1.2;">
              {{ entry.userData.lastName }} {{ entry.userData.firstName }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              v-if="nextCompetitor.id === entry.id"
              icon
              fab
              small
              outlined
              color="success"
              disabled
            >
              <v-icon>
                mdi-home
              </v-icon>
            </v-btn>
            <v-btn
              v-else-if="isOwner"
              icon
              fab
              small
              outlined
              color="primary"
              :disabled="isLoading"
              @click="clickHandler(entry.id)"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "StartNumberInputDialog",
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      show: false,
      query: "",
      nextCompetitorStyleObject: {
        position: "sticky",
        top: 0,
        zIndex: 10
      },
      isLoading: false,
      selectedEntryIndex: null
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      getCompetitorById: "events/getCompetitorById"
    }),
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    },
    startList() {
      const heatCycleEntries = new Set();
      this.heat.heatCycle.entries.map(entry =>
        heatCycleEntries.add(entry.competitorId)
      );

      return this.heat.competitors.reduce((competitors, competitor) => {
        if (!heatCycleEntries.has(competitor)) {
          competitors.push(this.getCompetitorById(competitor));
        }
        return competitors;
      }, []);
    },
    filteredStartList() {
      return this.startList.filter(competitor => {
        const query = String(this.query).toLowerCase();
        const startNumber = String(competitor.startNumber);
        const lastName = String(competitor.userData.lastName).toLowerCase();
        const firstName = String(competitor.userData.firstName).toLowerCase();
        return (
          startNumber.includes(query) ||
          lastName.includes(query) ||
          firstName.includes(query) ||
          `${lastName} ${firstName}`.includes(query)
        );
      });
    },
    nextCompetitor() {
      return this.startList[0] ?? null;
    }
  },
  watch: {
    query() {
      // no results -> set null
      // 1 result and not the next competitor -> set index
      // 1 result and the next competitor -> set null
      // more than 1 result and the first is the next competitor -> set index 1
      // more than 1 result and the first is not the next competitor -> set index

      if (this.filteredStartList.length > 0) {
        if (this.filteredStartList.length === 1) {
          if (this.filteredStartList[0].id === this.nextCompetitor.id) {
            this.selectedEntryIndex = null;
          } else {
            this.selectedEntryIndex = 0;
          }
        } else {
          if (this.filteredStartList[0].id === this.nextCompetitor.id) {
            this.selectedEntryIndex = 1;
          } else {
            this.selectedEntryIndex = 0;
          }
        }
      } else {
        this.selectedEntryIndex = null;
      }
    }
  },
  methods: {
    ...mapActions({
      setStartCompetitor: "events/setStartCompetitor"
    }),
    openDialog() {
      this.query = "";
      this.show = true;
      if (this.isOwner && this.filteredStartList.length > 1) {
        this.selectedEntryIndex = 1;
      }
      document.addEventListener("keydown", this.handleKeyDown);
    },
    closeDialog() {
      this.show = false;
      document.removeEventListener("keydown", this.handleKeydown);
    },
    submitNewStartCompetitor(competitorId) {
      this.isLoading = true;

      const payload = {
        eventId: this.event.id,
        heatId: this.heat.id,
        competitorId
      };

      this.setStartCompetitor(payload)
        .then(() => {
          this.selectedEntryIndex = 1;
        })
        .finally(() => {
          this.isLoading = false;
          this.query = "";
        });
    },
    clickHandler(competitorId) {
      if (this.isOwner) {
        this.submitNewStartCompetitor(competitorId);
      }
    },
    handleKeyDown(event) {
      if (!this.show || !this.isOwner) return;

      if (Number.isInteger(this.selectedEntryIndex) && !this.isLoading) {
        switch (event.key) {
          case "ArrowUp":
            event.preventDefault();
            this.selectedEntryIndex = Math.max(1, this.selectedEntryIndex - 1);
            break;
          case "ArrowDown":
            event.preventDefault();
            this.selectedEntryIndex = Math.min(
              this.filteredStartList.length - 1,
              this.selectedEntryIndex + 1
            );
            break;
          case "Enter":
            event.preventDefault();
            const entry = this.filteredStartList[this.selectedEntryIndex];
            if (entry) {
              this.submitNewStartCompetitor(entry.id);
            }
            break;
        }
      }
    },
    mounted() {
      this.$el.focus();
    }
  }
};
</script>
