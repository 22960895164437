<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    style="z-index: 20"
  >
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip top :open-delay="300">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn icon v-on="{ ...onMenu, ...onTooltip }">
            <v-icon>mdi-tune</v-icon>
          </v-btn>
        </template>
        {{ $t("hints.tableFilter") }}
      </v-tooltip>
    </template>

    <v-card>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr v-for="(label, id) in fields" :key="id">
              <td>{{ label }}</td>
              <td class="text-right">
                <v-simple-checkbox
                  color="primary"
                  :ripple="false"
                  :value="getFieldPreferenceById(id)"
                  @input="toggleFieldPreference(id)"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import whitelist from "@/mixins/whitelist";

export default {
  name: "TableFilter",
  props: {
    event: {
      type: Object,
      required: true
    },
    reduced: {
      type: Boolean,
      default: false
    },
    timingFields: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      fieldPreferences: state => state.settings.fieldPreferences
    }),
    ...mapGetters({
      getFieldPreferenceById: "settings/getFieldPreferenceById"
    }),
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    },
    fields() {
      const fields = new Object();

      const competitorFields = this.event.competitorData.data
        .filter(field => {
          switch (field.id) {
            case "startNumber":
              return true;
            case "email":
              return false;
            default:
              return (
                field.accessControl.other.read ||
                (this.isOwner && field.accessControl.competitor.write)
              );
          }
        })
        .reduce((fields, field) => {
          if (field.id === "firstName" || field.id === "lastName") {
            if (!fields.hasOwnProperty("name")) {
              fields["name"] = this.$i18n.t("competitors.tables.name");
            }
          } else {
            fields[field.id] = this.whitelist.includes(field.id)
              ? this.$i18n.t(`competitors.tables.${field.id}`)
              : field.view.label;
          }

          return fields;
        }, {});
      Object.assign(fields, competitorFields);

      if (this.timingFields) {
        const timingFields = new Object();
        const intermediateSections = this.event.setup.sections.slice(1, -1);
        const speedSections = this.event.setup.sections.filter(section =>
          section.hasOwnProperty("distance")
        );
        if (intermediateSections.length > 0) {
          timingFields.sectionTime = this.$i18n.t(
            "competitors.tables.intermediateTimes"
          );
          timingFields.splitTime = this.$i18n.t(
            "competitors.tables.sectionTimes"
          );
        }
        if (speedSections.length > 0) {
          timingFields.speed = this.$i18n.t("competitors.tables.speeds");
        }
        Object.assign(fields, timingFields);
      }

      return fields;
    }
  },
  mixins: [whitelist],
  methods: {
    ...mapMutations({
      toggleFieldPreference: "settings/toggleFieldPreference"
    })
  }
};
</script>
