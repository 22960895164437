<template>
  <v-dialog
    v-model="showDialog"
    width="900"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("forms.trigger.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text class="pa-0 ma-0">
        <v-data-table
          v-model="selectedTrigger"
          class="eventTriggerTable"
          :headers="headers"
          :items="filteredTrigger"
          item-key="timestamp"
          :loading="isLoading"
          single-select
          :page.sync="table.page"
          :items-per-page.sync="table.itemsPerPage"
          mobile-breakpoint="0"
          @click:row="selectTrigger"
        >
          <template v-slot:body.prepend>
            <tr>
              <td>
                <v-text-field v-model="table.filters.timestamp" />
              </td>

              <td>
                <v-text-field v-model="table.filters.netTime" />
              </td>

              <td>
                <v-text-field v-model="table.filters.startNumber" />
              </td>

              <td colspan="2"></td>

              <td>
                <v-select
                  v-model="table.filters.isUsed"
                  :items="isUsedValues"
                  :item-text="item => $t(`trigger.table.${item.text}`)"
                  clearable
                ></v-select>
              </td>

              <td>
                <v-text-field v-model="table.filters.usedBy" />
              </td>
            </tr>
          </template>

          <template v-slot:item.timestamp="{ item }">
            <span style="white-space:nowrap">
              {{
                formatTimestamp_100ns(
                  item.timestamp,
                  item.timeOffset ? item.timeOffset : null,
                  false
                )
              }}
            </span>
          </template>

          <template v-slot:item.netTime="{ item }">
            <span
              :class="item.netTime < 0 ? 'error--text' : ''"
              style="white-space:nowrap"
            >
              {{ cropTime(formatRunTime(item.netTime, 5)) }}
            </span>
          </template>

          <template v-slot:item.isUsed="{ item }">
            <v-icon>{{
              item.isUsed ? "mdi-radiobox-marked" : "mdi-radiobox-blank"
            }}</v-icon>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-0">
        <v-container fluid>
          <v-row dense align="center">
            <v-col cols="12" md="6" class="text-md-left">
              <v-btn-toggle
                v-model="autoUpdate"
                active-class="success"
                borderless
                dense
                style="width: 100%"
              >
                <v-btn
                  :value="true"
                  elevation="2"
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
                  :class="autoUpdate ? 'white--text' : ''"
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  {{
                    autoUpdate
                      ? $t("forms.trigger.autoUpdateEnabled")
                      : $t("forms.trigger.autoUpdateDisabled")
                  }}
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col cols="12" md="6" class="d-flex justify-end">
              <v-btn
                :small="$vuetify.breakpoint.smAndDown"
                class="mr-2 flex-grow-1 flex-md-grow-0"
                @click="handleTriggerReload"
              >
                {{ $t("forms.trigger.reload") }}
              </v-btn>

              <v-btn
                type="submit"
                color="primary"
                :disabled="
                  selectedTrigger === null || selectedTrigger.length === 0
                "
                :small="$vuetify.breakpoint.smAndDown"
                class="flex-grow-1 flex-md-grow-0"
                @click="submitTriggerSelection"
              >
                {{ $t("forms.trigger.submit") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import formatTimestamp from "@/mixins/formatTimestamp";
import formatRunTime from "@/mixins/formatRunTime";
import cropTime from "@/mixins/cropTime";

export default {
  name: "EventTriggerDialog",
  props: {
    event: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    },
    sections: {
      type: Array,
      required: true
    },
    position: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showDialog: false,
      isLoading: false,
      sectionDevices: null,
      selectedTrigger: [],
      manualTrigger: null,
      table: {
        page: 1,
        itemsPerPage: 10,
        filters: {
          timestamp: "",
          netTime: "",
          startNumber: "",
          isUsed: "",
          usedBy: ""
        }
      },
      isUsedValues: [
        {
          value: true,
          text: "yes"
        },
        {
          value: false,
          text: "no"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      eventTrigger: state => state.events.selectedItemTrigger,
      triggerAutoUpdate: state => state.events.triggerAutoUpdate
    }),
    ...mapGetters({
      getDeviceById: "devices/getItemById",
      isTriggerUsed: "events/isTriggerUsed",
      getStartNumbersOfUsedTrigger: "events/getStartNumbersOfUsedTrigger"
    }),
    headers() {
      return [
        {
          text: this.$i18n.t("forms.trigger.table.timestamp"),
          value: "timestamp",
          align: "right",
          filter: (value, search, item) => {
            return this.formatTimestamp_100ns(value, item.timeOffset)
              .toLowerCase()
              .includes(String(this.table.filters.timestamp).toLowerCase());
          }
        },
        {
          text: this.$i18n.t("forms.trigger.table.netTime"),
          value: "netTime",
          align: "right",
          filter: value =>
            this.formatRunTime(value, 5)
              .toLowerCase()
              .includes(String(this.table.filters.netTime).toLowerCase())
        },
        {
          text: this.$i18n.t("forms.trigger.table.startNumber"),
          value: "startNumber.startNumber",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.table.filters.startNumber).toLowerCase())
        },
        {
          text: this.$i18n.t("forms.trigger.table.deviceName"),
          value: "device.deviceComponents.AppSettings.name"
        },
        {
          text: this.$i18n.t("forms.trigger.table.channel"),
          value: "timingChannel"
        },
        {
          text: this.$i18n.t("forms.trigger.table.used"),
          value: "isUsed",
          filter: value => {
            if (
              this.table.filters.isUsed !== null &&
              this.table.filters.isUsed !== undefined &&
              this.table.filters.isUsed !== ""
            ) {
              return value === this.table.filters.isUsed;
            }
            return true;
          }
        },
        {
          text: this.$i18n.t("forms.trigger.table.usedBy"),
          value: "startNumbers",
          filter: value =>
            String(value)
              .toLowerCase()
              .includes(String(this.table.filters.usedBy).toLowerCase())
        }
      ];
    },
    filteredTrigger() {
      if (this.eventTrigger && this.sectionDevices) {
        let trigger = this.eventTrigger.filter(
          el =>
            (!el.deviceId && !el.timingChannel) ||
            this.sectionDevices.has(`${el.deviceId}-${el.timingChannel}`)
        );

        if (this.manualTrigger !== null) {
          trigger = trigger.concat(this.manualTrigger);
        }

        return trigger
          .sort((b, a) => a.timestamp - b.timestamp)
          .map((el, index) => ({
            id: index,
            ...el,
            netTime: this.getNetTime(el),
            device: el.deviceId
              ? this.$store.getters["devices/getItemById"](el.deviceId)
              : null,
            isUsed: this.isTriggerUsed(el.timestamp),
            startNumbers: this.getStartNumbersOfUsedTrigger(el.timestamp)
          }));
      }

      return [];
    },
    autoUpdate: {
      get() {
        return this.triggerAutoUpdate ? true : undefined;
      },
      set() {
        this.toggleTriggerAutoUpdate();
      }
    }
  },
  mixins: [formatTimestamp, formatRunTime, cropTime],
  methods: {
    ...mapMutations({
      toggleTriggerAutoUpdate: "events/toggleTriggerAutoUpdate"
    }),
    ...mapActions({
      fetchSelectedItemTrigger: "events/fetchSelectedItemTrigger"
    }),
    openDialog() {
      this.initData();
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    initData() {
      this.manualTrigger = null;
      this.setSectionDevices();

      if (this.eventTrigger === null) {
        this.handleFetchEventTriggers();
      } else {
        this.setSelectedTrigger();
      }
    },
    handleTriggerReload() {
      this.handleFetchEventTriggers();
    },
    handleFetchEventTriggers() {
      this.isLoading = true;

      const payload = {
        eventId: this.event.id
      };

      this.fetchSelectedItemTrigger(payload)
        .then(() => {
          this.isLoading = false;
          this.setSelectedTrigger();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    setSectionDevices() {
      this.sectionDevices = new Set();
      this.event.setup.sections[this.position].devices.map(el =>
        this.sectionDevices.add(`${el.deviceId}-${el.channel}`)
      );
    },
    setSelectedTrigger() {
      if (this.section.time.timestamp && this.section.time.timeOffset) {
        // jump to the page with the selected timestamp and highlight it
        let triggerIndex = this.filteredTrigger.findIndex(
          el =>
            el.timestamp === this.section.time.timestamp &&
            el.timeOffset === this.section.time.timeOffset
        );

        if (triggerIndex > -1) {
          Vue.set(this.selectedTrigger, 0, this.filteredTrigger[triggerIndex]);
          this.goToTablePageByIndex(triggerIndex);
        } else {
          this.manualTrigger = {
            timestamp: this.section.time.timestamp,
            timeOffset: this.section.time.timeOffset
          };

          triggerIndex = this.filteredTrigger.findIndex(
            el =>
              el.timestamp === this.section.time.timestamp &&
              el.timeOffset === this.section.time.timeOffset
          );

          Vue.set(this.selectedTrigger, 0, this.filteredTrigger[triggerIndex]);
          this.goToTablePageByIndex(triggerIndex);
        }
      } else {
        // jump to the page with near 0 net time
        const sortedTriggers = [...this.filteredTrigger].sort(
          (a, b) => a.netTime - b.netTime
        );
        const triggerIndex = this.filteredTrigger.findIndex(
          el => el.netTime === sortedTriggers[0].netTime
        );
        this.goToTablePageByIndex(triggerIndex);
      }
    },
    goToTablePageByIndex(index) {
      this.table.page = Math.ceil((index + 1) / this.table.itemsPerPage);
    },
    selectTrigger(item, row) {
      row.select();
    },
    submitTriggerSelection() {
      this.$emit("triggerSelected", this.position, this.selectedTrigger[0]);
      this.closeDialog();
    },
    getNetTime(trigger) {
      if (this.section && this.sections && this.sections.length > 0) {
        const timestamp = trigger.timestamp + (trigger.timeOffset * -1) / 60;
        const startTime = this.sections[0].time;
        const finishTime = this.sections[this.sections.length - 1].time;

        if (
          this.section.type === "START" &&
          finishTime.timestamp &&
          finishTime.timeOffset
        ) {
          const finishTimestamp =
            finishTime.timestamp + (finishTime.timeOffset * -1) / 60;
          return finishTimestamp - timestamp;
        } else if (startTime.timestamp && startTime.timeOffset) {
          const startTimestamp =
            startTime.timestamp + (startTime.timeOffset * -1) / 60;
          return timestamp - startTimestamp;
        }
      }

      return null;
    }
  }
};
</script>
<style>
.eventTriggerTable tr.v-data-table__selected {
  color: white !important;
  background: #2b81d6 !important;
}
.eventTriggerTable tr.v-data-table__selected .v-icon,
.eventTriggerTable tr.v-data-table__selected .error--text {
  color: white !important;
}
.eventTriggerTable th[role="columnheader"] {
  white-space: nowrap;
}
</style>
