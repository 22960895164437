<template>
  <div>
    <v-menu v-model="menu[0]" transition="scale-transition" offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :loading="isLoading"
          :color="iconColor(buttonIcon)"
          small
          class="ma-1"
        >
          <v-icon>{{ buttonIcon }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item class="downloadItem" @click="raceResultsHandler">
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("export.raceResults") }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :small="$vuetify.breakpoint.smAndUp">mdi-download</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-menu v-model="menu[1]" transition="scale-transition" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("export.training") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
          <v-list>
            <v-menu v-model="menu[2]" transition="scale-transition" offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("export.total") }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
              <v-list>
                <v-list-item
                  class="downloadItem"
                  @click="trainingHandler(false, 'RANK')"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("export.rank") }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :small="$vuetify.breakpoint.smAndUp"
                      >mdi-download</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  class="downloadItem"
                  @click="trainingHandler(false, 'START_TIME')"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("export.startTime") }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :small="$vuetify.breakpoint.smAndUp"
                      >mdi-download</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu v-model="menu[3]" transition="scale-transition" offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("export.heat") }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
              <v-list>
                <v-list-item
                  class="downloadItem"
                  @click="trainingHandler(true, 'RANK')"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("export.rank") }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :small="$vuetify.breakpoint.smAndUp"
                      >mdi-download</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  class="downloadItem"
                  @click="trainingHandler(true, 'START_TIME')"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("export.startTime") }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :small="$vuetify.breakpoint.smAndUp"
                      >mdi-download</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list>
        </v-menu>
        <v-list-item class="downloadItem" @click="protocolHandler">
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("export.protocol") }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :small="$vuetify.breakpoint.smAndUp">mdi-download</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item class="downloadItem" @click="selectColumnsHandler">
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("export.selection") }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :small="$vuetify.breakpoint.smAndUp">mdi-download</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <ExportSelectionDialog
      ref="exportSelection"
      :event="event"
      @submit="selectionHandler"
    />
  </div>
</template>

<script>
import XLSX from "xlsx";
import { mapGetters } from "vuex";
import ExportSelectionDialog from "@/components/ExportSelectionDialog";
import formatRunTime from "@/mixins/formatRunTime";
import formatTimestamp from "@/mixins/formatTimestamp";
import convertToSpeedUnit from "@/mixins/convertToSpeedUnit";

export default {
  name: "ResultsExportMenu",
  components: {
    ExportSelectionDialog
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      buttonIcon: "mdi-microsoft-excel",
      precisions: ["1s", "1/10s", "1/100s", "1/1000s", "1/10000s", "1/100000s"],
      menu: [false, false, false, false]
    };
  },
  computed: {
    ...mapGetters({
      getCompetitorById: "events/getCompetitorById"
    }),
    decimals() {
      const precision = this.precisions.findIndex(
        el => el === this.event.setup.precision
      );

      if (precision > 0) {
        return `.${new Array(Math.min(3, precision) + 1).join("0")}`;
      }

      return "";
    }
  },
  mixins: [formatRunTime, formatTimestamp, convertToSpeedUnit],
  methods: {
    selectColumnsHandler() {
      this.$refs.exportSelection.openDialog();
    },
    selectionHandler(columns) {
      try {
        this.isLoading = true;
        const wb = XLSX.utils.book_new();
        wb.Props = {
          Title: `${this.event.name} - ${this.$i18n.t("export.selection")}`,
          Author: "ALGE-Results"
        };

        const entries = new Array();
        this.event.heats.map((heat, index) => {
          entries[index] = new Array();
          const heatLeaderRunTime =
            heat.ranking.entries.length > 0
              ? heat.ranking.entries[0].runTime
              : 0;
          heat.ranking.entries.map(heatRankingEntry => {
            const competitor = this.getCompetitorById(
              heatRankingEntry.competitorId
            );
            const heatCycleEntry = heat.heatCycle.entries.find(
              el => el.competitorId === heatRankingEntry.competitorId
            );
            entries[index].push({
              state: heatRankingEntry.state,
              heat: {
                name: heat.name,
                index,
                number: index + 1,
                ranking: {
                  rank: heatRankingEntry.rank,
                  runTime: heatRankingEntry.runTime,
                  diff: heatLeaderRunTime
                    ? heatRankingEntry.runTime - heatLeaderRunTime
                    : 0,
                  sections: this.event.setup.sections
                    .slice(1)
                    .reduce((re, section) => {
                      const sections = heat.ranking.sectionRanking.filter(
                        el => el.section === section.type
                      );
                      const splitTimeEntries =
                        sections.length > 0
                          ? sections.filter(el => el.type === "SPLIT_TIME")
                          : null;
                      const splitTimeEntry =
                        splitTimeEntries && splitTimeEntries.length > 0
                          ? splitTimeEntries[0].entries.find(
                              el =>
                                el.competitorId ===
                                heatRankingEntry.competitorId
                            )
                          : null;
                      const sectionTimeEntries =
                        sections.length > 0
                          ? sections.filter(el => el.type === "SECTION_TIME")
                          : null;
                      const sectionTimeEntry =
                        sectionTimeEntries && sectionTimeEntries.length > 0
                          ? sectionTimeEntries[0].entries.find(
                              el =>
                                el.competitorId ===
                                heatRankingEntry.competitorId
                            )
                          : null;
                      const speedEntries =
                        sections.length > 0
                          ? sections.filter(el => el.type === "SPEED")
                          : null;
                      const speedEntry =
                        speedEntries && speedEntries.length > 0
                          ? speedEntries[0].entries.find(
                              el =>
                                el.competitorId ===
                                heatRankingEntry.competitorId
                            )
                          : null;

                      re[section.type] = {
                        splitTime: splitTimeEntry
                          ? {
                              rank: splitTimeEntry.rank,
                              time: splitTimeEntry.value
                            }
                          : null,
                        sectionTime: sectionTimeEntry
                          ? {
                              rank: sectionTimeEntry.rank,
                              time: sectionTimeEntry.value
                            }
                          : null,
                        speed: speedEntry
                          ? { rank: speedEntry.rank, speed: speedEntry.value }
                          : null
                      };

                      return re;
                    }, [])
                },
                run: {
                  cancellation: heatCycleEntry
                    ? heatCycleEntry.data.cancellation
                    : null,
                  sections: heatCycleEntry ? heatCycleEntry.data.sections : null
                }
              },
              competitor
            });
          });
          entries[index].sort((a, b) => this.compareRankingEntriesByRank(a, b));
        });

        const sheet = this.columnsToSheet(columns, entries.flat());

        wb.SheetNames.push(this.$i18n.t("export.selection"));
        wb.Sheets[this.$i18n.t("export.selection")] = sheet;

        XLSX.writeFile(
          wb,
          `${this.event.name} - ${this.$i18n.t("export.selection")}.xlsx`
        );
        this.isLoading = false;
        this.buttonIcon = "mdi-check";
        setTimeout(() => {
          this.buttonIcon = "mdi-microsoft-excel";
        }, 2000);
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.buttonIcon = "mdi-close";
        setTimeout(() => {
          this.buttonIcon = "mdi-microsoft-excel";
        }, 2000);
      }
    },
    raceResultsHandler() {
      try {
        this.isLoading = true;
        const wb = XLSX.utils.book_new();
        wb.Props = {
          Title: `${this.event.name} - ${this.$i18n.t("export.raceResults")}`,
          Author: "ALGE-Results"
        };

        const eventRankingColumns = [
          {
            value: "event-rank",
            text: this.$i18n.t("competitors.tables.totalRank")
          },
          ...this.event.competitorData.data
            .filter(field => field.settings.classDivision)
            .map(field => {
              const fieldText = [
                "startNumber",
                "firstName",
                "lastName",
                "gender",
                "dateOfBirth",
                "nation",
                "club",
                "team",
                "class",
                "email"
              ].includes(field.id)
                ? this.$i18n.t(`competitors.tables.${field.id}`)
                : field.view.label;

              return {
                value: `event-class-${field.id}-rank`,
                text: `${fieldText} ${this.$i18n.t("competitors.tables.rank")}`
              };
            })
            .flat(),
          ...this.event.competitorData.data.map(field => ({
            value: `competitor-${field.id}`,
            text: [
              "startNumber",
              "firstName",
              "lastName",
              "gender",
              "dateOfBirth",
              "nation",
              "club",
              "team",
              "class",
              "email"
            ].includes(field.id)
              ? this.$i18n.t(`competitors.tables.${field.id}`)
              : field.view.label
          })),
          {
            value: "event-run-time",
            text: this.$i18n.t("competitors.tables.totalTime")
          },
          {
            value: "event-run-time-diff",
            text: this.$i18n.t("competitors.tables.diff")
          },
          ...this.event.heats
            .map((heat, index) => [
              {
                value: `heats-${index + 1}-run-time`,
                text: heat.name
              },
              {
                value: `heats-${index + 1}-run-time-diff`,
                text: this.$i18n.t("competitors.tables.diff")
              },
              {
                value: `heats-${index + 1}-rank`,
                text: this.$i18n.t("competitors.tables.rank")
              }
            ])
            .flat()
        ];

        const eventRankingEntries = new Array();
        this.event.overallRanking.entries.map(eventRankingEntry => {
          const eventLeaderRunTime =
            this.event.overallRanking.entries.length > 0
              ? this.event.overallRanking.entries[0].runTime
              : 0;
          const competitor = this.getCompetitorById(
            eventRankingEntry.competitorId
          );
          const classRankingEntries = new Object();
          for (const division in this.event.overallRanking
            .classDivisionRanking) {
            const classDivision = this.event.overallRanking.classDivisionRanking[
              division
            ].find(el => el.key === competitor.userData[division]);
            if (classDivision) {
              const classDivisionEntry = classDivision.entries.find(
                el => el.competitorId === competitor.id
              );
              if (classDivisionEntry) {
                classRankingEntries[division] = classDivisionEntry.rank;
              }
            }
          }

          eventRankingEntries.push({
            state: eventRankingEntry.state,
            event: {
              rank: eventRankingEntry.rank,
              classRanks: classRankingEntries,
              runTime: eventRankingEntry.runTime,
              diff: eventLeaderRunTime
                ? eventRankingEntry.runTime - eventLeaderRunTime
                : 0
            },
            heats: this.event.heats.map((heat, index) => {
              const heatLeaderRunTime =
                heat.ranking.entries.length > 0
                  ? heat.ranking.entries[0].runTime
                  : 0;
              const heatCycleEntry = heat.heatCycle.entries.find(
                el => el.competitorId === eventRankingEntry.competitorId
              );
              const heatRankingEntry = heat.ranking.entries.find(
                el => el.competitorId === eventRankingEntry.competitorId
              );

              return {
                name: heat.name,
                index,
                number: index + 1,
                ranking: {
                  rank: heatRankingEntry ? heatRankingEntry.rank : null,
                  runTime: heatRankingEntry ? heatRankingEntry.runTime : null,
                  diff: heatRankingEntry
                    ? heatLeaderRunTime
                      ? heatRankingEntry.runTime - heatLeaderRunTime
                      : 0
                    : null
                },
                run: {
                  cancellation: heatCycleEntry
                    ? heatCycleEntry.data.cancellation
                    : null,
                  sections: heatCycleEntry ? heatCycleEntry.data.sections : null
                }
              };
            }),
            competitor
          });
        });

        const eventRankingSheet = this.columnsToSheet(
          eventRankingColumns,
          eventRankingEntries
        );
        wb.SheetNames.push(this.$i18n.t("events.totalRanking"));
        wb.Sheets[this.$i18n.t("events.totalRanking")] = eventRankingSheet;

        const heatRankingColumns = [
          {
            value: "heat-rank",
            text: this.$i18n.t("competitors.tables.heatRank")
          },
          ...this.event.competitorData.data
            .filter(field => field.settings.classDivision)
            .map(field => {
              const fieldText = [
                "startNumber",
                "firstName",
                "lastName",
                "gender",
                "dateOfBirth",
                "nation",
                "club",
                "team",
                "class",
                "email"
              ].includes(field.id)
                ? this.$i18n.t(`competitors.tables.${field.id}`)
                : field.view.label;

              return {
                value: `heat-class-${field.id}-rank`,
                text: `${fieldText} ${this.$i18n.t("competitors.tables.rank")}`
              };
            })
            .flat(),
          ...this.event.competitorData.data.map(field => ({
            value: `competitor-${field.id}`,
            text: [
              "startNumber",
              "firstName",
              "lastName",
              "gender",
              "dateOfBirth",
              "nation",
              "club",
              "team",
              "class",
              "email"
            ].includes(field.id)
              ? this.$i18n.t(`competitors.tables.${field.id}`)
              : field.view.label
          })),
          {
            value: "heat-run-time",
            text: this.$i18n.t("competitors.tables.runTime")
          },
          {
            value: "heat-run-time-diff",
            text: this.$i18n.t("competitors.tables.diff")
          }
        ];

        const heatNamesCollection = new Map();

        this.event.heats.map((heat, index) => {
          const heatRankingEntries = new Array();
          const heatLeaderRunTime =
            heat.ranking.entries.length > 0
              ? heat.ranking.entries[0].runTime
              : 0;
          heat.ranking.entries.map(heatRankingEntry => {
            const competitor = this.getCompetitorById(
              heatRankingEntry.competitorId
            );
            const heatCycleEntry = heat.heatCycle.entries.find(
              el => el.competitorId === heatRankingEntry.competitorId
            );
            const classRankingEntries = new Object();
            for (const division in heat.ranking.classDivisionRanking) {
              const classDivision = heat.ranking.classDivisionRanking[
                division
              ].find(el => el.key === competitor.userData[division]);
              if (classDivision) {
                const classDivisionEntry = classDivision.entries.find(
                  el => el.competitorId === competitor.id
                );
                if (classDivisionEntry) {
                  classRankingEntries[division] = classDivisionEntry.rank;
                }
              }
            }
            heatRankingEntries.push({
              state: heatRankingEntry ? heatRankingEntry.state : null,
              heat: {
                name: heat.name,
                index,
                number: index + 1,
                ranking: {
                  rank: heatRankingEntry ? heatRankingEntry.rank : null,
                  classRanks: heatRankingEntry ? classRankingEntries : null,
                  runTime: heatRankingEntry ? heatRankingEntry.runTime : null,
                  diff: heatRankingEntry
                    ? heatLeaderRunTime
                      ? heatRankingEntry.runTime - heatLeaderRunTime
                      : 0
                    : null
                },
                run: {
                  cancellation: heatCycleEntry
                    ? heatCycleEntry.data.cancellation
                    : null,
                  sections: heatCycleEntry ? heatCycleEntry.data.sections : null
                }
              },
              competitor
            });
          });
          heatRankingEntries.sort((a, b) =>
            this.compareRankingEntriesByRank(a, b)
          );
          const heatRankingSheet = this.columnsToSheet(
            heatRankingColumns,
            heatRankingEntries
          );

          if (heatNamesCollection.has(heat.name)) {
            const sheetName = `${heat.name} (${heatNamesCollection.get(
              heat.name
            )})`;
            wb.SheetNames.push(sheetName);
            wb.Sheets[sheetName] = heatRankingSheet;
            heatNamesCollection.set(
              heat.name,
              heatNamesCollection.get(heat.name) + 1
            );
          } else {
            heatNamesCollection.set(heat.name, 1);
            wb.SheetNames.push(heat.name);
            wb.Sheets[heat.name] = heatRankingSheet;
          }
        });

        XLSX.writeFile(
          wb,
          `${this.event.name} - ${this.$i18n.t("export.raceResults")}.xlsx`
        );
        this.isLoading = false;
        this.buttonIcon = "mdi-check";
        setTimeout(() => {
          this.buttonIcon = "mdi-microsoft-excel";
        }, 2000);
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.buttonIcon = "mdi-close";
        setTimeout(() => {
          this.buttonIcon = "mdi-microsoft-excel";
        }, 2000);
      }
    },
    trainingHandler(split = false, sortBy = "RANK") {
      try {
        this.menu = [false, false, false, false];

        this.isLoading = true;
        const wb = XLSX.utils.book_new();
        wb.Props = {
          Title: `${this.event.name} - ${this.$i18n.t("export.training")}`,
          Author: "ALGE-Results"
        };

        const intermediateSections = this.event.setup.sections.slice(1, -1);
        const speedSections = this.event.setup.sections.filter(section =>
          section.hasOwnProperty("distance")
        );
        const columns = [
          {
            value: "heat-rank",
            text: this.$i18n.t("competitors.tables.rank")
          },
          {
            value: "competitor-startNumber",
            text: this.$i18n.t("competitors.tables.startNumber")
          },
          {
            value: "competitor-name",
            text: this.$i18n.t("competitors.tables.name")
          },
          {
            value: "heat-number",
            text: this.$i18n.t("competitors.tables.heat")
          },
          {
            value: "heat-start-time",
            text: this.$i18n.t("export.columns.startTime")
          },
          ...intermediateSections
            .map((section, index) => {
              const position = index + 1;
              return [
                {
                  value: `heat-intermediate-${position}-time`,
                  text: `Int${position}`
                },
                {
                  value: `heat-intermediate-${position}-time-diff`,
                  text: this.$i18n.t("competitors.tables.diff")
                },
                {
                  value: `heat-intermediate-${position}-rank`,
                  text: this.$i18n.t("competitors.tables.rank")
                },
                {
                  value: `heat-section-${position}-time`,
                  text:
                    index < intermediateSections.length - 1
                      ? `I${position}-I${position + 1}`
                      : `I${position}-FIN`
                },
                {
                  value: `heat-section-${position}-time-diff`,
                  text: this.$i18n.t("competitors.tables.diff")
                },
                {
                  value: `heat-section-${position}-rank`,
                  text: this.$i18n.t("competitors.tables.rank")
                }
              ];
            })
            .flat(),
          {
            value: "heat-run-time",
            text: this.$i18n.t("competitors.tables.runTime")
          },
          {
            value: "heat-run-time-diff",
            text: this.$i18n.t("competitors.tables.diff")
          },
          ...speedSections
            .map((section, index) => {
              const position = index + 1;
              return [
                {
                  value: `heat-speed-${position}-speed`,
                  text: this.$i18n.t("export.columns.speed", { position })
                },
                {
                  value: `heat-speed-${position}-rank`,
                  text: this.$i18n.t("competitors.tables.rank")
                }
              ];
            })
            .flat()
        ];

        const heatNamesCollection = new Map();

        if (split) {
          this.event.heats.map((heat, index) => {
            const entries = new Array();
            const heatLeaderRunTime =
              heat.ranking.entries.length > 0
                ? heat.ranking.entries[0].runTime
                : 0;
            heat.ranking.entries.map(heatRankingEntry => {
              const competitor = this.getCompetitorById(
                heatRankingEntry.competitorId
              );
              const heatCycleEntry = heat.heatCycle.entries.find(
                el => el.competitorId === heatRankingEntry.competitorId
              );
              if (heatCycleEntry) {
                entries.push({
                  state: heatRankingEntry.state,
                  heat: {
                    name: heat.name,
                    index,
                    number: index + 1,
                    ranking: {
                      rank: heatRankingEntry.rank,
                      runTime: heatRankingEntry.runTime,
                      diff: heatLeaderRunTime
                        ? heatRankingEntry.runTime - heatLeaderRunTime
                        : 0,
                      sections: this.event.setup.sections
                        .slice(1)
                        .reduce((re, section) => {
                          const sections = heat.ranking.sectionRanking.filter(
                            el => el.section === section.type
                          );
                          const splitTimeEntries =
                            sections.length > 0
                              ? sections.filter(el => el.type === "SPLIT_TIME")
                              : null;
                          const splitTimeEntry =
                            splitTimeEntries && splitTimeEntries.length > 0
                              ? splitTimeEntries[0].entries.find(
                                  el =>
                                    el.competitorId ===
                                    heatRankingEntry.competitorId
                                )
                              : null;
                          const sectionTimeEntries =
                            sections.length > 0
                              ? sections.filter(
                                  el => el.type === "SECTION_TIME"
                                )
                              : null;
                          const sectionTimeEntry =
                            sectionTimeEntries && sectionTimeEntries.length > 0
                              ? sectionTimeEntries[0].entries.find(
                                  el =>
                                    el.competitorId ===
                                    heatRankingEntry.competitorId
                                )
                              : null;
                          const speedEntries =
                            sections.length > 0
                              ? sections.filter(el => el.type === "SPEED")
                              : null;
                          const speedEntry =
                            speedEntries && speedEntries.length > 0
                              ? speedEntries[0].entries.find(
                                  el =>
                                    el.competitorId ===
                                    heatRankingEntry.competitorId
                                )
                              : null;

                          re[section.type] = {
                            splitTime: splitTimeEntry
                              ? {
                                  rank: splitTimeEntry.rank,
                                  time: splitTimeEntry.value
                                }
                              : null,
                            sectionTime: sectionTimeEntry
                              ? {
                                  rank: sectionTimeEntry.rank,
                                  time: sectionTimeEntry.value
                                }
                              : null,
                            speed: speedEntry
                              ? {
                                  rank: speedEntry.rank,
                                  speed: speedEntry.value
                                }
                              : null
                          };

                          return re;
                        }, [])
                    },
                    run: {
                      cancellation: heatCycleEntry
                        ? heatCycleEntry.data.cancellation
                        : null,
                      sections: heatCycleEntry
                        ? heatCycleEntry.data.sections
                        : null
                    }
                  },
                  competitor
                });
              }
            });

            if (sortBy === "RANK") {
              entries.sort((a, b) => this.compareRankingEntriesByRank(a, b));
            } else if (sortBy === "START_TIME") {
              entries.sort((a, b) =>
                this.compareRankingEntriesByStartTime(a, b)
              );
            }

            const sheet = this.columnsToSheet(columns, entries);

            if (heatNamesCollection.has(heat.name)) {
              const sheetName = `${heat.name} (${heatNamesCollection.get(
                heat.name
              )})`;
              wb.SheetNames.push(sheetName);
              wb.Sheets[sheetName] = sheet;
              heatNamesCollection.set(
                heat.name,
                heatNamesCollection.get(heat.name) + 1
              );
            } else {
              heatNamesCollection.set(heat.name, 1);
              wb.SheetNames.push(heat.name);
              wb.Sheets[heat.name] = sheet;
            }
          });

          XLSX.writeFile(
            wb,
            `${this.event.name} - ${this.$i18n.t(
              "export.training"
            )} - ${this.$i18n.t("export.heat")}.xlsx`
          );
        } else {
          const entries = new Array();
          this.event.heats.map((heat, index) => {
            const heatLeaderRunTime =
              heat.ranking.entries.length > 0
                ? heat.ranking.entries[0].runTime
                : 0;
            heat.ranking.entries.map(heatRankingEntry => {
              const competitor = this.getCompetitorById(
                heatRankingEntry.competitorId
              );
              const heatCycleEntry = heat.heatCycle.entries.find(
                el => el.competitorId === heatRankingEntry.competitorId
              );
              if (heatCycleEntry) {
                entries.push({
                  state: heatRankingEntry.state,
                  heat: {
                    name: heat.name,
                    index,
                    number: index + 1,
                    ranking: {
                      rank: heatRankingEntry.rank,
                      runTime: heatRankingEntry.runTime,
                      diff: heatLeaderRunTime
                        ? heatRankingEntry.runTime - heatLeaderRunTime
                        : 0,
                      sections: this.event.setup.sections
                        .slice(1)
                        .reduce((re, section) => {
                          const sections = heat.ranking.sectionRanking.filter(
                            el => el.section === section.type
                          );
                          const splitTimeEntries =
                            sections.length > 0
                              ? sections.filter(el => el.type === "SPLIT_TIME")
                              : null;
                          const splitTimeEntry =
                            splitTimeEntries && splitTimeEntries.length > 0
                              ? splitTimeEntries[0].entries.find(
                                  el =>
                                    el.competitorId ===
                                    heatRankingEntry.competitorId
                                )
                              : null;
                          const sectionTimeEntries =
                            sections.length > 0
                              ? sections.filter(
                                  el => el.type === "SECTION_TIME"
                                )
                              : null;
                          const sectionTimeEntry =
                            sectionTimeEntries && sectionTimeEntries.length > 0
                              ? sectionTimeEntries[0].entries.find(
                                  el =>
                                    el.competitorId ===
                                    heatRankingEntry.competitorId
                                )
                              : null;
                          const speedEntries =
                            sections.length > 0
                              ? sections.filter(el => el.type === "SPEED")
                              : null;
                          const speedEntry =
                            speedEntries && speedEntries.length > 0
                              ? speedEntries[0].entries.find(
                                  el =>
                                    el.competitorId ===
                                    heatRankingEntry.competitorId
                                )
                              : null;

                          re[section.type] = {
                            splitTime: splitTimeEntry
                              ? {
                                  rank: splitTimeEntry.rank,
                                  time: splitTimeEntry.value
                                }
                              : null,
                            sectionTime: sectionTimeEntry
                              ? {
                                  rank: sectionTimeEntry.rank,
                                  time: sectionTimeEntry.value
                                }
                              : null,
                            speed: speedEntry
                              ? {
                                  rank: speedEntry.rank,
                                  speed: speedEntry.value
                                }
                              : null
                          };

                          return re;
                        }, [])
                    },
                    run: {
                      cancellation: heatCycleEntry
                        ? heatCycleEntry.data.cancellation
                        : null,
                      sections: heatCycleEntry
                        ? heatCycleEntry.data.sections
                        : null
                    }
                  },
                  competitor
                });
              }
            });
          });

          if (sortBy === "RANK") {
            entries.sort((a, b) => this.compareRankingEntriesByRank(a, b));
          } else if (sortBy === "START_TIME") {
            entries.sort((a, b) => this.compareRankingEntriesByStartTime(a, b));
          }

          const sheet = this.columnsToSheet(columns, entries);

          wb.SheetNames.push(`${this.$i18n.t("export.total")}`);
          wb.Sheets[`${this.$i18n.t("export.total")}`] = sheet;

          XLSX.writeFile(
            wb,
            `${this.event.name} - ${this.$i18n.t(
              "export.training"
            )} - ${this.$i18n.t("export.total")}.xlsx`
          );
        }

        this.isLoading = false;
        this.buttonIcon = "mdi-check";
        setTimeout(() => {
          this.buttonIcon = "mdi-microsoft-excel";
        }, 2000);
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.buttonIcon = "mdi-close";
        setTimeout(() => {
          this.buttonIcon = "mdi-microsoft-excel";
        }, 2000);
      }
    },
    protocolHandler() {
      try {
        this.isLoading = true;
        const wb = XLSX.utils.book_new();
        wb.Props = {
          Title: `${this.event.name} - ${this.$i18n.t("export.protocol")}`,
          Author: "ALGE-Results"
        };

        const columns = [
          {
            value: "heat-rank",
            text: this.$i18n.t("competitors.tables.rank")
          },
          {
            value: "competitor-startNumber",
            text: this.$i18n.t("competitors.tables.startNumber")
          },
          {
            value: "heat-start-time",
            text: this.$i18n.t("export.columns.startTime")
          },
          {
            value: "heat-finish-time",
            text: this.$i18n.t("export.columns.finishTime")
          },
          {
            value: "heat-run-time",
            text: this.$i18n.t("competitors.tables.runTime")
          },
          {
            value: "heat-run-time-diff",
            text: this.$i18n.t("competitors.tables.diff")
          },
          {
            value: "heat-number",
            text: this.$i18n.t("export.columns.heatNumber")
          },
          ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(fieldIndex => {
            if (fieldIndex < this.event.competitorData.data.length) {
              const field = this.event.competitorData.data[fieldIndex];
              return {
                value: `competitor-${field.id}`,
                text: [
                  "firstName",
                  "lastName",
                  "gender",
                  "dateOfBirth",
                  "nation",
                  "club",
                  "team",
                  "class",
                  "email"
                ].includes(field.id)
                  ? this.$i18n.t(`competitors.tables.${field.id}`)
                  : field.view.label
              };
            } else {
              return {
                text: `RES${fieldIndex - 7}`
              };
            }
          }),
          ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            .map(position => [
              {
                value: `heat-intermediate-${position}-time`,
                text: `Int${position}`
              },
              {
                value: `heat-intermediate-${position}-time-diff`,
                text: this.$i18n.t("competitors.tables.diff")
              },
              {
                value: `heat-intermediate-${position}-rank`,
                text: this.$i18n.t("competitors.tables.rank")
              }
            ])
            .flat(),
          ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
            .map(position => [
              {
                value: `heat-section-${position}-time`,
                text:
                  position < 10
                    ? `I${position}-I${position + 1}`
                    : `I${position}-FIN`
              },
              {
                value: `heat-section-${position}-time-diff`,
                text: this.$i18n.t("competitors.tables.diff")
              },
              {
                value: `heat-section-${position}-rank`,
                text: this.$i18n.t("competitors.tables.rank")
              }
            ])
            .flat(),
          ...[1, 2, 3, 4, 5]
            .map(position => [
              {
                value: `heat-speed-${position}-speed`,
                text: this.$i18n.t("export.columns.speed", { position })
              },
              {
                value: `heat-speed-${position}-rank`,
                text: this.$i18n.t("competitors.tables.rank")
              }
            ])
            .flat(),
          {
            value: `heat-timePostings-times`,
            text: this.$i18n.t("export.columns.timePostings")
          },
          {
            value: `heat-timePostings-messages`,
            text: this.$i18n.t("export.columns.messages")
          }
        ];

        const entries = new Array();
        this.event.heats.map((heat, index) => {
          entries[index] = new Array();
          const heatLeaderRunTime =
            heat.ranking.entries.length > 0
              ? heat.ranking.entries[0].runTime
              : 0;
          heat.ranking.entries.map(heatRankingEntry => {
            const competitor = this.getCompetitorById(
              heatRankingEntry.competitorId
            );
            const heatCycleEntry = heat.heatCycle.entries.find(
              el => el.competitorId === heatRankingEntry.competitorId
            );
            entries[index].push({
              state: heatRankingEntry.state,
              heat: {
                name: heat.name,
                index,
                number: index + 1,
                ranking: {
                  rank: heatRankingEntry.rank,
                  runTime: heatRankingEntry.runTime,
                  diff: heatLeaderRunTime
                    ? heatRankingEntry.runTime - heatLeaderRunTime
                    : 0,
                  sections: this.event.setup.sections
                    .slice(1)
                    .reduce((re, section) => {
                      const sections = heat.ranking.sectionRanking.filter(
                        el => el.section === section.type
                      );
                      const splitTimeEntries =
                        sections.length > 0
                          ? sections.filter(el => el.type === "SPLIT_TIME")
                          : null;
                      const splitTimeEntry =
                        splitTimeEntries && splitTimeEntries.length > 0
                          ? splitTimeEntries[0].entries.find(
                              el =>
                                el.competitorId ===
                                heatRankingEntry.competitorId
                            )
                          : null;
                      const sectionTimeEntries =
                        sections.length > 0
                          ? sections.filter(el => el.type === "SECTION_TIME")
                          : null;
                      const sectionTimeEntry =
                        sectionTimeEntries && sectionTimeEntries.length > 0
                          ? sectionTimeEntries[0].entries.find(
                              el =>
                                el.competitorId ===
                                heatRankingEntry.competitorId
                            )
                          : null;
                      const speedEntries =
                        sections.length > 0
                          ? sections.filter(el => el.type === "SPEED")
                          : null;
                      const speedEntry =
                        speedEntries && speedEntries.length > 0
                          ? speedEntries[0].entries.find(
                              el =>
                                el.competitorId ===
                                heatRankingEntry.competitorId
                            )
                          : null;

                      re[section.type] = {
                        splitTime: splitTimeEntry
                          ? {
                              rank: splitTimeEntry.rank,
                              time: splitTimeEntry.value
                            }
                          : null,
                        sectionTime: sectionTimeEntry
                          ? {
                              rank: sectionTimeEntry.rank,
                              time: sectionTimeEntry.value
                            }
                          : null,
                        speed: speedEntry
                          ? { rank: speedEntry.rank, speed: speedEntry.value }
                          : null
                      };

                      return re;
                    }, [])
                },
                run: {
                  cancellation: heatCycleEntry
                    ? heatCycleEntry.data.cancellation
                    : null,
                  sections: heatCycleEntry ? heatCycleEntry.data.sections : null
                },
                timePostings: heatCycleEntry
                  ? heatCycleEntry.data.timePostings
                  : null
              },
              competitor
            });
          });
          entries[index].sort((a, b) => this.compareRankingEntriesByRank(a, b));
        });

        const sheet = this.columnsToSheet(columns, entries.flat());

        wb.SheetNames.push(this.$i18n.t("export.protocol"));
        wb.Sheets[this.$i18n.t("export.protocol")] = sheet;

        XLSX.writeFile(
          wb,
          `${this.event.name} - ${this.$i18n.t("export.protocol")}.xlsx`
        );
        this.isLoading = false;
        this.buttonIcon = "mdi-check";
        setTimeout(() => {
          this.buttonIcon = "mdi-microsoft-excel";
        }, 2000);
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.buttonIcon = "mdi-close";
        setTimeout(() => {
          this.buttonIcon = "mdi-microsoft-excel";
        }, 2000);
      }
    },
    columnsToSheet(columns, entries) {
      const intermediateSections = this.event.setup.sections.slice(1, -1);
      const speedSections = this.event.setup.sections.filter(section =>
        section.hasOwnProperty("distance")
      );
      const protocol = new Array(
        new Array(...columns.map(column => column.text))
      );
      entries.map(entry => {
        const row = new Array();
        columns.map((column, index) => {
          const value = column.value;
          switch (true) {
            case /^event-rank$/.test(value):
              if (entry.state === "FINISHED") {
                row.push(entry.event.rank);
              } else {
                row.push("-");
              }
              break;
            case /^event-class-[a-zA-Z0-9]+-rank$/.test(value):
              if (entry.state === "FINISHED" && entry.event.classRanks) {
                const fieldId = value.split("-")[2];
                row.push(entry.event.classRanks[fieldId]);
              } else {
                row.push("-");
              }
              break;
            case /^event-run-time$/.test(value):
              if (entry.state === "FINISHED") {
                row.push(
                  this.transformToExcelNumber(
                    this.formatRunTime(entry.event.runTime, 3)
                  )
                );
              } else {
                row.push("-");
              }
              break;
            case /^event-run-time-diff$/.test(value):
              if (entry.state === "FINISHED") {
                row.push(
                  this.transformToExcelNumber(
                    this.formatRunTime(entry.event.diff, 3)
                  )
                );
              } else {
                row.push("-");
              }
              break;
            case /^heat(s-\d+)?-name$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                row.push(heat.name);
              }
              break;
            }
            case /^heat(s-\d+)?-number$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                row.push(heat.number);
              }
              break;
            }
            case /^heat(s-\d+)?-rank$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                if (entry.state === "FINISHED" && heat.ranking.rank) {
                  row.push(heat.ranking.rank);
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-class-[a-zA-Z0-9]+-rank$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                if (entry.state === "FINISHED" && heat.ranking.classRanks) {
                  const fieldId = value.split("-")[2];
                  row.push(heat.ranking.classRanks[fieldId]);
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-run-time$/.test(value):
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                if (
                  (entry.state === "FINISHED" ||
                    entry.state === "INCOMPLETE") &&
                  heat.ranking.runTime
                ) {
                  row.push(
                    this.transformToExcelNumber(
                      this.formatRunTime(heat.ranking.runTime, 3)
                    )
                  );
                } else if (
                  (entry.state === "CANCELLED" ||
                    entry.state === "INCOMPLETE") &&
                  heat.run.cancellation &&
                  heat.run.cancellation !== "UNDEFINED"
                ) {
                  row.push(heat.run.cancellation);
                } else {
                  row.push("-");
                }
              }
              break;
            case /^heat(s-\d+)?-run-time-diff$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                if (entry.state === "FINISHED" && heat.ranking.diff !== null) {
                  row.push(
                    this.transformToExcelNumber(
                      this.formatRunTime(heat.ranking.diff, 3)
                    )
                  );
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-start-time$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                if (heat.run.sections["START"]) {
                  row.push(
                    this.timestampToDate(
                      heat.run.sections["START"].timestamp_100ns,
                      heat.run.sections["START"].timeOffset_m
                    )
                  );
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-finish-time$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                if (heat.run.sections["FINISH"]) {
                  row.push(
                    this.timestampToDate(
                      heat.run.sections["FINISH"].timestamp_100ns,
                      heat.run.sections["FINISH"].timeOffset_m
                    )
                  );
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-intermediate-\d+-time$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                const index = Number(value.split("-")[2]) - 1;
                if (index < intermediateSections.length) {
                  const section = intermediateSections[index].type;
                  if (
                    heat.run.sections[section] &&
                    heat.ranking.sections[section] &&
                    heat.ranking.sections[section].sectionTime
                  ) {
                    row.push(
                      this.transformToExcelNumber(
                        this.formatRunTime(
                          heat.ranking.sections[section].sectionTime.time,
                          3
                        )
                      )
                    );
                  } else {
                    row.push("-");
                  }
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-intermediate-\d+-time-diff$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                const index = Number(value.split("-")[2]) - 1;
                if (index < intermediateSections.length) {
                  const section = intermediateSections[index].type;
                  const intermediateLeaderTime = this.getIntermediateLeaderTime(
                    this.event.heats[entry.heat.index],
                    section
                  );
                  if (
                    intermediateLeaderTime &&
                    heat.run.sections[section] &&
                    heat.ranking.sections[section] &&
                    heat.ranking.sections[section].sectionTime
                  ) {
                    row.push(
                      this.transformToExcelNumber(
                        this.formatRunTime(
                          heat.ranking.sections[section].sectionTime.time -
                            intermediateLeaderTime,
                          3
                        )
                      )
                    );
                  } else {
                    row.push("-");
                  }
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-intermediate-\d+-rank$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                const index = Number(value.split("-")[2]) - 1;
                if (index < intermediateSections.length) {
                  const section = intermediateSections[index].type;
                  if (
                    heat.run.sections[section] &&
                    heat.ranking.sections[section] &&
                    heat.ranking.sections[section].sectionTime
                  ) {
                    row.push(heat.ranking.sections[section].sectionTime.rank);
                  } else {
                    row.push("-");
                  }
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-section-\d+-time$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                const position = Number(value.split("-")[2]);
                if (position < this.event.setup.sections.length - 1) {
                  const section = this.event.setup.sections[position + 1].type;
                  if (
                    heat.run.sections[section] &&
                    heat.ranking.sections[section] &&
                    heat.ranking.sections[section].splitTime
                  ) {
                    row.push(
                      this.transformToExcelNumber(
                        this.formatRunTime(
                          heat.ranking.sections[section].splitTime.time,
                          3
                        )
                      )
                    );
                  } else {
                    row.push("-");
                  }
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-section-\d+-time-diff$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                const position = Number(value.split("-")[2]);
                if (position < this.event.setup.sections.length - 1) {
                  const section = this.event.setup.sections[position + 1].type;
                  const sectionLeaderTime = this.getSectionLeaderTime(
                    this.event.heats[entry.heat.index],
                    section
                  );
                  if (
                    sectionLeaderTime &&
                    heat.run.sections[section] &&
                    heat.ranking.sections[section] &&
                    heat.ranking.sections[section].splitTime
                  ) {
                    row.push(
                      this.transformToExcelNumber(
                        this.formatRunTime(
                          heat.ranking.sections[section].splitTime.time -
                            sectionLeaderTime,
                          3
                        )
                      )
                    );
                  } else {
                    row.push("-");
                  }
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-section-\d+-rank$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                const position = Number(value.split("-")[2]);
                if (position < this.event.setup.sections.length - 1) {
                  const section = this.event.setup.sections[position + 1].type;
                  if (
                    heat.run.sections[section] &&
                    heat.ranking.sections[section] &&
                    heat.ranking.sections[section].splitTime
                  ) {
                    row.push(heat.ranking.sections[section].splitTime.rank);
                  } else {
                    row.push("-");
                  }
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-speed-\d+-speed$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                const index = Number(value.split("-")[2]) - 1;
                const timePosting = heat;
                if (index < speedSections.length) {
                  const section = speedSections[index].type;
                  if (
                    heat.ranking.sections[section] &&
                    heat.ranking.sections[section].speed
                  ) {
                    row.push(
                      this.convertToSpeedUnit(
                        heat.ranking.sections[section].speed.speed
                      )
                    );
                  } else {
                    row.push("-");
                  }
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-speed-\d+-rank$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                const index = Number(value.split("-")[2]) - 1;
                if (index < speedSections.length) {
                  const section = speedSections[index].type;
                  if (
                    heat.ranking.sections[section] &&
                    heat.ranking.sections[section].speed
                  ) {
                    row.push(heat.ranking.sections[section].speed.rank);
                  } else {
                    row.push("-");
                  }
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-timePostings-times$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                if (heat.timePostings) {
                  const content = heat.timePostings.reduce((re, el, index) => {
                    re += `${this.formatRunTime(el.time_100ns, 3)}`;
                    if (index < heat.timePostings.length - 1) {
                      re += "\n";
                    }
                    return re;
                  }, "");
                  row.push(content);
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^heat(s-\d+)?-timePostings-messages$/.test(value): {
              const heat = this.getHeatByValue(value, entry);
              if (heat) {
                if (heat.timePostings && heat.timePostings.length > 0) {
                  const content = heat.timePostings.reduce((re, el, index) => {
                    re += `${el.message ? el.message : "-"}`;
                    if (index < heat.timePostings.length - 1) {
                      re += "\n";
                    }
                    return re;
                  }, "");
                  row.push(content);
                } else {
                  row.push("-");
                }
              }
              break;
            }
            case /^competitor-startNumber$/.test(value):
              row.push(entry.competitor.startNumber);
              break;
            case /^competitor-dateOfBirth$/.test(value):
              if (entry.competitor.userData.dateOfBirth) {
                if (isNaN(new Date(entry.competitor.userData.dateOfBirth))) {
                  row.push(entry.competitor.userData.dateOfBirth);
                } else {
                  row.push(
                    new Date(
                      `${entry.competitor.userData.dateOfBirth}T00:00:00`
                    )
                  );
                }
              } else {
                row.push("-");
              }
              break;
            case /^competitor-/.test(value):
              const field = value.slice(11);
              if (entry.competitor.userData[field]) {
                row.push(entry.competitor.userData[field]);
              } else {
                row.push("-");
              }
              break;
            default:
              row.push("");
              break;
          }
        });
        protocol.push(row);
      });
      const sheet = XLSX.utils.aoa_to_sheet(protocol);
      columns.map((column, index) => {
        const value = column.value;
        switch (true) {
          case /^event-run-time$/.test(value):
          case /^event-run-time-diff$/.test(value):
          case /^heat(s-\d+)?-run-time$/.test(value):
          case /^heat(s-\d+)?-run-time-diff$/.test(value):
          case /^heat(s-\d+)?-intermediate-\d+-time$/.test(value):
          case /^heat(s-\d+)?-intermediate-\d+-time-diff$/.test(value):
          case /^heat(s-\d+)?-section-\d+-time$/.test(value):
          case /^heat(s-\d+)?-section-\d+-time-diff$/.test(value):
          case /^heat(s-\d+)?-timePosting-\d+-time$/.test(value):
            this.setColsCustomFormat(sheet, index);
            break;
          case /^heat(s-\d+)?-start-time$/.test(value):
          case /^heat(s-\d+)?-finish-time$/.test(value):
            this.setColsTimeFormat(sheet, index);
            break;
        }
      });
      return sheet;
    },
    setColsCustomFormat(worksheet, col) {
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        const ref = XLSX.utils.encode_cell({ r: row, c: col });
        if (worksheet[ref]) {
          if (worksheet[ref].v >= 1 / 24) {
            worksheet[ref].z = `[h]:mm:ss${this.decimals}`;
          } else if (worksheet[ref].v >= 1 / 1440) {
            worksheet[ref].z = `[m]:ss${this.decimals}`;
          } else if (worksheet[ref].v >= 0) {
            worksheet[ref].z = `[s]${this.decimals}`;
          }
        }
      }
    },
    setColsTimeFormat(worksheet, col) {
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        const ref = XLSX.utils.encode_cell({ r: row, c: col });
        if (worksheet[ref]) {
          worksheet[ref].z = `HH:mm:ss${this.decimals}`;
        }
      }
    },
    transformToExcelNumber(time) {
      if (time === 0) {
        return 0;
      }

      const times = String(time).split(/[:.]+/);
      const h = Number(times[0]);
      const m = Number(times[1]);
      const s = Number(times[2]);
      const ms = Number(times[3]);

      return h / 24 + m / 1440 + s / 86400 + ms / 86400000;
    },
    getHeatByValue(value, entry) {
      const values = value.split("-");
      if (values[0] === "heat") {
        return entry.heat;
      } else if (values[0] === "heats") {
        const index = Number(values[1]) - 1;
        return entry.heats[index];
      }

      return null;
    },
    getIntermediateLeaderTime(heat, section) {
      const splitTimeEntries = heat.ranking.sectionRanking.filter(
        el => el.section === section && el.type === "SECTION_TIME"
      );
      if (
        splitTimeEntries.length > 0 &&
        splitTimeEntries[0].entries.length > 0
      ) {
        return splitTimeEntries[0].entries[0].value;
      }

      return null;
    },
    getSectionLeaderTime(heat, section) {
      const sectionTimeEntries = heat.ranking.sectionRanking.filter(
        el => el.section === section && el.type === "SPLIT_TIME"
      );
      if (
        sectionTimeEntries.length > 0 &&
        sectionTimeEntries[0].entries.length > 0
      ) {
        return sectionTimeEntries[0].entries[0].value;
      }

      return null;
    },
    iconColor(icon, defaultColor = "primary") {
      switch (String(icon)) {
        case "mdi-check":
          return "success";
        case "mdi-close":
          return "error";
        default:
          return defaultColor;
      }
    },
    compareRankingEntriesByRank(a, b) {
      if (a.state === "CANCELLED" && b.state === "CANCELLED") {
        const aCancellation = a.heat.run.cancellation;
        const bCancellation = b.heat.run.cancellation;
        if (aCancellation === bCancellation) {
          return a.competitor.startNumber - b.competitor.startNumber;
        } else {
          switch (aCancellation) {
            case "DNS":
              return -1;
            case "DNF":
              if (bCancellation === "DNS") {
                return 1;
              } else {
                return -1;
              }
            case "DSQ":
              return 1;
          }
        }
        return 0;
      } else if (a.state === "CANCELLED") {
        return 1;
      } else if (b.state === "CANCELLED") {
        return -1;
      } else {
        return a.heat.ranking.rank - b.heat.ranking.rank;
      }
    },
    compareRankingEntriesByStartTime(a, b) {
      if (a.heat.run.sections["START"] && b.heat.run.sections["START"]) {
        return (
          a.heat.run.sections["START"].timestamp_100ns -
          b.heat.run.sections["START"].timestamp_100ns
        );
      } else if (a.heat.run.sections["START"]) {
        return -1;
      } else if (b.heat.run.sections["START"]) {
        return 1;
      } else {
        const aCancellation = a.heat.run.cancellation;
        const bCancellation = b.heat.run.cancellation;
        if (aCancellation === bCancellation) {
          return a.competitor.startNumber - b.competitor.startNumber;
        } else {
          switch (aCancellation) {
            case "DNS":
              return -1;
            case "DNF":
              if (bCancellation === "DNS") {
                return 1;
              } else {
                return -1;
              }
            case "DSQ":
              return 1;
          }
        }
        return 0;
      }
    }
  }
};
</script>
<style>
.downloadItem:hover .v-list-item__title,
.downloadItem:hover .v-list-item__icon .v-icon {
  color: #1976d2;
}
</style>
