<template>
  <div>
    <v-container style="max-height: 70vh; overflow-y: auto;">
      <v-row>
        <v-col cols="12" lg="6">
          <v-simple-table>
            <thead>
              <tr>
                <th style="max-width: 200px; font-weight: normal">
                  <v-select
                    v-model="selectedClassField"
                    :items="classFields"
                    item-value="id"
                    :item-text="
                      item =>
                        whitelist.includes(item.id)
                          ? $t(`competitors.tables.${item.id}`)
                          : item.view.label
                    "
                    hide-details
                    dense
                  ></v-select>
                </th>
                <th>{{ $t("startTimeAssignment.startTime") }}</th>
                <th>{{ $t("startTimeAssignment.interval") }}</th>
                <th>{{ $t("startTimeAssignment.assign") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in form" :key="row.id">
                <td class="font-weight-medium">{{ row.id }}</td>
                <td>
                  <v-text-field
                    v-model="$v.form.$each.$iter[index].startTime.$model"
                    placeholder="00:00:00.00000"
                    :error-messages="startTimeError(index)"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="$v.form.$each.$iter[index].interval.$model"
                    placeholder="00:00:00.00000"
                    :error-messages="intervalError(index)"
                  ></v-text-field>
                </td>
                <td>
                  <v-simple-checkbox v-model="row.assign"></v-simple-checkbox>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <div class="d-flex">
            <v-btn
              v-if="form && form.length > 0"
              type="button"
              color="primary"
              class="mt-6 ml-auto"
              :disabled="loading"
              @click="generatePreview"
            >
              {{ $t("startTimeAssignment.actions.preview") }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" lg="6">
          <v-simple-table dense>
            <tbody>
              <template v-for="(competitors, group) in groupedPreview">
                <tr :key="group">
                  <td colspan="3">
                    <span class="subtitle-1 font-weight-medium">{{
                      group
                    }}</span>
                  </td>
                </tr>
                <template v-for="competitor in competitors">
                  <tr :key="competitor.id">
                    <td>{{ competitor.bib }}</td>
                    <td>{{ competitor.name }}</td>
                    <td>
                      {{
                        formatTimestamp_100ns(
                          competitor.startTime.timestamp,
                          competitor.startTime.offset,
                          false
                        )
                      }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" class="d-flex justify-end" style="column-gap: 12px;">
          <v-btn type="button" text :disabled="loading" @click="$emit('close')">
            {{ $t("startTimeAssignment.cancel") }}
          </v-btn>
          <v-btn
            type="button"
            color="success"
            :disabled="!generated"
            :loading="loading"
            @click="submitHandler"
          >
            {{ $t("startTimeAssignment.apply") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import whitelist from "@/mixins/whitelist";
import formatTimestamp from "@/mixins/formatTimestamp";
import { required } from "vuelidate/lib/validators";
import dateTime from "@/utils/validators/dateTime";
import runTime from "@/utils/validators/runTime";

export default {
  name: "StartTimeAssignmentByClass",
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      default: null
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selectedClassField: null,
      form: null,
      preview: null,
      generated: false,
      loading: false,
      precisions: ["1s", "1/10s", "1/100s", "1/1000s", "1/10000s", "1/100000s"]
    };
  },
  validations() {
    return {
      form: {
        $each: {
          startTime: {
            required,
            format: dateTime
          },
          interval: {
            required,
            format: runTime
          }
        }
      }
    };
  },
  mixins: [whitelist, formatTimestamp],
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      getCompetitorById: "events/getCompetitorById"
    }),
    classFields() {
      return this.event.competitorData.data.filter(
        field => field.settings.classDivision
      );
    },
    classEntries() {
      if (this.selectedClassField && this.classFields) {
        return this.event.overallRanking.classDivisionRanking[
          this.selectedClassField
        ].map(el => el.key);
      }
      return null;
    },
    groupedPreview() {
      if (this.preview) {
        const groups = this.preview.reduce((re, el) => {
          if (!re[el.class]) {
            re[el.class] = new Array();
          }
          re[el.class].push(el);
          return re;
        }, {});
        return Object.keys(groups)
          .sort()
          .reduce((re, el) => {
            re[el] = groups[el];
            return re;
          }, {});
      }
      return null;
    },
    precision() {
      const precision = this.precisions.findIndex(
        el => el === this.event.setup.precision
      );
      return precision !== -1 ? precision : 2;
    }
  },
  watch: {
    show(value) {
      if (value) {
        this.initForm();
        this.initPreview();
      }
    },
    selectedClassField() {
      this.initForm();
      this.initPreview();
    }
  },
  methods: {
    ...mapActions({
      setHeatRunTimes: "events/setHeatRunTimes"
    }),
    initClass() {
      if (this.classFields) {
        this.selectedClassField = this.classFields[0].id;
      }
    },
    initForm() {
      if (this.classEntries) {
        this.form = this.classEntries
          .reduce((re, el) => {
            const row = {
              id: el,
              startTime: "00:00:00.00000",
              interval: "00:00:00.00000",
              assign: true
            };
            re.push(row);
            return re;
          }, [])
          .sort((a, b) => String(a.id).localeCompare(String(b.id)));
      }
    },
    initPreview() {
      this.preview = this.heat.competitors
        .reduce((re, competitorId) => {
          const competitor = this.getCompetitorById(competitorId);
          const heatCycleEntry = this.heat.heatCycle.entries.find(
            entry => entry.competitorId === competitorId
          );
          const row = {
            class: competitor.userData[this.selectedClassField],
            id: competitor.id,
            bib: competitor.startNumber,
            name: competitor.userData.name,
            startTime: {
              timestamp:
                heatCycleEntry && heatCycleEntry.data.sections["START"]
                  ? heatCycleEntry.data.sections["START"].timestamp_100ns
                  : null,
              offset:
                heatCycleEntry && heatCycleEntry.data.sections["START"]
                  ? heatCycleEntry.data.sections["START"].timeOffset_m
                  : null
            }
          };
          re.push(row);
          return re;
        }, [])
        .filter(el => el.class);
    },
    generatePreview() {
      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        this.generated = true;

        const currentStartTimes = this.form.reduce((re, el) => {
          const startTimeTimestamp = this.formatTimeToTimestamp(el.startTime);
          const intervalTimestamp = this.formatRunTimeToTimestamp(el.interval);
          if (startTimeTimestamp) {
            re[el.id] = {
              assign: el.assign,
              hasCustomValue: el.startTime !== "00:00:00.00000",
              startTime: startTimeTimestamp,
              interval: intervalTimestamp ? intervalTimestamp : 0
            };
          }
          return re;
        }, {});

        let lastClassEntry = null;
        this.preview = this.heat.competitors
          .filter(el => {
            const competitor = this.getCompetitorById(el);
            return competitor.userData[this.selectedClassField];
          })
          .sort((a, b) => {
            const competitorA = this.getCompetitorById(a);
            const competitorB = this.getCompetitorById(b);
            return competitorA.userData[this.selectedClassField].localeCompare(
              competitorB.userData[this.selectedClassField]
            );
          })
          .reduce((re, el) => {
            const competitor = this.getCompetitorById(el);
            const classEntry = competitor.userData[this.selectedClassField];
            const heatCycleEntry = this.heat.heatCycle.entries.find(
              entry => entry.competitorId === el
            );

            let timestamp = 0;
            let offset = 0;
            let changed = false;

            if (currentStartTimes[classEntry].assign) {
              if (currentStartTimes[classEntry].hasCustomValue) {
                timestamp = currentStartTimes[classEntry].startTime;
                currentStartTimes[classEntry].startTime +=
                  currentStartTimes[classEntry].interval;
                lastClassEntry = classEntry;
              } else if (lastClassEntry && currentStartTimes[lastClassEntry]) {
                timestamp = currentStartTimes[lastClassEntry].startTime;
                currentStartTimes[lastClassEntry].startTime +=
                  currentStartTimes[lastClassEntry].interval;
              }
              changed = true;
            } else {
              if (heatCycleEntry && heatCycleEntry.data.sections["START"]) {
                timestamp =
                  heatCycleEntry.data.sections["START"].timestamp_100ns;
                offset = heatCycleEntry.data.sections["START"].timeOffset_m;
              }
            }

            re.push({
              class: competitor.userData[this.selectedClassField],
              id: competitor.id,
              bib: competitor.startNumber,
              name: competitor.userData.name,
              startTime: {
                timestamp,
                offset
              },
              changed
            });

            return re;
          }, []);
      }
    },
    formatRunTimeToTimestamp(value) {
      if (!value) return null;

      const valueSplit = value.split(new RegExp("[.,]"));

      const left = valueSplit[0];
      const leftSplit = left.split(":").reverse();
      const seconds =
        leftSplit[0] === undefined ? 0 : Number(leftSplit[0]) * 10000000;
      const minutes =
        leftSplit[1] === undefined ? 0 : Number(leftSplit[1]) * 600000000;
      const hours =
        leftSplit[2] === undefined ? 0 : Number(leftSplit[2]) * 36000000000;

      const right = valueSplit[1] || "";
      const remainder = Number(right.padEnd(5, "0")) * 100;

      return hours + minutes + seconds + remainder;
    },
    formatTimeToTimestamp(value) {
      if (!value) return null;

      const heatDate = new Date(this.heat.startTime);

      switch (true) {
        case /^((2[0-3])|([0-1][0-9])|([0-9])):?$/.test(value): {
          // 1 > 1:00:00.00000
          // 12 > 12:00:00.00000
          // 1: > 1:00:00.00000
          // 12: > 12:00:00.00000
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              parseInt(value.slice(0, 2)),
              0,
              0
            ) * 10000
          );
        }
        case /^((2[0-3])|([0-1][0-9])|([0-9])):([0-5]|([0-5][0-9]:?))$/.test(
          value
        ): {
          // 1:00 > 1:00:00.00000
          // 12:00 > 12:00:00.00000
          // 1:00: > 1:00:00.00000
          // 12:00: > 12:00:00.00000
          const valueSplit = value.split(":");
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              parseInt(valueSplit[0]),
              parseInt(valueSplit[1].slice(0, 2).padEnd(2, "0")),
              0
            ) * 10000
          );
        }
        case /^((2[0-3])|([0-1][0-9])|([0-9])):[0-5][0-9]:([0-5]|([0-5][0-9]:?))$/.test(
          value
        ): {
          // 1:00:00 > 1:00:00.00000
          // 12:00:00 > 12:00:00.00000
          const valueSplit = value.split(":");
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              parseInt(valueSplit[0]),
              parseInt(valueSplit[1]),
              parseInt(valueSplit[2].padEnd(2, "0"))
            ) * 10000
          );
        }
        case /^((2[0-3])|([0-1][0-9])|([0-9])):[0-5][0-9]:[0-5][0-9][.,][0-9]{0,5}$/.test(
          value
        ): {
          // 1:00:00. > 1:00:00.00000
          // 12:00:00. > 12:00:00.00000
          // 1:00:00.1 > 1:00:00.10000
          // 12:00:00.1 > 12:00:00.10000
          const valueSplit = value.split(new RegExp("[:.,]"));
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              parseInt(valueSplit[0]),
              parseInt(valueSplit[1]),
              parseInt(valueSplit[2])
            ) *
              10000 +
            parseInt(valueSplit[3].padEnd(5, "0")) * 100
          );
        }
        case /^((:?[0-5][0-9])|[0-9]):[0-5][0-9][.,][0-9]{0,5}$/.test(value): {
          // 1:00. > 00:01:00.00000
          // 12:00. > 00:12:00.00000
          // :12:00. > 00:12:00.00000
          const valueSplit = value.split(new RegExp("[:.,]")).reverse();
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              0,
              parseInt(valueSplit[2]),
              parseInt(valueSplit[1])
            ) *
              10000 +
            parseInt(valueSplit[0].padEnd(5, "0")) * 100
          );
        }
        case /^((:?[0-5][0-9])|[0-9])[.,][0-9]{0,5}$/.test(value): {
          // 1. > 00:00:01.00000
          // 12. > 00:00:12.00000
          // :12. > 00:00:12.00000
          const valueSplit = value.split(new RegExp("[:.,]")).reverse();
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              0,
              0,
              parseInt(valueSplit[1])
            ) *
              10000 +
            parseInt(valueSplit[0].padEnd(5, "0")) * 100
          );
        }
        case /^[.,][0-9]{0,5}$/.test(value): {
          // . > 00:00:00.00000
          const valueSplit = value.split(new RegExp("[.,]")).reverse();
          return (
            Date.UTC(
              heatDate.getUTCFullYear(),
              heatDate.getUTCMonth(),
              heatDate.getUTCDate(),
              0,
              0,
              0
            ) *
              10000 +
            parseInt(valueSplit[0].padEnd(5, "0")) * 100
          );
        }
        default:
          return null;
      }
    },
    startTimeError(index) {
      const errors = [];
      if (!this.$v.form.$each.$iter[index].startTime.$dirty) return errors;
      !this.$v.form.$each.$iter[index].startTime.required &&
        errors.push(
          this.$i18n.t("startTimeAssignment.form.errors.startTime.required")
        );
      !this.$v.form.$each.$iter[index].startTime.format &&
        errors.push(
          this.$i18n.t("startTimeAssignment.form.errors.startTime.format")
        );

      if (errors.length > 0) {
        return errors[0];
      }

      return null;
    },
    intervalError(index) {
      const errors = [];
      if (!this.$v.form.$each.$iter[index].interval.$dirty) return errors;
      !this.$v.form.$each.$iter[index].interval.required &&
        errors.push(
          this.$i18n.t("startTimeAssignment.form.errors.interval.required")
        );
      !this.$v.form.$each.$iter[index].interval.format &&
        errors.push(
          this.$i18n.t("startTimeAssignment.form.errors.interval.format")
        );

      if (errors.length > 0) {
        return errors[0];
      }

      return null;
    },
    submitHandler() {
      const confirmation = confirm(
        this.$i18n.t("startTimeAssignment.confirmation")
      );

      if (confirmation) {
        this.loading = true;

        const items = this.preview
          .filter(el => el.startTime.timestamp && el.changed)
          .reduce((re, el) => {
            re.push({
              competitorId: el.id,
              dtos: [
                {
                  section: "START",
                  timestamp_100ns: el.startTime.timestamp
                }
              ]
            });
            return re;
          }, []);

        const payload = {
          userId: this.user.id,
          eventId: this.event.id,
          heatId: this.heat.id,
          data: items
        };

        this.setHeatRunTimes(payload)
          .then(() => {
            this.loading = false;
            this.$emit("close");
          })
          .catch(() => {
            this.loading = false;
            this.error = "startTimeAssignment.errors.upload";
          });
      }
    }
  },
  mounted() {
    this.initClass();
    this.initForm();
    this.initPreview();
  }
};
</script>
