<template>
  <div>
    <span
      v-if="
        competitorRunTime !== null &&
          leaderRunTime !== null &&
          competitorRunTime !== leaderRunTime
      "
      :class="isCompetitorLeading ? 'success--text' : 'error--text'"
    >
      {{ prefixTime(cropTime(formatRunTime(diffTime, decimals, false))) }}
    </span>
    <span v-else>
      -
    </span>
  </div>
</template>

<script>
import formatRunTime from "@/mixins/formatRunTime";
import cropTime from "@/mixins/cropTime";

export default {
  name: "TimerDiff",
  props: {
    section: {
      type: String,
      default: null
    },
    serverTimestamp: {
      type: Number,
      default: null
    },
    competitor: {
      type: Object,
      default: null
    },
    leader: {
      type: Object,
      default: null
    },
    decimals: {
      type: Number,
      default: 2
    },
    base: {
      type: Number,
      default: 0
    },
    leaderBase: {
      type: Number,
      default: 0
    }
  },
  computed: {
    competitorSections() {
      if (
        this.competitor &&
        this.competitor.data &&
        this.competitor.data.sections
      ) {
        return this.competitor.data.sections;
      }

      return null;
    },
    leaderSections() {
      if (this.leader && this.leader.data && this.leader.data.sections) {
        return this.leader.data.sections;
      }

      return null;
    },
    competitorRunTime() {
      if (this.competitorSections) {
        if (this.section) {
          if (this.competitorSections[this.section]) {
            return this.competitorSections[this.section].sectionTime;
          }
        } else {
          return this.competitorSections["START"].sectionTime;
        }
      }

      return null;
    },
    leaderRunTime() {
      if (this.leaderSections) {
        if (this.section) {
          if (this.leaderSections[this.section]) {
            return this.leaderSections[this.section].sectionTime;
          }
        } else {
          return this.leaderSections["START"].sectionTime;
        }
      }

      return null;
    },
    diffTime() {
      if (this.competitorRunTime !== null && this.leaderRunTime !== null) {
        return (
          this.competitorRunTime +
          this.base -
          (this.leaderRunTime + this.leaderBase)
        );
      }

      return null;
    },
    isCompetitorLeading() {
      if (
        this.runningTime &&
        this.serverTimestamp &&
        this.competitorSections &&
        this.leader
      ) {
        return (
          this.serverTimestamp -
            this.competitorSections["START"].timestamp_100ns -
            this.leader.runTime <=
          0
        );
      } else if (
        this.competitorRunTime !== null &&
        this.leaderRunTime !== null
      ) {
        return this.competitorRunTime - this.leaderRunTime <= 0;
      }
      return false;
    }
  },
  mixins: [formatRunTime, cropTime],
  methods: {
    prefixTime(time) {
      return `${this.isCompetitorLeading ? "-" : "+"}${time}`;
    }
  }
};
</script>
