<template>
  <v-dialog
    v-if="heat"
    v-model="show"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("timesImport.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-stepper v-model="step" vertical>
          <v-stepper-step step="1" :complete="step > 1">
            {{ $t("timesImport.uploadFile") }}
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-file-input
              :label="$t('timesImport.file')"
              v-model="$v.step1.file.$model"
              :loading="step1.loading"
              :error-messages="fileErrors"
            />
            <v-btn
              @click="submitStep1Handler"
              color="primary"
              :disabled="$v.step1.$invalid"
            >
              {{ $t("timesImport.continue") }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-step step="2" :complete="step > 2">
            {{ $t("timesImport.assignFields") }}
          </v-stepper-step>

          <v-stepper-content step="2">
            <p :class="['my-4', validRowsColor]">
              {{
                $t("timesImport.validAmount", {
                  amount: validRows.length,
                  count: rows.length
                })
              }}
            </p>

            <v-select
              v-model="step2.pagination.itemsPerPage"
              :items="itemsPerPageOptions"
              :label="$t('timesImport.rowsPerPage')"
              style="width: 100px"
              class="ml-auto"
            />

            <v-simple-table
              v-if="step2.sheetData && step2.selectedColumns"
              id="sheetDataTable"
              class="mb-4"
              dense
            >
              <thead>
                <tr>
                  <th></th>
                  <th v-for="col in step2.selectedColumns" :key="col.id">
                    <v-select
                      :items="availableColumns"
                      v-model="col.value"
                      clearable
                      @input="
                        value => updateSelectedColumnHandler(col.id, value)
                      "
                    ></v-select>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(row, index) in pagedRows" :key="`row-${index}`">
                  <td class="text-right">
                    <v-btn @click="deleteRowHandler(index)" icon small>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </td>
                  <v-tooltip
                    v-for="(col, colIndex) in Object.keys(row)"
                    :key="`col-${col}`"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <td
                        v-on="
                          validateCell(
                            row[col],
                            step2.selectedColumns[colIndex]
                              ? step2.selectedColumns[colIndex].value
                              : null
                          ).length === 0
                            ? null
                            : on
                        "
                        :class="
                          validateCell(
                            row[col],
                            step2.selectedColumns[colIndex]
                              ? step2.selectedColumns[colIndex].value
                              : null
                          ).length === 0
                            ? ''
                            : 'red accent-1'
                        "
                        style="white-space: nowrap"
                      >
                        {{ row[col].formatted }}
                      </td>
                    </template>
                    <p
                      v-for="error in validateCell(
                        row[col],
                        step2.selectedColumns[colIndex]
                          ? step2.selectedColumns[colIndex].value
                          : null
                      )"
                      :key="error.id"
                      class="pa-0 ma-0"
                    >
                      {{ error.message }}
                    </p>
                  </v-tooltip>
                </tr>
              </tbody>
            </v-simple-table>

            <v-pagination
              v-if="step2.pagination.itemsPerPage !== 'ALL'"
              v-model="step2.pagination.page"
              :length="pages"
              :total-visible="$vuetify.breakpoint.smAndUp ? 7 : 5"
              class="mb-4"
            />

            <v-alert
              v-if="step2.error"
              class="mt-6"
              dense
              outlined
              type="error"
            >
              {{ $t(step2.error) }}
            </v-alert>

            <v-btn
              @click="submitStep2Handler"
              color="primary"
              :disabled="$v.step2.$invalid"
              :loading="step2.loading"
            >
              {{ $t("timesImport.uploadTimes") }}
            </v-btn>

            <v-btn @click="step--" text class="ml-2">
              {{ $t("timesImport.back") }}
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TimesImportDialog",
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      show: false,
      step: 1,
      step1: {
        loading: false,
        file: null
      },
      step2: {
        sheetData: null,
        selectedColumns: [],
        pagination: {
          itemsPerPage: 10,
          page: 1
        },
        startNumbers: null,
        loading: false
      },
      itemsPerPageOptions: [
        {
          value: 5,
          text: 5
        },
        {
          value: 10,
          text: 10
        },
        {
          value: 15,
          text: 15
        },
        {
          value: "ALL",
          text: this.$i18n.t("timesImport.all")
        }
      ],
      allowedFilesTypes: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel"
      ]
    };
  },
  validations: {
    step1: {
      file: {
        required,
        fileType: function(value) {
          return value ? this.allowedFilesTypes.includes(value.type) : true;
        }
      }
    },
    step2: {
      sheetData: {
        required
      },
      selectedColumns: {
        required: function(columns) {
          let startNumber = false;
          let start = false;
          let runTime = false;

          columns.map(column => {
            switch (column.value) {
              case "startNumber":
                startNumber = true;
                break;
              case "START":
                start = true;
                break;
              case "runTime":
                runTime = true;
                break;
            }
          });

          return startNumber && (start || runTime);
        }
      }
    }
  },
  watch: {
    file(newFile) {
      if (!this.$v.step1.file.$invalid) {
        this.step1.loading = true;
        this.step2.sheetData = null;

        const self = this;
        const reader = new FileReader();

        reader.onload = function(e) {
          let binary = "";
          const bytes = new Uint8Array(e.target.result);
          const length = bytes.byteLength;

          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          const workbook = XLSX.read(binary, {
            type: "binary"
          });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const rawSheetData = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            defval: null,
            raw: true
          });
          const formattedSheetData = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
            defval: null,
            raw: false
          });

          const sheetData = new Array();
          rawSheetData.map((row, rowIndex) => {
            const mergedRow = new Array();
            row.map((cell, colIndex) => {
              mergedRow.push({
                raw: cell,
                formatted: formattedSheetData[rowIndex][colIndex]
              });
            });
            sheetData.push(mergedRow);
          });
          self.step2.sheetData = sheetData;

          self.step2.selectedColumns = new Array();
          for (let i = 0; i < self.step2.sheetData[0].length; i++) {
            const defaultColumns = self.availableColumns.slice(0, -1);

            self.step2.selectedColumns.push({
              id: i,
              value: defaultColumns[i] ? defaultColumns[i].value : null
            });
          }

          self.step1.loading = false;
        };

        reader.onerror = function() {
          self.step1.loading = false;
        };

        reader.readAsArrayBuffer(newFile);
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      getCompetitorByStartNumber: "events/getCompetitorByStartNumber"
    }),
    file() {
      return this.step1.file;
    },
    fileErrors() {
      const errors = [];
      if (!this.$v.step1.file.$dirty) return errors;
      !this.$v.step1.file.required &&
        errors.push(this.$i18n.t("timesImport.errors.file.required"));
      !this.$v.step1.file.fileType &&
        errors.push(this.$i18n.t("timesImport.errors.file.fileType"));
      return errors;
    },
    pages() {
      if (this.step2.pagination.itemsPerPage === "ALL") {
        return 1;
      }

      return this.step2.sheetData
        ? Math.ceil(
            this.step2.sheetData.length / this.step2.pagination.itemsPerPage
          )
        : 1;
    },
    pagedRows() {
      if (!this.step2.sheetData) {
        return new Array();
      }

      if (this.step2.pagination.itemsPerPage === "ALL") {
        return this.step2.sheetData;
      }

      return this.step2.sheetData.slice(
        this.step2.pagination.itemsPerPage * (this.step2.pagination.page - 1),
        this.step2.pagination.itemsPerPage * this.step2.pagination.page
      );
    },
    availableColumns() {
      const columns = this.event.setup.sections.reduce((re, el) => {
        re.push({
          value: el.type,
          text: el.type
        });

        return re;
      }, []);

      columns.unshift({
        value: "startNumber",
        text: this.$i18n.t("competitors.tables.startNumber")
      });

      columns.push({
        value: "runTime",
        text: this.$i18n.t("competitors.tables.runTime")
      });

      return columns;
    },
    rows() {
      if (this.step2.sheetData) {
        return this.step2.sheetData;
      }

      return new Array();
    },
    validRows() {
      if (this.step2.sheetData) {
        return this.step2.sheetData.filter(row => this.isValidRow(row));
      }

      return new Array();
    },
    validRowsColor() {
      if (this.validRows.length === 0) {
        return "error--text";
      } else if (this.validRows.length === this.rows.length) {
        return "success--text";
      } else {
        return "warning--text";
      }
    },
    heatDate_100ns() {
      const heatDate = moment(this.heat.startTime).utc();
      heatDate.hours(0);
      heatDate.minutes(0);
      heatDate.seconds(0);
      heatDate.milliseconds(0);
      return heatDate.valueOf() * 10000;
    }
  },
  methods: {
    ...mapActions({
      setHeatRunTimes: "events/setHeatRunTimes"
    }),
    resetData() {
      this.step = 1;
      this.step1.file = null;
      this.step2.sheetData = null;
      this.step2.selectedColumns = new Array();
      this.$v.$reset();
    },
    openDialog() {
      this.resetData();
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    },
    updateSelectedColumnHandler(id, value) {
      for (let i = 0; i < this.step2.selectedColumns.length; i++) {
        if (
          this.step2.selectedColumns[i].id !== id &&
          this.step2.selectedColumns[i].value === value
        ) {
          Vue.set(this.step2.selectedColumns[i], "value", null);
        }
      }

      if (value === "startNumber") {
        this.buildStartNumberList(id);
      }
    },
    deleteRowHandler(rowIndex) {
      if (this.step2.pagination.itemsPerPage === "ALL") {
        this.step2.sheetData.splice(rowIndex, 1);
      } else {
        this.step2.sheetData.splice(
          rowIndex +
            this.step2.pagination.itemsPerPage *
              (this.step2.pagination.page - 1),
          1
        );
      }
    },
    submitStep1Handler() {
      this.$v.step1.$touch();
      this.step++;
    },
    submitStep2Handler() {
      this.$v.step2.$touch();
      this.step2.loading = true;

      const startNumberIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "startNumber"
      );
      const runTimeIndex = this.step2.selectedColumns.findIndex(
        el => el.value === "runTime"
      );

      let sectionIndizes = this.event.setup.sections.map(section => {
        const sectionIndex = this.step2.selectedColumns.findIndex(
          el => el.value === section.type
        );
        return {
          id: section.type,
          index: sectionIndex
        };
      });

      const items = this.validRows.map(row => {
        const startNumber = row[startNumberIndex].raw;
        const competitor = this.getCompetitorByStartNumber(startNumber);
        const item = {
          competitorId: competitor.id,
          dtos: []
        };

        sectionIndizes.map(sectionIndex => {
          if (sectionIndex.index !== -1) {
            let rawSectionTime = row[sectionIndex.index].raw;
            let sectionTime_100ns = this.excelDateToTimestamp100ns(
              rawSectionTime
            );

            // 24 h * 7 days = 168 h
            // threshold for applying the heat date
            // otherwise assumed the given value is a valid date
            if (rawSectionTime <= 168) {
              sectionTime_100ns += this.heatDate_100ns;
            }

            item.dtos.push({
              section: sectionIndex.id,
              timestamp_100ns: sectionTime_100ns
            });
          }
        });

        if (runTimeIndex !== -1) {
          const runTime_100ns = this.excelDateToTimestamp100ns(
            row[runTimeIndex].raw
          );

          const startIndex = item.dtos.findIndex(el => el.section === "START");
          const finishIndex = item.dtos.findIndex(
            el => el.section === "FINISH"
          );

          if (startIndex === -1) {
            item.dtos.push({
              section: "START",
              timestamp_100ns: this.heatDate_100ns
            });

            if (finishIndex === -1) {
              item.dtos.push({
                section: "FINISH",
                timestamp_100ns: this.heatDate_100ns + runTime_100ns
              });
            } else {
              item.dtos[finishIndex].timestamp_100ns =
                this.heatDate_100ns + runTime_100ns;
            }
          } else {
            if (finishIndex === -1) {
              item.dtos.push({
                section: "FINISH",
                timestamp_100ns:
                  item.dtos[startIndex].timestamp_100ns + runTime_100ns
              });
            } else {
              item.dtos[finishIndex].timestamp_100ns =
                item.dtos[startIndex].timestamp_100ns + runTime_100ns;
            }
          }
        }

        return item;
      });

      const payload = {
        userId: this.user.id,
        eventId: this.event.id,
        heatId: this.heat.id,
        data: items
      };

      this.setHeatRunTimes(payload)
        .then(() => {
          this.step2.loading = false;
          this.closeDialog();
        })
        .catch(() => {
          this.step2.loading = false;
          this.step2.error = "timesImport.errors.upload";
        });
    },
    isValidRow(row) {
      let isEmptyRow = true;

      for (let i = 0; i < row.length; i++) {
        if (
          this.validateCell(
            row[i],
            this.step2.selectedColumns[i]
              ? this.step2.selectedColumns[i].value
              : null
          ).length > 0
        ) {
          return false;
        }

        if (row[i] !== null) {
          isEmptyRow = false;
        }
      }

      return !isEmptyRow;
    },
    validateCell(value, fieldType) {
      if (fieldType !== null) {
        switch (true) {
          case /^startNumber$/.test(fieldType):
            return this.validateStartNumber(value.raw);
          case /^(START|INTERMEDIATE[1-9]|FINISH)$/.test(fieldType):
            return this.validateSectionTime(value.raw);
          case /^runTime$/.test(fieldType):
            return this.validateRunTime(value.raw);
        }
      }

      return new Array();
    },
    validateStartNumber(value) {
      const errors = new Array();

      if (value === null) {
        errors.push({
          id: "startNumber-required",
          message: this.$i18n.t("timesImport.validations.required")
        });
      } else if (!Number.isInteger(value)) {
        errors.push({
          id: "startNumber-number",
          message: this.$i18n.t("timesImport.validations.number")
        });
      } else if (
        this.event.competitors.filter(el => el.startNumber === value).length ===
        0
      ) {
        errors.push({
          id: "startNumber-notFound",
          message: this.$i18n.t("timesImport.validations.startNumberNotFound")
        });
      } else if (
        this.step2.startNumbers !== null &&
        this.step2.startNumbers.get(value) > 1
      ) {
        errors.push({
          id: "startNumber-notUnique",
          message: this.$i18n.t("timesImport.validations.startNumberNotUnique")
        });
      }

      return errors;
    },
    validateRunTime(value) {
      const errors = new Array();

      if (value === null) {
        errors.push({
          id: "runTime-required",
          message: this.$i18n.t("timesImport.validations.required")
        });
      } else if (isNaN(value)) {
        errors.push({
          id: "runTime-format",
          message: this.$i18n.t("timesImport.validations.format")
        });
      }

      return errors;
    },
    validateSectionTime(value) {
      const errors = new Array();

      if (value === null) {
        errors.push({
          id: "sectionTime-required",
          message: this.$i18n.t("timesImport.validations.required")
        });
      } else if (isNaN(value)) {
        errors.push({
          id: "sectionTime-format",
          message: this.$i18n.t("timesImport.validations.format")
        });
      }

      return errors;
    },
    buildStartNumberList(columnIndex) {
      this.step2.startNumbers = new Map();
      for (let i = 0; i < this.step2.sheetData.length; i++) {
        const entry = this.step2.sheetData[i][columnIndex];
        if (entry !== null) {
          if (this.step2.startNumbers.has(entry)) {
            this.step2.startNumbers.set(
              entry,
              this.step2.startNumbers.get(entry) + 1
            );
          } else {
            this.step2.startNumbers.set(entry, 1);
          }
        }
      }
    },
    excelDateToTimestamp100ns(value) {
      if (value && !isNaN(value) && value >= 0) {
        if (value >= 25569) {
          value - 25569;
        }
        return Math.round(value * 86400 * 10000000);
      }
      return value;
    }
  }
};
</script>
<style scoped>
#sheetDataTable thead th:first-child,
#sheetDataTable tbody td:first-child {
  white-space: nowrap;
  position: sticky;
  left: 0;
  z-index: 10;
  background: #ffffff;
}
#sheetDataTable tbody tr:hover td:first-child {
  background: #eeeeee;
}
</style>
