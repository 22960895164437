<template>
  <tr
    v-if="showSection"
    :class="highlight.active ? [`${highlight.color}`, 'font-weight-bold'] : ''"
  >
    <td
      key="icon"
      class="firstSectionColumn"
      style="width: 1%;"
      :style="{ cursor: entry.type === 'start' ? 'pointer' : 'cursor' }"
      @click="entry.type === 'start' && $emit('open-start-number-input')"
    >
      <v-icon>{{ sectionIcon }}</v-icon>
      <span
        v-if="isIntermediateSection"
        class="grey--text text--darken-1 font-weight-bold"
      >
        {{ entry.sectionIndex }}
      </span>
    </td>
    <td v-if="!competitor || !competitorFields" :colspan="columns.length"></td>
    <template v-else>
      <td
        v-for="field in competitorFields"
        :key="field.id"
        :style="{ textAlign: field.align, width: field.width }"
      >
        <span
          v-if="competitor && field.id === 'name'"
          style="white-space: nowrap"
        >
          {{ getCompetitorName(competitor) }}
        </span>
        <a
          v-else-if="competitor && field.id === 'startNumber'"
          @click="$emit('open-edit-action', competitor)"
        >
          {{ field.value }}
        </a>
        <span v-else-if="competitor && field.id === 'gender'">
          <v-icon small>{{ genderIcon(field.value) }}</v-icon>
        </span>
        <span
          v-else-if="competitor && field.id === 'dateOfBirth' && field.value"
        >
          {{
            isNaN(new Date(field.value))
              ? field.value
              : $d(new Date(field.value).getTime(), "shortDate")
          }}
        </span>
        <span v-else-if="competitor && field.id === 'nation'">
          <CountryFlag :country="getItemById(field.value)" :size="20" />
        </span>
        <span
          v-else-if="
            competitor && entry.type === 'track' && field.id === 'time'
          "
        >
          <Timer
            v-if="event.setup.showRunningTime && entry.competitorData.data"
            :serverTimestamp="serverTimestamp"
            :start="entry.competitorData.data.sections['START'].timestamp_100ns"
            :decimals="1"
            :syncDelta="event.setup.syncDelta"
            :base="timeBase"
            :formatRunTime="formatRunTime"
            :cropTime="cropTime"
          />
        </span>
        <span
          v-else-if="
            competitor && entry.type !== 'start' && field.id === 'time'
          "
        >
          <Timer
            v-if="event.setup.showRunningTime && entry.competitorData.data"
            :serverTimestamp="serverTimestamp"
            :start="entry.competitorData.data.sections['START'].sectionTime"
            :end="
              entry.competitorData.data.sections[entry.sectionId].sectionTime
            "
            :decimals="decimals"
            :syncDelta="event.setup.syncDelta"
            :base="timeBase"
            :formatRunTime="formatRunTime"
            :cropTime="cropTime"
          />
          <span v-else>
            -
          </span>
        </span>
        <span
          v-else-if="
            competitor &&
              entry.type !== 'start' &&
              entry.type !== 'track' &&
              field.id === 'speed' &&
              entry.competitorData.data &&
              isSpeedSection
          "
        >
          {{
            event.setup.speedEvent === true
              ? convertToSpeedUnit(
                  entry.competitorData.data.sections[entry.sectionId].speed
                )
              : isSpeedInBoundary
              ? convertToSpeedUnit(
                  entry.competitorData.data.sections[entry.sectionId].speed
                )
              : "-"
          }}
        </span>
        <span
          v-else-if="
            competitor && entry.type !== 'start' && field.id === 'diff'
          "
        >
          <SpeedDiff
            v-if="event.setup.speedEvent"
            :event="event"
            :section="entry.sectionId"
            :competitor="entry.competitorData"
            :leader="leaderData"
            colored
            prefixed
          />
          <TimerDiff
            v-else-if="
              event.setup.showRunningTime &&
                (entry.type === 'intermediate' || entry.type === 'finish')
            "
            :serverTimestamp="serverTimestamp"
            :section="entry.sectionId"
            :competitor="entry.competitorData"
            :leader="leaderData"
            :decimals="decimals"
            :base="timeBase"
            :leaderBase="leaderTimeBase"
          />
          <TimerDiffRunning
            v-else-if="
              event.setup.showRunningTime &&
                entry.type === 'track' &&
                leaderData &&
                serverTimestamp -
                  entry.competitorData.data.sections['START'].timestamp_100ns -
                  leaderData.runTime >=
                  -50000000
            "
            :serverTimestamp="serverTimestamp"
            :competitorStartSectionTime="
              entry.competitorData.data.sections['START'].timestamp_100ns
            "
            :leaderRunTime="leaderData.runTime"
            :formatRunTime="formatRunTime"
            :cropTime="cropTime"
          />
          <span v-else>
            -
          </span>
        </span>
        <span v-else-if="competitor && field.id === 'note'">
          <v-tooltip v-if="competitorInformation || isOwner" top>
            <template v-slot:activator="{ on }">
              <span>
                <v-btn
                  v-if="isOwner"
                  icon
                  @click="$emit('edit-competitor-comment', competitor.id)"
                >
                  <v-icon
                    :color="
                      competitorInformation && competitorInformation.note
                        ? 'primary'
                        : 'default'
                    "
                    v-on="on"
                  >
                    mdi-note-edit-outline
                  </v-icon>
                </v-btn>
                <v-icon v-else color="primary" v-on="on">
                  mdi-note-outline
                </v-icon>
              </span>
            </template>
            <span>{{
              competitorInformation && competitorInformation.note
                ? competitorInformation.note
                : "-"
            }}</span>
          </v-tooltip>
        </span>
        <span
          v-else-if="
            competitor &&
              (entry.type === 'start' || entry.type === 'track') &&
              field.id === 'excludeFromReferenceRun'
          "
        >
          <ReferenceSwitch
            :event="event"
            :heat="heat"
            :competitor="competitor"
            :competitor-information="competitorInformation"
          />
        </span>
        <span
          v-else-if="
            competitor && entry.type === 'finish' && field.id === 'rank'
          "
        >
          <span
            v-if="
              entry.competitorData.data.cancellation === null ||
                entry.competitorData.data.cancellation === 'UNDEFINED'
            "
          >
            {{ getRunRank(entry.competitorData.competitorId) }}
          </span>
          <span v-else>
            {{ entry.competitorData.data.cancellation }}
          </span>
        </span>
        <span
          v-else-if="
            competitor && entry.type === 'intermediate' && field.id === 'rank'
          "
        >
          {{ getIntermediateRank(entry.competitorData.competitorId) }}
        </span>
        <v-menu v-else-if="competitor && field.id === 'actions'" bottom left>
          <template v-slot:activator="{ on }">
            <v-btn small icon v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-if="entry.type === 'start'"
              @click="$emit('cancel-action', heat.id, competitor.id, 'DNS')"
            >
              <v-list-item-title>
                {{ $t("events.actions.dns") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="entry.type !== 'start' && entry.type !== 'finish'"
              @click="$emit('cancel-action', heat.id, competitor.id, 'DNF')"
            >
              <v-list-item-title>
                {{ $t("events.actions.dnf") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="$emit('cancel-action', heat.id, competitor.id, 'DSQ')"
            >
              <v-list-item-title>
                {{ $t("events.actions.dsq") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="$emit('open-edit-run-action', heat.id, competitor.id)"
            >
              <v-list-item-title>
                {{ $t("events.actions.edit") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="entry.type !== 'start'"
              @click="$emit('restart-action', heat.id, competitor.id, false)"
            >
              <v-list-item-title>
                {{ $t("events.actions.restartRun") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="entry.type !== 'start' && event.settings.massStartFieldId"
              @click="$emit('restart-action', heat.id, competitor.id, true)"
            >
              <v-list-item-title>
                {{ $t("events.actions.restartGroup") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <span v-else-if="field.id === 'pagination'">
          <v-btn
            v-if="showPagination && isFirstPageItem"
            icon
            small
            color="primary"
            :disabled="decreasePaginationDisabled"
            @click="$emit('decreaseOffset', entry.sectionIndex)"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn
            v-if="showPagination && isLastPageItem"
            icon
            small
            color="primary"
            :disabled="increasePaginationDisabled"
            @click="$emit('increaseOffset', entry.sectionIndex)"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </span>
        <span v-else-if="competitor">
          {{ field.value }}
        </span>
      </td>
    </template>
  </tr>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CountryFlag from "@/components/CountryFlag";
import Timer from "@/components/Timer";
import TimerDiff from "@/components/TimerDiff";
import TimerDiffRunning from "@/components/TimerDiffRunning";
import SpeedDiff from "@/components/SpeedDiff";
import ReferenceSwitch from "@/components/ReferenceSwitch";
import formatRunTime from "@/mixins/formatRunTime";
import cropTime from "@/mixins/cropTime";
import genderIcon from "@/mixins/genderIcon";
import getCompetitorName from "@/mixins/getCompetitorName";
import convertToSpeedUnit from "@/mixins/convertToSpeedUnit";

export default {
  name: "Section",
  components: {
    CountryFlag,
    Timer,
    TimerDiff,
    TimerDiffRunning,
    SpeedDiff,
    ReferenceSwitch
  },
  props: {
    entry: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    leaderData: {
      type: Object,
      default: null
    },
    serverTimestamp: {
      type: Number,
      default: null
    },
    decimals: {
      type: Number,
      default: 2
    },
    liveTime: {
      type: Object,
      required: true
    },
    sectionItems: {
      type: Number,
      default: 0
    },
    sectionItemsPerPage: {
      type: Number,
      default: 5
    },
    sectionIndexOffset: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      highlight: {
        active: false,
        color: "primary--text"
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      getItemById: "nations/getItemById",
      getCompetitorById: "events/getCompetitorById"
    }),
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    },
    competitor() {
      if (this.entry.competitorData) {
        if (this.entry.competitorData.id) {
          return this.entry.competitorData;
        } else if (this.entry.competitorData.competitorId) {
          return this.getCompetitorById(this.entry.competitorData.competitorId);
        }
      }

      return null;
    },
    sectionSetup() {
      return this.event.setup.sections[this.entry.sectionIndex];
    },
    sectionIcon() {
      switch (this.entry.type) {
        case "start":
          return "mdi-home";
        case "track":
          return "mdi-run";
        case "intermediate":
          return "mdi-timer-outline";
        case "finish":
          return "mdi-flag";
        default:
          return "";
      }
    },
    isIntermediateSection() {
      return this.entry.type === "intermediate";
    },
    isSpeedSection() {
      if (this.entry.competitorData && this.entry.competitorData.data) {
        if (
          this.entry.competitorData.data.sections[this.entry.sectionId] &&
          this.entry.competitorData.data.sections[this.entry.sectionId].speed
        ) {
          return true;
        }
      }

      return false;
    },
    competitorFields() {
      if (this.competitor) {
        const fields = [];

        this.columns.map(header => {
          const el = header.value.split(".");

          if (el[0] === "userData") {
            fields.push({
              id: el[1],
              value: this.competitor.userData[el[1]]
                ? this.competitor.userData[el[1]]
                : null,
              align: header.align || null,
              width: header.width || null
            });
          } else {
            fields.push({
              id: el[0],
              value: this.competitor[el[0]] ? this.competitor[el[0]] : null,
              align: header.align || null,
              width: header.width || null
            });
          }
        });

        return fields;
      }

      return null;
    },
    minSpeed() {
      if (this.sectionSetup) {
        return this.sectionSetup.minSpeed || 0;
      }

      return null;
    },
    maxSpeed() {
      if (this.sectionSetup && this.sectionSetup.maxSpeed) {
        return this.sectionSetup.maxSpeed;
      }

      return null;
    },
    isSpeedInBoundary() {
      if (
        this.isSpeedSection &&
        this.minSpeed >= 0 &&
        this.maxSpeed &&
        this.entry.competitorData &&
        this.entry.competitorData.data
      ) {
        const speed = this.entry.competitorData.data.sections[
          this.entry.sectionId
        ].speed;

        return speed >= this.minSpeed && speed <= this.maxSpeed;
      }

      return false;
    },
    pastHeats() {
      if (this.heat) {
        const allHeats = this.event.heats;

        const pastHeats = new Array();

        for (const heat of allHeats) {
          if (heat.id !== this.heat.id) {
            pastHeats.push(heat);
          } else {
            break;
          }
        }

        return pastHeats;
      }

      return [];
    },
    pastTimes() {
      if (this.competitor && this.pastHeats.length > 0) {
        const times = this.pastHeats.reduce((re, el) => {
          if (this.entry.type !== "start") {
            if (this.entry.type === "track") {
              const rankingEntry = el.ranking.entries.find(
                entry => entry.competitorId === this.competitor.id
              );

              if (rankingEntry !== undefined) {
                return re + rankingEntry.runTime;
              }
            } else {
              const heatCycleEntry = el.heatCycle.entries.find(
                entry => entry.competitorId === this.competitor.id
              );

              if (heatCycleEntry !== undefined) {
                if (heatCycleEntry.data.sections[this.entry.sectionId]) {
                  return (
                    re +
                    heatCycleEntry.data.sections[this.entry.sectionId]
                      .sectionTime
                  );
                }
              }
            }
          }

          return re;
        }, 0);

        return times;
      }

      return 0;
    },
    pastLeaderTimes() {
      if (this.leaderData) {
        const times = this.pastHeats.reduce((re, el) => {
          if (this.entry.type !== "start") {
            if (this.entry.type === "track") {
              const rankingEntry = el.ranking.entries.find(
                entry => entry.competitorId === this.leaderData.competitorId
              );

              if (rankingEntry !== undefined) {
                return re + rankingEntry.runTime;
              }
            } else {
              const heatCycleEntry = el.heatCycle.entries.find(
                entry => entry.competitorId === this.leaderData.competitorId
              );

              if (heatCycleEntry !== undefined) {
                if (heatCycleEntry.data.sections[this.entry.sectionId]) {
                  return (
                    re +
                    heatCycleEntry.data.sections[this.entry.sectionId]
                      .sectionTime
                  );
                }
              }
            }
          }

          return re;
        }, 0);

        return times;
      }

      return 0;
    },
    timeBase() {
      if (this.liveTime !== null) {
        switch (this.liveTime.value) {
          case "RUN_TIME":
            return 0;
          case "TOTAL_TIME":
            return this.pastTimes;
          default:
            return 0;
        }
      }

      return 0;
    },
    leaderTimeBase() {
      if (this.liveTime !== null) {
        switch (this.liveTime.value) {
          case "RUN_TIME":
            return 0;
          case "TOTAL_TIME":
            return this.pastLeaderTimes;
          default:
            return 0;
        }
      }

      return 0;
    },
    showPagination() {
      return this.sectionItems > 5;
    },
    isFirstPageItem() {
      return this.entry.runIndex === 1 + this.sectionIndexOffset;
    },
    isLastPageItem() {
      return (
        this.entry.runIndex ===
        this.sectionItemsPerPage + this.sectionIndexOffset
      );
    },
    decreasePaginationDisabled() {
      return this.entry.runIndex === 1;
    },
    increasePaginationDisabled() {
      return this.entry.runIndex === this.sectionItems;
    },
    showSection() {
      return (
        this.entry.runIndex === 0 ||
        (this.entry.runIndex > this.sectionIndexOffset &&
          this.entry.runIndex <
            1 + this.sectionIndexOffset + this.sectionItemsPerPage)
      );
    },
    competitorInformation() {
      if (this.heat && this.competitor) {
        const competitorInformation = this.heat.competitorInformation.find(
          el => el.competitorId === this.competitor.id
        );
        if (competitorInformation) {
          return competitorInformation;
        }
      }

      return null;
    }
  },
  watch: {
    sectionItems(newSectionItems, oldSectionItems) {
      if (this.entry.runIndex === 0 && newSectionItems < oldSectionItems) {
        this.$emit("decreaseOffset", this.entry.sectionIndex);
      }
    }
  },
  methods: {
    getRunRank(competitorId) {
      if (this.liveTime !== null) {
        switch (this.liveTime.value) {
          case "RUN_TIME":
            return this.entry.competitorData.rank || null;
          case "TOTAL_TIME":
            const eventRankingEntry = this.event.overallRanking.entries.find(
              el => el.competitorId === competitorId
            );

            if (eventRankingEntry !== undefined) {
              return eventRankingEntry.rank || null;
            } else {
              break;
            }
          default:
            return null;
        }
      }

      return null;
    },
    getIntermediateRank(competitorId) {
      if (this.liveTime !== null) {
        const sectionRankingEntries = this.heat.ranking.sectionRanking.filter(
          el => el.section === this.entry.sectionId
        );
        const sectionTimeEntries =
          sectionRankingEntries.length > 0
            ? sectionRankingEntries.filter(el => el.type === "SECTION_TIME")
            : null;
        const sectionTimeEntry =
          sectionTimeEntries && sectionTimeEntries.length > 0
            ? sectionTimeEntries[0].entries.find(
                el => el.competitorId === competitorId
              )
            : null;

        switch (this.liveTime.value) {
          case "RUN_TIME":
            return sectionTimeEntry ? sectionTimeEntry.rank : null;
          case "TOTAL_TIME":
            return null;
        }
      }

      return null;
    },
    highlightSection(color = "primary--text", duration = 1000) {
      this.highlight.color = color;
      this.highlight.active = true;

      setTimeout(() => {
        this.highlight.active = false;
      }, duration);
    }
  },
  mixins: [
    formatRunTime,
    cropTime,
    genderIcon,
    getCompetitorName,
    convertToSpeedUnit
  ]
};
</script>

<style scoped>
.firstSectionColumn {
  white-space: nowrap;
  position: sticky;
  left: 0;
  z-index: 10;
  background: #ffffff;
}
</style>
