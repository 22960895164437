const runTime = value => {
  if (typeof value === "undefined" || value === null || value === "") {
    return true;
  }
  return /^[0-9]{0,2}\:?([0-5]?[0-9]?)\:?([0-5]?[0-9]?)(\.|\,)[0-9]{0,5}$/.test(
    value
  );
};

export default runTime;
