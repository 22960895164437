<template>
  <div>
    <v-btn
      @click="printHandler"
      class="ma-1"
      color="primary"
      small
      :disabled="isDisabled"
    >
      <v-icon>mdi-printer</v-icon>
    </v-btn>

    <div v-show="false" :id="printId">
      <div
        class="v-application v-application--is-ltr theme--light"
        style="font-family: 'Roboto'"
      >
        <div class="flex-grow-1">
          <div>
            <div class="d-flex justify-space-between">
              <div>
                <h1>{{ event.name }}</h1>
                <p>
                  {{ $t("organizer.organizedBy") }}
                  {{ event.organizer.organizerName }}
                </p>
              </div>
              <div class="text-right">
                <p v-if="event.start === event.end" style="font-weight: bold">
                  {{ $d(new Date(event.start * 1000), "shortDate") }}
                </p>
                <p v-else style="font-weight: bold">
                  {{ $d(new Date(event.start * 1000), "shortDate") }} -
                  {{ $d(new Date(event.end * 1000), "shortDate") }}
                </p>
                <p>{{ event.place }} ({{ event.country }})</p>
              </div>
            </div>
          </div>
          <h2 v-if="heat">{{ $t("events.ranking") }}: {{ heat.name }}</h2>
          <h2 v-else>{{ $t("events.totalRanking") }}</h2>
          <HeatRanking v-if="heat" :event="event" :heat="heat" print />
          <EventRanking v-else :event="event" print />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeatRanking from "@/components/HeatRanking";
import EventRanking from "@/components/EventRanking";

export default {
  name: "PrintRanking",
  components: {
    HeatRanking,
    EventRanking
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    printId() {
      if (this.heat) {
        return "heatRankingList";
      }
      return "eventRankingList";
    },
    isDisabled() {
      if (this.heat) {
        return this.heat.ranking.entries.length === 0;
      }

      return this.event.overallRanking.entries.length === 0;
    }
  },
  methods: {
    printHandler() {
      this.$htmlToPaper(this.printId);
    }
  }
};
</script>

<style>
.v-data-table--dense /deep/ thead > tr > th,
.v-data-table--dense /deep/ tbody > tr > td {
  padding: 0 10px !important;
}
</style>
