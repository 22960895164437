<template functional>
  <div>
    <span
      v-if="
        props.serverTimestamp &&
          props.competitorStartSectionTime !== null &&
          props.leaderRunTime !== null
      "
      :class="
        props.serverTimestamp -
          props.competitorStartSectionTime -
          props.leaderRunTime <=
        0
          ? 'success--text'
          : 'error--text'
      "
    >
      {{
        props.serverTimestamp -
          props.competitorStartSectionTime -
          props.leaderRunTime <=
        0
          ? "-"
          : "+"
      }}{{
        props.cropTime(
          props.formatRunTime(
            props.serverTimestamp -
              props.competitorStartSectionTime -
              props.leaderRunTime,
            1,
            false
          )
        )
      }}
    </span>
    <span v-else>
      -
    </span>
  </div>
</template>

<script>
export default {
  name: "TimerDiffRunning",
  props: {
    serverTimestamp: {
      type: Number,
      default: null
    },
    competitorStartSectionTime: {
      type: Number,
      default: null
    },
    leaderRunTime: {
      type: Number,
      default: null
    },
    formatRunTime: Function,
    cropTime: Function
  }
};
</script>
