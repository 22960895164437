<template>
  <div>
    <span
      v-if="diff"
      :class="
        colored ? (isCompetitorLeading ? 'success--text' : 'error--text') : ''
      "
    >
      {{ convertToSpeedUnit(diff, true, prefixed) }}
    </span>
    <span v-else>
      -
    </span>
  </div>
</template>

<script>
import convertToSpeedUnit from "@/mixins/convertToSpeedUnit";

export default {
  name: "SpeedDiff",
  props: {
    event: {
      type: Object,
      required: true
    },
    section: {
      type: String,
      default: null
    },
    competitor: {
      type: Object,
      default: null
    },
    leader: {
      type: Object,
      default: null
    },
    colored: {
      type: Boolean,
      default: false
    },
    prefixed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    competitorSections() {
      if (
        this.competitor &&
        this.competitor.data &&
        this.competitor.data.sections
      ) {
        return this.competitor.data.sections;
      }
      return null;
    },
    leaderSections() {
      if (this.leader && this.leader.data && this.leader.data.sections) {
        return this.leader.data.sections;
      }
      return null;
    },
    competitorSpeed() {
      if (this.competitorSections && this.section) {
        if (
          this.competitorSections[this.section] &&
          this.competitorSections[this.section].speed !== undefined
        ) {
          return this.competitorSections[this.section].speed;
        } else if (this.competitorSections["START"].speed !== undefined) {
          return this.competitorSections["START"].speed;
        }
      }
      return null;
    },
    leaderSpeed() {
      if (this.leaderSections && this.section) {
        if (
          this.leaderSections[this.section] &&
          this.leaderSections[this.section].speed !== undefined
        ) {
          return this.leaderSections[this.section].speed;
        } else if (this.leaderSections["START"].speed !== undefined) {
          return this.leaderSections["START"].speed;
        }
      }
      return null;
    },
    diff() {
      if (this.competitorSpeed !== null && this.leaderSpeed !== null) {
        return this.competitorSpeed - this.leaderSpeed;
      }
      return null;
    },
    isCompetitorLeading() {
      if (this.diff !== null) {
        return this.diff > 0;
      }
      return false;
    }
  },
  mixins: [convertToSpeedUnit]
};
</script>
