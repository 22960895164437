<template>
  <v-switch
    :input-value="excludeFromReferenceRun"
    :true-value="false"
    :false-value="true"
    :loading="isLoading"
    :color="statusColor"
    dense
    hide-details
    style="margin: 0; padding: 0"
    @change="submitExcludeFromReferenceRunUpdate"
  ></v-switch>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ReferenceSwitch",
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      required: true
    },
    competitor: {
      type: Object,
      required: true
    },
    competitorInformation: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isLoading: false,
      success: null,
      error: null
    };
  },
  computed: {
    statusColor() {
      if (this.error) {
        return "error";
      }
      if (this.success) {
        return "success";
      }
      return "primary";
    },
    excludeFromReferenceRun() {
      return (
        this.$props.competitorInformation?.excludeFromReferenceRun ?? false
      );
    }
  },
  methods: {
    ...mapActions({
      updateExcludeFromReferenceRun: "events/updateExcludeFromReferenceRun"
    }),
    submitExcludeFromReferenceRunUpdate() {
      this.isLoading = true;

      const payload = {
        eventId: this.event.id,
        heatId: this.heat.id,
        competitorId: this.competitor.id,
        data: {
          value: !this.excludeFromReferenceRun
        }
      };

      this.updateExcludeFromReferenceRun(payload)
        .then(() => {
          this.isLoading = false;
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 2000);
        })
        .catch(() => {
          this.isLoading = false;
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 2000);
        });
    }
  }
};
</script>
