<template>
  <div>
    <v-container v-if="!print" :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row align="center">
        <v-col cols="auto">
          <v-select
            :label="$t('events.classFilter.class')"
            :items="classFilters"
            v-model="classFilter"
            return-object
          />
        </v-col>

        <v-col cols="auto">
          <TableFilter :event="event" reduced timingFields />
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="auto">
          <v-text-field
            :label="$t('events.search')"
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      ref="heatRankingTable"
      :headers="
        print ? rankingHeaders : isOwner ? rankingHeadersOwner : rankingHeaders
      "
      :items="indexedHeatRanking"
      :search="search"
      fixed-header
      mobile-breakpoint="0"
      single-select
      :value="highlightedCompetitor"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      dense
      :disable-sort="print"
      :disable-filtering="print"
      :disable-pagination="print"
      :hide-default-footer="print"
      :class="printClass"
    >
      <template v-slot:item.startNumber="{ item }">
        <a @click="$emit('open-edit-action', item)">
          {{ item.startNumber }}
        </a>
      </template>

      <template
        v-for="(section, sectionIndex) in intermediateSections"
        v-slot:[`header.data.sections.${section.type}.sectionTime`]
      >
        <v-icon :key="`${section.type}-sectionTime-icon`"
          >mdi-timer-outline</v-icon
        >
        <span
          class="grey--text, text--darken-1, font-weight-bold"
          :key="`${section.type}-sectionTime-index`"
        >
          {{ sectionIndex + 1 }}
        </span>
      </template>

      <template
        v-for="(section, sectionIndex) in event.setup.sections.slice(1)"
        v-slot:[`header.splitTimes.${section.type}`]
      >
        <v-icon :key="`${section.type}-splitTime-icon`"
          >mdi-ray-start-end</v-icon
        >
        <span
          class="grey--text, text--darken-1, font-weight-bold"
          :key="`${section.type}-splitTime-index`"
        >
          {{ sectionIndex + 1 }}
        </span>
      </template>

      <template
        v-for="(section, sectionIndex) in speedSections"
        v-slot:[`header.data.sections.${section.type}.speed`]
      >
        <v-icon :key="`${section.type}-speed-icon`">mdi-speedometer</v-icon>
        <span
          class="grey--text, text--darken-1, font-weight-bold"
          :key="`${section.type}-speed-index`"
        >
          {{ sectionIndex + 1 }}
        </span>
      </template>

      <template v-slot:item.rank="{ item }">
        <Trophy
          v-if="
            !print &&
              item.rank > 0 &&
              item.rank < 4 &&
              item.state !== 'CANCELLED'
          "
          :rank="item.rank"
        />
        <span v-else-if="item.state !== 'CANCELLED'">
          {{ item.rank }}
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template v-slot:item.userData.name="{ item }">
        <span style="white-space: nowrap">
          {{ getCompetitorName(item) }}
        </span>
      </template>

      <template v-slot:item.userData.nation="{ item }">
        <span v-if="item.userData.nation && print">{{
          item.userData.nation
        }}</span>
        <CountryFlag
          v-else-if="item.userData.nation"
          :country="getNationById(item.userData.nation)"
          :size="20"
        />
      </template>

      <template v-slot:item.userData.gender="{ item }">
        <v-icon small>
          {{ genderIcon(item.userData.gender) }}
        </v-icon>
      </template>

      <template v-slot:item.userData.dateOfBirth="{ item }">
        {{
          item.userData.dateOfBirth
            ? isNaN(new Date(item.userData.dateOfBirth))
              ? item.userData.dateOfBirth
              : $d(new Date(item.userData.dateOfBirth).getTime(), "shortDate")
            : ""
        }}
      </template>

      <template
        v-for="section in intermediateSections"
        v-slot:[`item.data.sections.${section.type}.sectionTime`]="{ item }"
      >
        <span
          v-if="
            item.data &&
              item.data.sections[section.type] &&
              item.data.sections[section.type].timestamp_100ns
          "
          :key="section.type"
        >
          {{
            cropTime(
              formatRunTime(
                item.data.sections[section.type].timestamp_100ns -
                  item.data.sections["START"].timestamp_100ns,
                precision
              )
            )
          }}
        </span>
        <span v-else :key="section.type">
          -
        </span>
      </template>

      <template
        v-for="section in event.setup.sections.slice(1)"
        v-slot:[`item.splitTimes.${section.type}`]="{ item }"
      >
        <span
          v-if="item.splitTimes && item.splitTimes[section.type]"
          :key="section.type"
        >
          {{
            cropTime(formatRunTime(item.splitTimes[section.type], precision))
          }}
        </span>
        <span v-else :key="section.type">
          -
        </span>
      </template>

      <template
        v-for="section in speedSections"
        v-slot:[`item.data.sections.${section.type}.speed`]="{ item }"
      >
        <span
          v-if="
            item.data &&
              item.data.sections[section.type] &&
              (event.setup.speedEvent ||
                isSpeedInBoundary(
                  section.type,
                  item.data.sections[section.type]
                ))
          "
          :key="section.type"
        >
          {{ convertToSpeedUnit(item.data.sections[section.type].speed) }}
        </span>
        <span v-else :key="section.type">
          -
        </span>
      </template>

      <template
        v-for="heat in event.heats.filter(heat => heat.id !== this.heat.id)"
        v-slot:[`item.heatTimeDifferences.${heat.id}.timeDifference`]="{
          item
        }"
      >
        <span
          v-if="
            item.state !== 'CANCELLED' &&
              item.data !== undefined &&
              item.heatTimeDifferences[heat.id]
          "
          :key="`heatTimeDifference-${heat.id}`"
          :class="{
            'text-decoration-underline':
              item.heatTimeDifferences[heat.id].highlighted
          }"
        >
          {{
            cropTime(
              formatRunTime(
                item.heatTimeDifferences[heat.id].timeDifference,
                precision
              )
            )
          }}
        </span>
        <span v-else :key="`heatTimeDifference-${heat.id}`">
          -
        </span>
      </template>

      <template v-slot:item.timePostings="{ item }">
        <div
          v-if="
            item.data &&
              item.data.timePostings &&
              item.data.timePostings.length > 0
          "
        >
          <p
            v-for="timePosting in item.data.timePostings"
            :key="timePosting.id"
            class="ma-0"
            style="white-space: nowrap"
          >
            <span v-if="timePosting.time_100ns !== 0"
              >{{ timePosting.time_100ns / 10000000 }}&nbsp;s&nbsp;</span
            >
            <span v-if="timePosting.message">{{ timePosting.message }}</span>
          </p>
        </div>
        <span v-else>
          -
        </span>
      </template>

      <template v-slot:item.targetTime="{ item }">
        <span v-if="item.state === 'CANCELLED' && item.data !== undefined">
          -
        </span>
        <span v-else>
          {{ cropTime(formatRunTime(item.targetTime, precision)) }}
        </span>
      </template>

      <template v-slot:item.runTime="{ item }">
        <span v-if="item.state === 'CANCELLED' && item.data !== undefined">
          {{ item.data.cancellation }}
        </span>
        <span v-else>
          {{ cropTime(formatRunTime(item.runTime, precision)) }}
        </span>
      </template>

      <template v-slot:item.diff="{ item }">
        <span v-if="item.state === 'CANCELLED' || item.diff === 0">
          -
        </span>
        <span v-else-if="event.setup.speedEvent">
          <SpeedDiff
            :event="event"
            section="FINISH"
            :competitor="item"
            :leader="heatLeaderData"
          />
        </span>
        <span v-else>
          {{ cropTime(formatRunTime(item.diff, precision)) }}
        </span>
      </template>

      <template v-slot:item.isReferenceRun="{ item }">
        <v-simple-checkbox
          :value="item.isReferenceRun"
          color="primary"
          :ripple="false"
          @input="setHeatReferenceRun(item.runId, item.isReferenceRun)"
        ></v-simple-checkbox>
      </template>

      <template v-slot:item.note="{ item }">
        <v-tooltip v-if="item.note || isOwner" top>
          <template v-slot:activator="{ on }">
            <span>
              <v-btn
                v-if="isOwner"
                icon
                @click="$emit('edit-competitor-comment', item.id)"
              >
                <v-icon :color="item.note ? 'primary' : 'default'" v-on="on">
                  mdi-note-edit-outline
                </v-icon>
              </v-btn>
              <v-icon v-else color="primary" v-on="on">
                mdi-note-outline
              </v-icon>
            </span>
          </template>
          <span>{{ item.note ? item.note : "-" }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn small icon v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              :disabled="item.state === 'CANCELLED'"
              @click="cancelCompetitorRun(heat.id, item.id, 'DSQ')"
            >
              <v-list-item-title>
                {{ $t("events.actions.dsq") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="event.setup.useTolerances"
              @click="setEventReferenceRun(item.runId, true)"
            >
              <v-list-item-title>
                {{ $t("events.actions.setEventReferenceRun") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="$emit('open-edit-run-action', heat.id, item.id)"
            >
              <v-list-item-title>
                {{ $t("events.actions.edit") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="restartCompetitorRun(heat.id, item.id, false)">
              <v-list-item-title>
                {{ $t("events.actions.restartRun") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="event.settings.massStartFieldId"
              @click="restartCompetitorRun(heat.id, item.id, true)"
            >
              <v-list-item-title>
                {{ $t("events.actions.restartGroup") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import TableFilter from "@/components/TableFilter";
import Trophy from "@/components/Trophy";
import CountryFlag from "@/components/CountryFlag";
import SpeedDiff from "@/components/SpeedDiff";
import whitelist from "@/mixins/whitelist";
import formatRunTime from "@/mixins/formatRunTime";
import cropTime from "@/mixins/cropTime";
import genderIcon from "@/mixins/genderIcon";
import getCompetitorName from "@/mixins/getCompetitorName";
import convertToSpeedUnit from "@/mixins/convertToSpeedUnit";
import headers from "@/mixins/headers";

export default {
  name: "HeatRanking",
  components: {
    TableFilter,
    Trophy,
    CountryFlag,
    SpeedDiff
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      required: true
    },
    print: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      precisions: ["1s", "1/10s", "1/100s", "1/1000s", "1/10000s", "1/100000s"],
      search: "",
      page: 1,
      itemsPerPage: 10
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      heatRunCollection: state => state.events.selectedItemHeatRunCollection
    }),
    ...mapGetters({
      getNationById: "nations/getItemById",
      getCompetitorById: "events/getCompetitorById",
      getCompetitorByUserId: "events/getCompetitorByUserId",
      getFieldPreferenceById: "settings/getFieldPreferenceById"
    }),
    classFilter: {
      get() {
        return this.$store.state.events.selectedItemClassFilter;
      },
      set(value) {
        this.$store.commit("events/updateSelectedItemClassFilter", value);
      }
    },
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    },
    printClass() {
      if (this.rankingHeaders.length < 15) {
        return "";
      }
      if (this.rankingHeaders.length < 17) {
        return "print-large";
      }
      if (this.rankingHeaders.length < 19) {
        return "print-medium";
      }
      return "print-small";
    },
    rankingHeaders() {
      const headers = Array.from(this.headers());

      headers.unshift({
        text: this.$i18n.t("competitors.tables.rank"),
        value: "rank",
        align: "right",
        width: "1%",
        sort: (a, b) => {
          if (a === null) {
            return 1;
          }
          if (b === null) {
            return -1;
          }
          return a - b;
        }
      });

      this.event.setup.sections.slice(1).map((section, index) => {
        if (
          this.getFieldPreferenceById("splitTime") &&
          (index > 0 ||
            (!this.getFieldPreferenceById("sectionTime") &&
              !this.event.setup.speedEvent))
        ) {
          headers.push({
            value: `splitTimes.${section.type}`,
            align: "right",
            sort: (a, b) => {
              if (a === undefined) {
                return 1;
              }
              if (b === undefined) {
                return -1;
              }
              return a - b;
            }
          });
        }
        if (
          section.type !== "FINISH" &&
          this.getFieldPreferenceById("sectionTime")
        ) {
          headers.push({
            value: `data.sections.${section.type}.sectionTime`,
            align: "right",
            sort: (a, b) => {
              if (a === undefined) {
                return 1;
              }
              if (b === undefined) {
                return -1;
              }
              return a - b;
            }
          });
        }
      });

      if (this.getFieldPreferenceById("speed")) {
        this.speedSections.map(section => {
          headers.push({
            value: `data.sections.${section.type}.speed`,
            align: "right",
            sort: (a, b) => {
              if (a === undefined) {
                return 1;
              }
              if (b === undefined) {
                return -1;
              }
              return a - b;
            }
          });
        });
      }

      const hasAnyTimePostings =
        this.heat.heatCycle.entries.filter(
          entry => entry.data && entry.data.timePostings.length > 0
        ).length > 0;
      if (hasAnyTimePostings) {
        headers.push({
          text: this.$i18n.t("competitors.tables.timePostings"),
          value: "timePostings",
          align: "right",
          sortable: false
        });
      }

      if (this.isRegularity && this.isPerfection) {
        this.event.heats
          .filter(heat => heat.id !== this.heat.id)
          .map(heat => {
            headers.push({
              text: `${heat.name} Diff`,
              value: `heatTimeDifferences.${heat.id}.timeDifference`,
              align: "right"
            });
          });
      }

      if (
        this.isRegularity &&
        this.isTargetTime &&
        this.heat.id !== this.event.heats[0].id
      ) {
        headers.push({
          text: this.$i18n.t("competitors.tables.targetTime"),
          value: "targetTime",
          align: "right"
        });
      }

      if (!this.event.setup.speedEvent) {
        headers.push({
          text: this.$i18n.t("competitors.tables.runTime"),
          value: "runTime",
          align: "right",
          sort: (a, b) => {
            if (a === null) {
              return 1;
            }
            if (b === null) {
              return -1;
            }
            return a - b;
          }
        });
      }

      headers.push({
        text: this.$i18n.t("competitors.tables.diff"),
        value: "diff",
        align: "right",
        sort: (a, b) => {
          if (a === null) {
            return 1;
          }
          if (b === null) {
            return -1;
          }
          return a - b;
        }
      });

      if (this.heat.competitorInformation.length > 0 || this.isOwner) {
        headers.push({
          text: this.$i18n.t("competitors.tables.note"),
          value: "note",
          sortable: false
        });
      }

      return headers;
    },
    rankingHeadersOwner() {
      const headers = Array.from(this.rankingHeaders);

      if (this.event.setup.useTolerances) {
        headers.push({
          text: this.$i18n.t("competitors.tables.referenceRun"),
          value: "isReferenceRun"
        });
      }

      headers.push({
        value: "actions",
        sortable: false,
        width: "1%"
      });

      return headers;
    },
    indexedHeatRanking() {
      let entries = new Array();
      if (this.classFilter.value.value === "all") {
        entries = this.heat.ranking.entries;
      } else {
        const classDivision = this.heat.ranking.classDivisionRanking[
          this.classFilter.value.field
        ];
        if (classDivision) {
          const classDivisionValue = classDivision.find(
            el => el.key === this.classFilter.value.value
          );
          if (classDivisionValue) {
            entries = classDivisionValue.entries;
          }
        }
      }

      return entries
        .map(rankingEntry => {
          const entry = {
            ...rankingEntry
          };

          if (!entry.state && !entry.runTime) {
            const heatRankingEntryIndex = this.heat.ranking.entries.findIndex(
              el => el.competitorId === rankingEntry.competitorId
            );

            if (heatRankingEntryIndex !== -1) {
              Object.assign(entry, {
                state: this.heat.ranking.entries[heatRankingEntryIndex].state,
                runTime: this.heat.ranking.entries[heatRankingEntryIndex]
                  .runTime
              });
            }
          }

          if (entry.state === "INCOMPLETE") {
            entry.rank = null;
            entry.runTime = null;
          }

          const competitor = this.getCompetitorById(rankingEntry.competitorId);
          if (competitor) {
            Object.assign(entry, competitor);
          }

          const heatCycleIndex = this.heat.heatCycle.entries.findIndex(
            el => el.competitorId === rankingEntry.competitorId
          );
          if (heatCycleIndex !== -1) {
            const heatCycleEntry = this.heat.heatCycle.entries[heatCycleIndex];
            Object.assign(entry, heatCycleEntry);
            entry.isReferenceRun = this.heat.referenceRun
              ? this.heat.referenceRun.isActive &&
                this.heat.referenceRun.runId === heatCycleEntry.runId
              : false;

            entry.splitTimes = new Object();

            this.event.setup.sections.slice(1).map((section, index) => {
              if (
                heatCycleEntry.data.sections[section.type] &&
                heatCycleEntry.data.sections[
                  this.event.setup.sections[index].type
                ]
              ) {
                entry.splitTimes[section.type] =
                  heatCycleEntry.data.sections[section.type].sectionTime -
                  heatCycleEntry.data.sections[
                    this.event.setup.sections[index].type
                  ].sectionTime;
              }
            });
          }

          let localMinimum = null;
          if (this.isRegularity && this.isPerfection) {
            entry.heatTimeDifferences = new Object();
            rankingEntry.regularity?.map((el, index) => {
              const heatId = this.heatRunCollection.get(el.runId);

              if (!localMinimum) {
                localMinimum = {
                  heatId,
                  timeDifference: el.timeDifference
                };
              } else if (el.timeDifference < localMinimum.timeDifference) {
                localMinimum.heatId = heatId;
                localMinimum.timeDifference = el.timeDifference;
              }

              entry.heatTimeDifferences[heatId] = {
                timeDifference: el.timeDifference,
                highlighted: false
              };
            });

            if (localMinimum) {
              entry.heatTimeDifferences[localMinimum.heatId].highlighted = true;
            }
          }

          entry.diff = null;
          if (
            this.isRegularity &&
            this.isPerfection &&
            localMinimum &&
            this.heatLeaderData &&
            this.heatLeaderData.smallestDifference
          ) {
            entry.diff =
              localMinimum.timeDifference -
              this.heatLeaderData.smallestDifference;
          } else if (
            this.isRegularity &&
            this.isTargetTime &&
            this.heat.id !== this.event.heats[0].id
          ) {
            const targetTimeRankingEntry = this.event.heats[0].ranking.entries.find(
              el => el.competitorId === rankingEntry.competitorId
            );
            if (
              targetTimeRankingEntry &&
              targetTimeRankingEntry.state === "FINISHED"
            ) {
              const targetTime = targetTimeRankingEntry.runTime;
              entry.targetTime = targetTime;
              entry.diff = Math.abs(entry.runTime - targetTime);
            }
          } else if (entry.runTime && this.leader) {
            entry.diff = entry.runTime - this.leader.runTime;
          }

          const competitorInformation = this.heat.competitorInformation.find(
            el => el.competitorId === rankingEntry.competitorId
          );
          if (competitorInformation) {
            entry.note = competitorInformation.note;
          }

          return entry;
        })
        .sort((a, b) => {
          if (a.state === "CANCELLED" && b.state === "CANCELLED") {
            const cancellationCompare = a.data.cancellation.localeCompare(
              b.data.cancellation
            );

            if (cancellationCompare === 0) {
              return a.startNumber - b.startNumber;
            }

            return cancellationCompare;
          }

          return 0;
        });
    },
    finishSection() {
      return this.event.setup.sections[this.event.setup.sections.length - 1];
    },
    intermediateSections() {
      return this.event.setup.sections.filter(
        section => section.type !== "START" && section.type !== "FINISH"
      );
    },
    speedSections() {
      return this.event.setup.sections.filter(section =>
        section.hasOwnProperty("distance")
      );
    },
    leader() {
      if (this.classFilter.value.value === "all") {
        if (
          this.heat.ranking.entries.length > 0 &&
          this.heat.ranking.entries[0].runTime > 0 &&
          this.heat.ranking.entries[0].state !== "CANCELLED"
        ) {
          return this.heat.ranking.entries[0];
        }
      } else {
        const classDivision = this.heat.ranking.classDivisionRanking[
          this.classFilter.value.field
        ];
        if (classDivision) {
          const classDivisionValue = classDivision.find(
            el => el.key === this.classFilter.value.value
          );
          if (classDivisionValue) {
            const entries = classDivisionValue.entries;
            if (entries.length > 0) {
              const entry = entries[0];
              const heatRankingEntry = this.heat.ranking.entries.find(
                el => el.competitorId === entry.competitorId
              );
              if (
                heatRankingEntry &&
                heatRankingEntry.runTime > 0 &&
                heatRankingEntry.state !== "CANCELLED"
              ) {
                return {
                  competitorId: entry.competitorId,
                  rank: entry.rank,
                  runTime: heatRankingEntry.runTime,
                  state: heatRankingEntry.state
                };
              }
            }
          }
        }
      }

      return null;
    },
    heatLeaderData() {
      if (this.leader) {
        const index = this.heat.heatCycle.entries.findIndex(
          entry => entry.competitorId === this.leader.competitorId
        );

        if (index >= 0) {
          const heatLeaderData = {
            ...this.leader,
            ...this.heat.heatCycle.entries[index]
          };

          if (this.isRegularity && this.isPerfection) {
            let localMinimum = null;
            this.leader.regularity?.map(el => {
              const heatId = this.heatRunCollection.get(el.runId);

              if (!localMinimum) {
                localMinimum = {
                  heatId,
                  timeDifference: el.timeDifference
                };
              } else if (el.timeDifference < localMinimum.timeDifference) {
                localMinimum.heatId = heatId;
                localMinimum.timeDifference = el.timeDifference;
              }
            });

            if (localMinimum) {
              heatLeaderData.smallestDifference = localMinimum.timeDifference;
            }
          }

          return heatLeaderData;
        }
      }

      return null;
    },
    highlightedCompetitor() {
      if (
        (this.competitorFinish || this.$route.query.competitor) &&
        this.indexedHeatRanking
      ) {
        const competitor = this.getCompetitorByUserId(
          this.$route.query.competitor
        );
        const rankingEntry = this.indexedHeatRanking.find(
          el =>
            el.competitorId === this.competitorFinish?.competitorId ||
            el.competitorId === competitor?.id
        );

        if (rankingEntry) {
          return new Array(rankingEntry);
        }
      }
      return [];
    },
    precision() {
      const precision = this.precisions.findIndex(
        el => el === this.event.setup.precision
      );

      return precision >= 0 ? precision : 2;
    },
    classFilters() {
      const items = new Array();

      items.push(
        {
          header: this.$i18n.t("events.classFilter.general")
        },
        {
          divider: true
        },
        {
          text: this.$i18n.t("events.classFilter.all"),
          value: {
            field: "general",
            value: "all"
          }
        }
      );

      for (const [fieldId, fieldValues] of Object.entries(
        this.heat.ranking.classDivisionRanking
      )) {
        const field = this.event.competitorData.data.find(
          el => el.id === fieldId
        );

        items.push(
          {
            header: this.whitelist.includes(fieldId)
              ? this.$i18n.t(`competitors.tables.${fieldId}`)
              : field.view.label
          },
          {
            divider: true
          }
        );
        fieldValues.map(value => {
          items.push({
            text: value.key,
            value: {
              field: fieldId,
              value: value.key
            }
          });
        });
      }

      return items;
    },
    isRegularity() {
      return this.event.setup.regularitySettings.enabled;
    },
    isTargetTime() {
      return this.event.setup.regularitySettings.type === "TARGET_TIME";
    },
    isPerfection() {
      return this.event.setup.regularitySettings.type === "PERFECTION";
    }
  },
  watch: {
    classFilter() {
      this.page = 1;
    }
  },
  mixins: [
    whitelist,
    formatRunTime,
    cropTime,
    genderIcon,
    getCompetitorName,
    convertToSpeedUnit,
    headers
  ],
  methods: {
    ...mapActions({
      cancelRunByCompetitor: "events/cancelRunByCompetitor",
      restartRunByCompetitor: "events/restartRunByCompetitor",
      setReferenceRunOfHeat: "events/setReferenceRunOfHeat",
      setReferenceRunOfEvent: "events/setReferenceRunOfEvent"
    }),
    isSpeedInBoundary(sectionType, sectionData) {
      const sectionConfig = this.event.setup.sections.find(
        section => section.type === sectionType
      );

      if (!sectionConfig.minSpeed) {
        Vue.set(sectionConfig, "minSpeed", 0);
      }

      if (
        sectionData.speed &&
        sectionConfig &&
        sectionConfig.minSpeed >= 0 &&
        sectionConfig.maxSpeed
      ) {
        return (
          sectionData.speed >= sectionConfig.minSpeed &&
          sectionData.speed <= sectionConfig.maxSpeed
        );
      }

      return false;
    },
    cancelCompetitorRun(heatId, competitorId, reason) {
      const payload = {
        eventId: this.event.id,
        heatId,
        competitorId,
        data: {
          message: "",
          reason
        }
      };

      this.cancelRunByCompetitor(payload);
    },
    restartCompetitorRun(heatId, competitorId, includeStartgroup) {
      const runId = this.heat.heatCycle.entries.filter(
        entry => entry.competitorId === competitorId
      )[0].runId;
      const payload = {
        eventId: this.event.id,
        heatId,
        runId,
        includeStartgroup
      };

      let confirmationMessage;
      let groupSize;
      if (includeStartgroup) {
        const fieldId = this.event.settings.massStartFieldId;
        if (String(fieldId).toLowerCase() === "all") {
          groupSize = this.event.competitors.length;
        } else {
          const competitor = this.getCompetitorById(competitorId);
          const group = this.event.competitors.filter(
            el => el.userData[fieldId] === competitor.userData[fieldId]
          );
          groupSize = group.length;
        }

        confirmationMessage = this.$i18n.t("events.restartGroupConfirmation", {
          groupSize
        });
      } else {
        confirmationMessage = this.$i18n.t("events.restartRunConfirmation");
      }
      confirm(confirmationMessage) && this.restartRunByCompetitor(payload);
    },

    setHeatReferenceRun(runId, isReferenceRun) {
      const payload = {
        eventId: this.event.id,
        heatId: this.heat.id,
        data: {
          runId,
          isActive: !isReferenceRun
        }
      };

      this.setReferenceRunOfHeat(payload)
        .then(response => {
          this.$emit("referenceUpdate", true, response);
        })
        .catch(response => {
          this.$emit("referenceUpdate", false, response);
        });
    },
    setEventReferenceRun(runId, isActive) {
      const payload = {
        eventId: this.event.id,
        data: {
          runId,
          isActive
        }
      };

      this.setReferenceRunOfEvent(payload)
        .then(response => {
          this.$emit("referenceUpdate", true, response);
        })
        .catch(response => {
          this.$emit("referenceUpdate", false, response);
        });
    }
  },
  created() {
    if (this.$route.query.competitor && this.highlightedCompetitor) {
      const entryIndex = this.indexedHeatRanking.findIndex(
        el => el.id === this.highlightedCompetitor[0].id
      );
      if (entryIndex !== -1) {
        this.$nextTick(() => {
          this.page = Math.floor(entryIndex / this.itemsPerPage) + 1;
          if (this.$refs.heatRankingTable) {
            this.$refs.heatRankingTable.$el.scrollIntoView({
              behavior: "smooth"
            });
          }
        });
      }
    }
    this.$watch("heat", (newValue, oldValue) => {
      if (newValue.id !== oldValue.id) {
        this.page = 1;
      }
    });
  }
};
</script>

<style scoped>
.v-data-table {
  font-family: "Roboto";
}
.v-data-table /deep/ th[role="columnheader"] {
  white-space: nowrap;
}
.v-data-table--dense /deep/ tbody tr:nth-child(even),
.v-data-table--dense /deep/ tbody tr:nth-child(even) .firstSectionColumn {
  background: #f7f7f7;
}
.firstSectionColumn {
  white-space: nowrap;
  position: sticky;
  left: 0;
  z-index: 10;
  background: #ffffff;
}
.v-data-table /deep/ tbody tr:hover .firstSectionColumn {
  background: #eeeeee;
}
th.clickable {
  cursor: pointer;
}
.v-data-table /deep/ tbody tr.v-data-table__selected {
  background: #e4f2fd;
}
.v-data-table--dense > .v-data-table__wrapper {
  overflow-y: hidden;
}
.v-data-table--dense /deep/ thead > tr > th,
.v-data-table--dense /deep/ tbody > tr > td {
  padding: 0 10px !important;
}
</style>
