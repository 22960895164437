<template>
  <div>
    <div
      class="d-flex flex-column flex-md-row align-stretch justify-space-between"
    >
      <v-card-title>
        {{ $t("events.tolerances.title") }}
      </v-card-title>

      <div class="mx-2 mx-md-4 d-flex flex-column flex-md-row">
        <v-btn
          @click="handleReferenceRunReset"
          color="warning"
          :loading="resetReferenceRun.loading"
          class="ma-1"
          small
        >
          {{ $t("events.tolerances.resetReferenceRun") }}
        </v-btn>
      </div>
    </div>

    <v-data-table
      :headers="headers"
      :items="sections"
      dense
      hide-default-footer
    >
      <template v-slot:item.tolerance.lowerBound="{ item }">
        {{ item.tolerance ? msToTime(item.tolerance.lowerBound) : "-" }}
      </template>

      <template v-slot:item.tolerance.upperBound="{ item }">
        {{ item.tolerance ? msToTime(item.tolerance.upperBound) : "-" }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import formatRunTime from "@/mixins/formatRunTime";
import cropTime from "@/mixins/cropTime";
import { mapActions } from "vuex";

export default {
  name: "Tolerances",
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      resetReferenceRun: {
        loading: false
      }
    };
  },
  computed: {
    headers() {
      return [
        {
          value: "type",
          text: this.$i18n.t("events.tolerances.table.section"),
          sortable: false
        },
        {
          value: "tolerance.lowerBound",
          text: this.$i18n.t("events.tolerances.table.lowerBound"),
          sortable: false
        },
        {
          value: "tolerance.upperBound",
          text: this.$i18n.t("events.tolerances.table.upperBound"),
          sortable: false
        }
      ];
    },
    sections() {
      return this.event.setup.sections.map(el => {
        const tolerance =
          (this.heat.referenceRun &&
            this.heat.referenceRun.sectionTolerances[el.type]) ||
          null;

        el.tolerance = tolerance;

        return el;
      });
    }
  },
  mixins: [formatRunTime, cropTime],
  methods: {
    ...mapActions({
      setReferenceRunOfHeat: "events/setReferenceRunOfHeat"
    }),
    msToTime(ms) {
      ms *= 10000;

      return this.cropTime(this.formatRunTime(ms, 2));
    },
    handleReferenceRunReset() {
      this.resetReferenceRun.loading = true;

      const payload = {
        eventId: this.event.id,
        heatId: this.heat.id,
        data: {
          isActive: false
        }
      };

      this.setReferenceRunOfHeat(payload)
        .then(response => {
          this.resetReferenceRun.loading = false;
          this.$emit("referenceUpdate", true, response);
        })
        .catch(response => {
          this.resetReferenceRun.loading = false;
          this.$emit("referenceUpdate", false, response);
        });
    }
  }
};
</script>
