<template functional>
  <div>
    <span v-if="props.serverTimestamp && props.end === null">
      {{
        props.cropTime(
          props.formatRunTime(
            props.serverTimestamp - props.start + props.syncDelta + props.base,
            props.decimals,
            false
          )
        )
      }}
    </span>
    <span v-else-if="props.serverTimestamp">
      {{
        props.cropTime(
          props.formatRunTime(
            props.end - props.start + props.base,
            props.decimals,
            false
          )
        )
      }}
    </span>
    <span v-else>
      -
    </span>
  </div>
</template>

<script>
export default {
  name: "Timer",
  props: {
    serverTimestamp: {
      type: Number,
      required: true
    },
    start: {
      type: Number,
      required: true
    },
    end: {
      type: Number,
      default: null
    },
    decimals: {
      type: Number,
      default: 2
    },
    syncDelta: {
      type: Number,
      required: true
    },
    base: {
      type: Number,
      default: 0
    },
    formatRunTime: Function,
    cropTime: Function
  }
};
</script>
